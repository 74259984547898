import { createApi } from "@reduxjs/toolkit/query/react";
import { getAxiosInstance } from "../../../API";

const axiosBaseQuery =
  () =>
  async ({ params, endpoint, method, body }) => {
    const api = await getAxiosInstance();
    const getParams = (key) => {
      if (params?.[key]) {
        return `${key}=${params[key]}&`;
      } else {
        return "";
      }
    };
    if (method === "get") {
      try {
        const response = await api[method](
            `${endpoint}?start=${params?.start || ""}&end=${params?.end || ""}&${getParams("sort_by")}sort_order=${
              params?.sort_order || "desc"
            }&length=${
              params?.page_size || 10
            }&page=${params?.currentPage || ""
            }&${getParams(
              "search"
            )}status=${params?.status||""}&${getParams("schedule")}${getParams("service_type_id")}${getParams("service_request_id")}
              `,
          body
        );
        return { data: response.data };
      } catch (axiosError) {
        let err = axiosError;
        return {
          error: {
            status: err.response?.status,
            data: err.response?.data || err.message,
          },
        };
      }
    } else {
      try {
        const response = await api[method](
          `${endpoint}?start=${params?.start || ""}&end=${params?.end || ""}&${getParams("sort_by")}sort_order=${
            params?.sort_order || "desc"
          }&length=${
            params?.page_size || 10
          }&page=${params?.currentPage || ""
          }&${getParams(
            "search"
          )}status=${params?.status||""}&${getParams("schedule")}${getParams("service_type_id")}${getParams("service_request_id")}`,
          body
        );
        return { data: response.data };
      } catch (axiosError) {
        let err = axiosError;
        return {
          error: {
            status: err.response?.status,
            data: err.response?.data || err.message,
          },
        };
      }
    }
  };

export const booking = createApi({
  reducerPath: "bookingApi",
  baseQuery: axiosBaseQuery(),
  tagTypes: ["booking"],
  endpoints: (builder) => ({
    getBookingData: builder.query({
      query: (values) => ({
        method: "get",
        params: values,
        endpoint: `/admin/service-request/list`,
      }),
      providesTags: ["booking"],
    }),
    bookingTableHeadData: builder.mutation({
      query: (body) => ({
        method: "post",
        body,
        endpoint: `/admin/user-field-save`,
      }),

      invalidatesTags: ["booking"],
    }),
    getBookingdetails: builder.query({
      query: (values) => ({
        method: "get",
        params: values,
        endpoint: `/admin/service-request/view`,
      }),

      invalidatesTags: ["booking"],
    }),
  }),
});
export const{useGetBookingDataQuery, useBookingTableHeadDataMutation,useGetBookingdetailsQuery}=booking
