import { useFormik } from "formik";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { updateConfig } from "../../../../Store/Slices/ServiceTypes/servicetypesSlice";
import { createServiceType, updateServiceType } from "../api";
import { toast } from "react-toastify";
import { useEffect, useState } from "react";
import * as Yup from "yup";
import { getBasicData } from "../../../../Store/Slices/Global";

const useAddServiceTypes = ({ refetch }) => {
  const dispatch = useDispatch();
  const [status, setStatus] = useState(true);
  const { imagePreview, isEdit, itemData, itemId,iconPreview} = useSelector((state) => state.servicetypes);

  const setBasicData = async () => {
    localStorage?.setItem("basicData", "");
    dispatch(getBasicData());
  };
  const validationSchema = Yup.object({
    name: Yup.string().required("*Service Type is required").matches(/^[^\s].*$/, "Service Type must not start with a space"),
    image:
      imagePreview !== ""
        ? Yup.mixed()
          .test("fileType", "Invalid file format", (value) => {
            if (value) {
              const supportedFormats = [
                "image/jpeg",
                "image/png",
                "image/svg+xml",
              ];
              return supportedFormats.includes(value.type);
            }
            return true;
          })
          .test("fileSize", "Please upload file less than 2MB", (value) => {
            if (value) {
              return value.size <= 2097152;
            }
            return true;
          })
        : Yup.string().required("Upload image"),
    icon:
      iconPreview !== ""
        ? Yup.mixed()
          .test("fileType", "Invalid file format", (value) => {
            if (value) {
              const supportedFormats = [
                "image/jpeg",
                "image/png",
                "image/svg+xml",
              ];
              return supportedFormats.includes(value.type);
            }
            return true;
          })
          .test("fileSize", "Please upload file less than 2MB", (value) => {
            if (value) {
              return value.size <= 2097152;
            }
            return true;
          })
        : Yup.string().required("*Upload image"),
    colour: Yup.string()
    .required("*Color is required")
    .matches(/^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$/, "Invalid color format"),
    background_colour: Yup.string()
    .required("*Background color is required")
    .matches(/^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$/, "Invalid color format"),
  });
  const formik = useFormik({
    initialValues: {
      name: "",
      image: "",
      icon:"",
      colour:"",
      background_colour:"",
    },
    validationSchema,
    onSubmit: (values) => {
      if (isEdit) {
        let data = {
          service_type_id: itemId,
          name: values.name,
          image: values?.image,
          icon: values?.icon,
          colour: values?.colour,
          background_colour:values?.background_colour,
          status: status ? 1 : 0,
        };
        if (values?.image === "") {
          delete data.image;
        }
        if (values?.icon === "") {
          delete data.icon;
        }
        updateServiceType(data).then((response) => {
          if (response?.status === 200) {
            toast.success("Added");
            setBasicData();
            refetch();
            handleCloseModal();
          } else if (response?.status === 422) {
            let errors = response?.errors;
            let errorFields = Object.keys(errors);
            errorFields?.forEach((field) => {
              formik.setFieldError(field, errors[field]);
            });
          }
          else if (response?.success === false) {
            let errors = response?.message;
            let errorFields = Object.keys(errors);
            errorFields?.forEach((field) => {
              formik.setFieldError(field, errors[field]);
            })
          } else {
            toast.error("Something went wrong");
          }
        });
      } else {
        let data = {
          name: values?.name,
          image: values?.image,
          icon:values?.icon,
          colour:values?.colour,
          background_colour:values?.background_colour
        };
        createServiceType(data).then((response) => {
          if (response?.status === 200) {
            toast.success("Added");
            setBasicData();
            refetch();
            handleCloseModal();
          } else if (response?.status === 422) {
            let errors = response?.errors;
            let errorFields = Object.keys(errors);
            errorFields?.forEach((field) => {
              formik.setFieldError(field, errors[field]);
            });
          } else if (response?.success === false) {
            let errors = response?.message;
            let errorFields = Object.keys(errors);
            errorFields?.forEach((field) => {
              formik.setFieldError(field, errors[field]);
            })

          } else {
            toast.error("Something went wrong");
          }
        });
      }
    },
  });
  useEffect(() => {
    if (isEdit) {
      Object.keys(itemData || {}).forEach((key) => {
        dispatch(
          updateConfig(
            (state) => {state.imagePreview = itemData?.image
              state.iconPreview = itemData?.icon   
            }
          )
        );
        if (key === "name") {
          formik.setFieldValue("name", itemData?.[key]);
        } else if (key === "status") {
          itemData?.[key] === 1 ? setStatus(true) : setStatus(false);
        } else if (key === "image") {
          formik.setFieldValue("image", '');
        }else if (key ==="icon") {
          formik.setFieldValue("icon", '');
        } else if (key === "colour") {
          formik.setFieldValue("colour", itemData?.[key]);
        } else if (key === "background_colour") {
          formik.setFieldValue("background_colour", itemData?.[key]);
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEdit, itemData]);


  const getFieldError = (fieldName) => {
    if (formik.touched[fieldName] && formik.errors[fieldName]) {
      return formik.errors[fieldName];
    }
    return "";
  };

  const handleCloseModal = () => {
    dispatch(
      updateConfig((state) => {
        state.createModal = false;
        state.itemData = "";
        state.imagePreview = "";
        state.iconPreview = "";
      })
    );
  };
  const handleImageChange = (event) => {
    const selectedImage = event?.target?.files[0];
    if (selectedImage) {
      formik.setFieldValue("image", selectedImage);
      const file = selectedImage;
      const reader = new FileReader();
      reader.onloadend = () => {
        dispatch(
          updateConfig((state) => {
            state.imagePreview = reader.result;
          })
        );
      };
      if (file) {
        reader.readAsDataURL(file);
      }
    }
  };
  const handleImageRemove = () => {
    dispatch(
      updateConfig((state) => {
        state.imagePreview = "";
      })
    );
    formik.setFieldValue("image", "");
    formik?.setFieldError("image", "Upload image");
    formik?.setFieldTouched("image", true);
  };
  const handleIconChange = (event) => {
    const selectedImage = event?.target?.files[0];
    if (selectedImage) {
      formik.setFieldValue("icon", selectedImage);
      const file = selectedImage;
      const reader = new FileReader();
      reader.onloadend = () => {
        dispatch(
          updateConfig((state) => {
            state.iconPreview = reader.result;
          })
        );
      };
      if (file) {
        reader.readAsDataURL(file);
      }
    }
  };
  const handleIconRemove = () => {
    dispatch(
      updateConfig((state) => {
        state.iconPreview = "";
      })
    );
    formik.setFieldValue("icon", "");
    formik?.setFieldError("icon", "Upload image");
    formik?.setFieldTouched("icon", true);
  };
  const handleStatusChange = () => {
    setStatus(!status);
  };
  return {
    formik,
    isEdit,
    getFieldError,
    handleCloseModal,
    handleImageChange,
    handleImageRemove,
    handleIconChange,
    handleIconRemove,
    imagePreview,
    iconPreview,
    status,
    handleStatusChange,
  };
};

export default useAddServiceTypes;
