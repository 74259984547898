import { getAxiosInstance } from "../../../API";

export const getAllPermissions = async () => {
  const api = await getAxiosInstance();
  try {
    const response = await api.get(`/permissions/list`);
    return response;
  } catch (error) {
    return error?.response?.data
  }
};

export const AddRole = async (body) => {
  const api = await getAxiosInstance();
  try {
    const response = await api.post(`/admin/roles/create`, body);
    return response;
  } catch (error) {
    return error?.response?.data
  }
};

export const updateRole = async (body) => {
  const api = await getAxiosInstance();
  try {
    const response = await api.put(`/admin/roles/update`, body);
    return response;
  } catch (error) {
    return error?.response?.data
  }
};

export const deleteRole = async (id) => {
  const api = await getAxiosInstance();
  try {
  const response = await api.delete(`/admin/roles/delete?role_id=${id}`);
    return response;
  } catch (error) {
    return error?.response?.data
  }
};

export const approveRequest = async (params) => {
  const api = await getAxiosInstance();
  try {
    const response = await api.post(`/roles/approve`, params);
    return response;
  } catch (error) {
    return error?.response?.data
  }
};
