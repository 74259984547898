import moment from "moment";

export const getFormatedDate = (date) => {
  return moment(new Date(date)).format("YYYY-MM-DD");
};

export const getFormatedDate2 = (date) => {
  return moment(new Date(date)).format("DD-MM-YYYY");
};
export const getFormatedDate3 = (date) => {
  if (!date) return ''; // Handle cases where date might be undefined or null
  return moment(date, 'DD-MM-YYYY').format('YYYY-MM-DD');
};