import { combineReducers } from "@reduxjs/toolkit";
import globalSlice from "./Slices/Global";
import { servicetypes } from "./Queries/ServiceTypes";
import servicetypesSlice from "./Slices/ServiceTypes/servicetypesSlice";
import expertiseSlice from "./Slices/Expertise/expertiseSlice";
import { expertise } from "./Queries/Expertise";
import { staff } from "./Queries/Staff";
import staffSlice from "./Slices/Staff/staffSlice";
import tagsSlice from "./Slices/Tags/tagsSlice";
import { tags } from "./Queries/Tags";
import { role } from "./Queries/Role";
import roleSlice from "./Slices/Role/roleSlice";
import { verification } from "./Queries/Verification";
import verificationSlice from "./Slices/Users/verificationSlice";
import { posts } from "./Queries/Posts";
import postsSlice from "./Slices/Posts/postsSlice";
import { booking } from "./Queries/Booking";
import bookingSlice from "./Slices/Booking/bookingSlice";
import paymentsSlice from "./Slices/Payments/paymentsSlice";
import { payments } from "./Queries/Payments";
import { privacypolicy } from "./Queries/CMS/Privacypolicy";
import privacypolicySlice from "./Slices/CMS/privacypolicySlice";
import { termsandconditions } from "./Queries/CMS/TermsandConditions";
import termsandconditionsSlice from "./Slices/CMS/termsandconditionsSlice";
import { providers } from "./Queries/Users/Providers";
import providersSlice from "./Slices/Users/providersSlice";
import { seekers } from "./Queries/Users/Seekers";
import seekersSlice from "./Slices/Users/seekersSlice";
import providerdetailSlice from "./Slices/Users/providerdetailSlice";
import { notifications } from "./Queries/Notifications";
import notificationSlice from "./Slices/Notifications/notificationSlice";
import seekerdetailSlice from "./Slices/Users/seekerdetailSlice";
import { faq } from "./Queries/CMS/FAQ";
import faqSlice from "./Slices/CMS/faqSlice";
import dashboardSlice from "./Slices/Dashboard/dashboardSlice";
import { dashboard } from "./Queries/Dashboard";


const appReducer = combineReducers({
  [servicetypes.reducerPath]: servicetypes.reducer,
  [expertise.reducerPath]: expertise.reducer,
  [staff.reducerPath]: staff.reducer,
  [tags.reducerPath]: tags.reducer,
  [role.reducerPath]: role.reducer,
  [verification.reducerPath]:verification.reducer,
  [posts.reducerPath]:posts.reducer,
  [booking.reducerPath]:booking.reducer,
  [payments.reducerPath]:payments.reducer,
  [privacypolicy.reducerPath]:privacypolicy.reducer,
  [termsandconditions.reducerPath]:termsandconditions.reducer,
  [providers.reducerPath]:providers.reducer,
  [seekers.reducerPath]:seekers.reducer,
  [notifications.reducerPath]: notifications.reducer,
  [faq.reducerPath]:faq.reducer,
  [dashboard.reducerPath]:dashboard.reducer,

  global: globalSlice,
  expertise:expertiseSlice,
  servicetypes: servicetypesSlice,
  staff: staffSlice,
  tags: tagsSlice,
  role: roleSlice,
  verification:verificationSlice,
  posts:postsSlice,
  booking:bookingSlice,
  payments:paymentsSlice,
  privacypolicy:privacypolicySlice,
  termsandconditions:termsandconditionsSlice,
  providers:providersSlice,
  providerdetails:providerdetailSlice,
  seekerdetails:seekerdetailSlice,
  seekers:seekersSlice,
  providerdata:providerdetailSlice,
  notifications:notificationSlice,
  faq:faqSlice,
  dashboard:dashboardSlice
});

export default appReducer;
