import { useSelector } from "react-redux";
import { staff,useGetStaffDataQuery,useStaffTableHeadDataMutation } from "../../../Store/Queries/Staff";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { getStaffData, updateConfig as globalUpdateConfig } from "../../../Store/Slices/Global";
import { updateConfig } from "../../../Store/Slices/Staff/staffSlice";
import { updateStatus, viewStaff, deleteStaff } from "./api";
import { toast } from "react-toastify";
import { current } from "@reduxjs/toolkit";
import { subDays,format} from "date-fns";

const useStaff = () => {
  const dispatch = useDispatch();
  const staffState = useSelector((state) => state.staff);
  const { tableFields,showEditModal} = useSelector((state) => state.global);
  let actionOptions = [{ label: "Edit Columns", value: 0 }];
  let paginationOptions = [
    { label: "10 Per page", value: 10 },
    { label: "20 Per page", value: 20 },
    { label: "30 Per page", value: 30 },
  ];
  const [initialDate, setInitialDate] = useState({
    startDate: subDays(new Date(), 30),
    endDate: subDays(new Date(), 0),
    key: "selection",
  });
  const {
    data: staffList = {},
    isLoading,
    isFetching,
    refetch,
  } = useGetStaffDataQuery({
    items_per_page: staffState.currentPageSize,
    currentPage: staffState.currentPage,
    sort_by: staffState.sortBy,
    sort_order: staffState.sortOrder,
    start: staffState.start===""? format(initialDate?.startDate, 'dd-MM-yyyy'):staffState.start,
    end: staffState.end===""? format(initialDate?.endDate, 'dd-MM-yyyy'):staffState.end,
    search: staffState.search,
    status:staffState?.status,
    role_id:staffState?.role_id
  });
  const [updateStatusFields] = useStaffTableHeadDataMutation();
  useEffect(() => {
    dispatch(
      globalUpdateConfig((state) => {
        state.tableFields = staffList?.data?.fields;
      })
    );
    // dispatch(getStaffData());
    // eslint-disable-next-line
  }, [staffList]);
  useEffect(() => {
   dispatch(getStaffData());
  }, [dispatch]);
  

  const handleChange = (data) => {
    let params = {
      user_id: data?._id,
      status: data.status === 1 ? 0 : 1,
    };
    updateStatus(params);
    // refetch()
    dispatch(
        staff.util.updateQueryData(
        "getStaffData",
        {  items_per_page: staffState.currentPageSize,
          currentPage: staffState.currentPage,
          sort_by: staffState.sortBy,
          sort_order: staffState.sortOrder,
          start: staffState.start===""? format(initialDate?.startDate, 'dd-MM-yyyy'):staffState.start,
          end: staffState.end===""? format(initialDate?.endDate, 'dd-MM-yyyy'):staffState.end,
          search: staffState.search,
          status:staffState?.status,
          role_id:staffState?.role_id
                },
        (cachedData) => {
          let updatedCachedData = { ...current(cachedData) };
          let newResult = updatedCachedData?.data?.data?.map((res) =>
            res?._id === data?._id
              ? {
                  ...res,
                  status: data?.status === 1 ? 0 : 1,
                }
              : res
          );
          updatedCachedData = {
            ...updatedCachedData,
            data: {
              ...updatedCachedData.data,
              data: newResult,
            },
          };
          return updatedCachedData;
        }
      )
    );
  };

  const getRow = (feild, data) => {
    const rows = {
      common: (feild, data) => <p className="pro-mb-0">{data[feild]}</p>,
      status: (feild, data) => {
        const status = data[feild] === 1;
        return (
          <>
              <div className="pro-toggle">
                <div className="pro-toggle-box">
                  <input
                    type="checkbox"
                    role="switch"
                    id="status"
                    checked={status}
                    onChange={() => handleChange(data)}
                  />
                  <span></span>
                </div>
              </div>
          </>
        );
      },
    };
    return rows?.[feild]?.(feild, data) ?? rows["common"](feild, data);
  };

  const handleClearClick = () => {
    dispatch(updateConfig((state) => {
      state.currentPageSize= 10;
      state.currentPage= 1;
      state.sortBy = "";
      state.sortOrder = "";
      state.start= format(subDays(new Date(), 30), 'dd-MM-yyyy');
      state.end= format(new Date(), 'dd-MM-yyyy');
      state.search = "";
      state.status = "";
      state.role_id = "";
    }));
    setInitialDate({
        startDate: subDays(new Date(), 30),
        endDate: new Date(),
        key: "selection",
      });
  };

  const handleSearch = (e) => {
    dispatch(
      updateConfig((state) => {
        state.search = e.target.value;
        state.currentPage = 1;
      })
    );
  };

  const handlePageSize = ({ value: page_size }) => {
    dispatch(
      updateConfig((state) => {
        state.currentPageSize = page_size;
        state.currentPage = 1;
      })
    );
  };
  const handlePagination = (page) => {
    dispatch(
      updateConfig((state) => {
        state.currentPage = page;
      })
    );
  };

  const handleSort = (label) => {
    if (staffState.sortBy === label) {
      dispatch(
        updateConfig((state) => {
          state.currentPage = 1;
          state.sortOrder = staffState.sortOrder === "asc" ? "desc" : "asc";
        })
      );
    } else {
      dispatch(
        updateConfig((state) => {
          state.currentPage = 1;
          state.sortBy = label;
          state.sortOrder = "asc";
        })
      );
    }
  };

  const handleEditAction = (e) => {
    dispatch(
      updateConfig((state) => {
        state.isEdit = true;
        state.createModal = true;
        state.itemId = e?.[0];
      })
    );
    viewStaff(e?.[0]).then((response) => {
      if (response?.status === 200) {
        dispatch(
          updateConfig((state) => (state.itemData = response?.data?.data))
        );
      } else {
        toast.error("Something went wrong");
      }
    });
  };

  const handleCreateClick = () => {
    dispatch(
      updateConfig((state) => {
        state.isEdit = false;
        state.createModal = true;
      })
    );
  };

  const handleCloseClick = () => {
    dispatch(updateConfig((state) => (state.createModal = false)));
  };
  const handleDeleteAction = () => {
    let id = staffState?.itemId;
    // if(staffState?.itemData?.can_update){
        deleteStaff(id).then((response) => {
        if (response?.status === 200) {
          toast.success("Deleted");
          refetch();          refetch();
          handleDeleteCloseModal();
          dispatch(updateConfig((state) => (state.clearSelection = true)));
          const clearSelection = setTimeout(() => {
            dispatch(updateConfig((state) => (state.clearSelection = false)));
            clearTimeout(clearSelection);
          }, 500);
        } else {
          toast.error("Something went wrong");
          handleDeleteCloseModal()
        }
      });
    // }else{
    //   toast.error("Dont have an access to Delete");
    // }
  };
  const handleDateChange = (date) => {
    dispatch(
      updateConfig((state) => {
        state.start = format(new Date(date.startDate),'dd-MM-yyyy');
        state.end = format(new Date(date.endDate),'dd-MM-yyyy');
      })
    );
  };
  const handleDeleteCloseModal = (e) => {
    dispatch(
      updateConfig((state) => {
        state.deleteModal = !state.deleteModal;
        state.itemId = ''
        state.itemData=''
      })
    );
  };
  const handleDeleteModal = (e) => {
    dispatch(
      updateConfig((state) => {
        state.deleteModal = !state.deleteModal;
        state.itemId = e?.[0];
      })
    );
    viewStaff(e?.[0]).then((response) => {
      if (response?.status === 200) {
        dispatch(
          updateConfig((state) => (state.itemData = response?.data?.data))
        );
      } else {
        toast.error("Something went wrong");
      }
    });
  };
  const handleFilter = () => {
    dispatch(
      updateConfig((state) => {
        state.isFilter = false;
        state.createFilterModal = true;
      })
    );
  };
  const handleCloseFilter = () => {
    dispatch(updateConfig((state) => (state.createFilterModal = false)));
  };
  const handleActionChange = (item) => {
    if (item?.value === 0) {
      dispatch(
        globalUpdateConfig((state) => {
          state.showEditModal = true;
        })
      );
    }
  };
  return {
    isLoading,
    isFetching,
    tableFields,
    staffList,
    staffState,
    paginationOptions,
    getRow,
    refetch,
    handleSort,
    handleSearch,
    handlePageSize,
    handleEditAction,
    handlePagination,
    handleCloseClick,
    handleClearClick,
    handleCreateClick,
    handleDeleteModal,
    handleDeleteCloseModal,
    handleDeleteAction,
    handleFilter,
    handleCloseFilter,
    actionOptions,
    handleActionChange,
    updateStatusFields,
    showEditModal,
    handleDateChange,
    initialDate
  };
  
};

export default useStaff;
