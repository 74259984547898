import {
    ConfirmationBox,
    HeadingGroup,
    ModalLayout,
    Pagination,
    SearchFilters,
    Table
  } from "@wac-ui-dashboard/wac_component_library";
  import React from "react";
  import { FaSort } from "react-icons/fa";
  import EmptyData from "../../Global/EmptyData";
  import Style from "./privacy.module.scss";
  import OrderColumn from "../../Global/OrderColumn";
  import AddPrivacy from "./AddPrivacy";
  import usePrivacy from "./usePrivacy";
  const Privacypolicy = () => {
    const {
      isLoading,
      isFetching,
      tableFields,
      privacyList,
      privacyState,
      paginationOptions,
      getRow,
      refetch,
      handleSort,
      handleSearch,
      handlePageSize,
      handleEditAction,
      handlePagination,
      handleCloseClick,
      handleClearClick,
      handleCreateClick,
      handleDeleteModal,
      handleDeleteCloseModal,
      handleDeleteAction,
      actionOptions,
      handleActionChange,
      updateServiceTypeFields,
      showEditModal
    } = usePrivacy();
    return (
      <div>
        <HeadingGroup
          title={"Privacy policy"}
          className={`pro-mb-4`}
          buttonTitle={true && "Create"}
          handleClick={handleCreateClick}
        />
        <div className="col-auto pro-pt-3 pro-pb-6">
          <div className="pro-d-flex">
            <SearchFilters
              onSearchInput={handleSearch}
              showActions={false}
              loading={isLoading}
              SearchIcon={
                <span className="material-symbols-outlined"> search </span>
              }
              // showClearFilters
              // handleClear={handleClearClick}
              searchInputProps={{ value: privacyState?.search }}
              actionOptions={actionOptions?.filter((item) => item.value === 0)}
              onActionOptionChange={handleActionChange}
              extraFilters={
                <>
                <div className="col-auto pro-d-flex">              
                  <button
                    className={" pro-btn-link lg"}
                    onClick={handleClearClick}
                  >                 
                    Clear
                  </button>
                </div>
                </>
              }
            />
  
          </div>
          {privacyList?.data?.data?.length !== 0 ? (
            <>
              <Table
                multiSelect={false}
                data={privacyList?.data?.data || []}
                uniqueID={"_id"}
                fields={tableFields}
                SortIcon={<FaSort />}
                editIcon={<span className="material-symbols-outlined">edit</span>}
                deleteIcon={
                  <span className="material-symbols-outlined">delete</span>
                }
                handleDelete={handleDeleteModal}
                handleEdit={handleEditAction}
                handleSort={handleSort}
                clear={privacyState.clearSelection}
                getRow={getRow}
                loading={isFetching}
                perpage={privacyState?.currentPageSize}
                editable={privacyList?.data?.permissions?.can_update}
                deletable={privacyList?.data?.permissions?.can_delete}
                assignable={false}
                showCheckBox={privacyList?.data?.permissions?.can_update}
              />
              {privacyList?.data?.data?.length > 0 && (
                
                <Pagination
                  currentPage={privacyState?.currentPage}
                  totalPageCount={Math.ceil(
                    privacyList?.data?.total /
                    privacyState.currentPageSize
                  )}
                  options={paginationOptions}
                  onPageChange={handlePagination}
                  onActionChange={handlePageSize}
                  center
                />
              )}
            </>
          ) : (
            <EmptyData />
          )}
          <ModalLayout
              centered={false}
              show={privacyState?.createModal}
              handleClose={handleCloseClick}
              title={privacyState?.isEdit ? "Edit Terms and Conditions" : "Add Terms and Conditions"}
              closeIcon={<span className="material-symbols-outlined">close</span>}
              backdrop="static"
              propStyle={{ root: Style.modal_root }}
            >
              <div className="modal_content_wrap">
                <AddPrivacy closeModal={handleCloseClick} refetch={refetch} />
              </div>
          </ModalLayout>
          <ModalLayout
            show={privacyState?.deleteModal}
            handleClose={handleDeleteCloseModal}
          >
            <div className="pro-m-5">
              <ConfirmationBox
                title={"Are you sure you want to delete?"}
                subTitle={"This action cannot be undo "}
                cancelText={"No"}
                cancelAction={handleDeleteCloseModal}
                submitText={"Yes"}
                submitAction={handleDeleteAction}
                isRight={true}
              />
            </div>
          </ModalLayout>
          <ModalLayout show={showEditModal} handleClose={handleCloseClick}>
            <div className="pro-m-5">
            <OrderColumn
              title={"Choose which columns you see"}
              tableFields={privacyList?.data?.fields}
              moduleId={privacyList?.data?.module_id}
              updateData={updateServiceTypeFields} 
              refetch={refetch}
            />
            </div>
          </ModalLayout>
        </div>
      </div>
    );
  };
  
  export default Privacypolicy;
  