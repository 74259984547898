// src/StarRating.js

import React from 'react';
import styles from './StarRating.module.scss';
import { ReactSVG } from 'react-svg';
import Assets from '../../../Assets';

const StarRating = ({ rating }) => {
  const totalStars = 5;
  const starsArray = Array.from({ length: totalStars }, (_, index) => index + 1);

  return (
    <div className={styles.star_rating}>
      {starsArray.map((star, index) => {
        const fill = Math.min(Math.max((rating??0) - index, 0), 1);
        return (
          <div key={index} className={styles.star}>
            <ReactSVG  src={Assets.STAR_RATING} className={styles.star_inactive}/>
            <ReactSVG src={Assets.STAR_RATING} className={styles.star_fill} style={{ width: `${fill * 100}%` }}/>
          </div>
        );
      })}
    </div>
  );
};

export default StarRating;
