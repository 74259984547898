import { Button, Input } from "@wac-ui-dashboard/wac_component_library";
import React from "react";
import useAddServiceTypes from "./useAddServiceTypes";
import FileUpload from "../../../Global/FileUpload";
import ColorPicker from "../../../Global/ColorPricker";

const AddServiceTypes = ({ refetch }) => {
  const { formik, isEdit, getFieldError, handleCloseModal,imagePreview, handleImageChange,
    handleImageRemove,status,handleStatusChange,iconPreview, handleIconChange,handleIconRemove} = useAddServiceTypes({
    refetch,
  });
  return (
    <div className="col">
      <div className="pro-mb-4">
      <Input
        label={"Service Type"}
        type="text"
        id="name"
        name="name"
        className={`pro-input lg ${getFieldError("name") && " error"}`}
        {...formik.getFieldProps("name")}
        error={getFieldError("name")}
        errorMessage={getFieldError("name")}
      />
      </div>
      <div className="input-wrap pro-mb-4">
        <label htmlFor="colour" className={`pro-font-sm pro-mb-1 pro-fw-medium`}>
          Color *
        </label>
        
        <ColorPicker
          id={'colour'}
          name={'colour'}
          onChange={(color) => formik.setFieldValue("colour", color)} 
          value={formik?.values?.colour}
          isError={getFieldError("colour")}
          errorMessage={getFieldError("colour")}
          onBlur={formik.handleBlur}
        />
        {/* <div className="color-picker-input">
          <Input
            label={formik?.values?.colour}
            type="color"
            name="colour"
            id="colour"
            
            // onChange={(color) => formik.setFieldValue("colour", color.hex)} 
            // value={formik?.values?.colour}
            value="#ff0000"
            onchange="this.setAttribute('value', this.value);"
            className={`pro-input lg ${getFieldError("colour") && " error"}`}
            {...formik.getFieldProps("colour")}
            error={getFieldError("colour")}
            errorMessage={getFieldError("colour")}
          />
        </div> */}
          {/* <input type="color" id="html5colorpicker" onChange={(color) => formik.setFieldValue("colour", color.hex)} value={formik?.values?.colour}/> */}

        {/* <SketchPicker
        color={formik?.values?.colour}
        onChangeComplete={(color) => formik.setFieldValue("colour", color.hex)}
        />
         {getFieldError("colour") && (
          <span className="error-custom">{getFieldError("colour")}</span>
        )} */}
      </div>
      <div className="pro-mb-4">
        <FileUpload
          formik={formik}
          title={"Image*"}
          filePreview={imagePreview}
          handleFileChange={handleImageChange}
          handleFileRemove={handleImageRemove}
          getFieldError={getFieldError}
          isError={getFieldError("image")}
        />
        {getFieldError("image") && (
          <span className="error-custom">{getFieldError("image")}</span>
        )}
      </div>
      <div className="pro-mb-4">
        <FileUpload
          formik={formik}
          title={"Icon*"}
          filePreview={iconPreview}
          handleFileChange={handleIconChange}
          handleFileRemove={handleIconRemove}
          getFieldError={getFieldError}
          isError={getFieldError("icon")}
        />
        {getFieldError("icon") && (
          <span className="error-custom">{getFieldError("icon")}</span>
        )}
      </div>
      <div className="input-wrap pro-mb-4">
        <label htmlFor="" className={`pro-font-sm pro-mb-1 pro-fw-medium`}>
          Background Color *
        </label>
        <ColorPicker
          id={'background_colour'}
          name={'background_colour'}
          onChange={(color) => formik.setFieldValue("background_colour", color)} 
          value={formik?.values?.background_colour}
          onBlur={formik.handleBlur}
          isError={getFieldError("background_colour")}
          errorMessage={getFieldError("background_colour")}
        />
      </div>
     {isEdit &&<div className="pro-toggle">
        <div className="pro-toggle-box ">
          <input
            type="checkbox"
            role="switch"
            id="status"
            checked={status}
            onChange={handleStatusChange}
          />
          <span></span>
        </div>
        <label htmlFor="status">Status</label>
      </div>
      }
      <div className={`col-12 pro-d-flex pro-justify-end ${"offcanvas-footer-sticky-btn"}`}>
        <Button
          className={"pro-btn-link lg pro-px-5"}
          onClick={handleCloseModal}
          type="button"
        >
          Cancel
        </Button>
        <Button
          type="submit"
          className={"pro-btn-primary lg pro-ms-3"}
          onClick={formik.handleSubmit}
        >
          {isEdit ? "Update" : "Add"}
        </Button>
      </div>
    </div>
  );
};

export default AddServiceTypes;
