import {
  NavGroup,
} from "@wac-ui-dashboard/wac_component_library";
import React from "react";
import Style from "./providersDetails.module.scss";
import useProvidersDetails from "./useProvidersDetails";
import { Link } from "react-router-dom";

const ProvidersDetails = () => {
  const {
    tabNavs,
    renderTabContent,
  } = useProvidersDetails();
  return (
      <>
        <div className={`${Style.main_container}`}>
          <div className="row pro-items-center">
            <div className="col tab_wrap">
              <NavGroup navigations={tabNavs} type={"type-2"} Link={Link} />
            </div>
          </div>
        </div>
        {renderTabContent()}
      </>
  );
};

export default ProvidersDetails;
