import { useFormik } from "formik";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { updateConfig } from "../../../../Store/Slices/Users/providersSlice";
import { createProviders, updateProviders } from "../api";
import { toast } from "react-toastify";
import { useEffect, useState } from "react";
import * as Yup from "yup";
import { getBasicData } from "../../../../Store/Slices/Global";
import countryData from "../../../../utils/countryCode";
import { getFormatedDate2, getFormatedDate3 } from "../../../../utils/Table";
import Language from "./Language";
import Basicdetails from "./Basicdetails";
import Documents from "./Documents";
import Services from "./Services";

const useAddProviders = ({ refetch, providersState }) => {
  const dispatch = useDispatch();
  const { isEdit, itemData, itemId, formActiveTab, documents, imagePreview } =
    useSelector((state) => state.providers);
  const { basicData } = useSelector((state) => state.global);
  const [status, setStatus] = useState(true);
  const setBasicData = async () => {
    localStorage?.setItem("basicData", "");
    dispatch(getBasicData());
  };
  const validationSchema = Yup.object({
    name: Yup.string()
      .required("*Name is required")
      .min(3, "Name must be at least 3 characters long")
      .matches(/^[^\s].*$/, "Name must not start with a space"),
    country_code: Yup.object().required("*Select country"),
    profile_image:
      imagePreview !== ""
        ? Yup.mixed()
            .test("fileType", "Invalid file format", (value) => {
              if (value) {
                const supportedFormats = [
                  "image/jpeg",
                  "image/png",
                  "image/svg+xml",
                ];
                return supportedFormats.includes(value.type);
              }
              return true;
            })
            .test("fileSize", "Please upload file less than 2MB", (value) => {
              if (value) {
                return value.size <= 2097152;
              }
              return true;
            })
        : Yup.string().required("Upload image"),
    phone: Yup.string()
      .required("*Enter mobile number")
      .matches(/^[0-9]+$/, "Number must be digits")
      .min(7, "Number must be at least 7 digits")
      .max(14, "Number must be at most 14 digits"),
    email: Yup.string()
      .email("Invalid email format")
      .required("*Email is required"),
    dob: Yup.string().required("*Select date of birth"),
    gender: Yup.object(),
    bio: Yup.string()
      .required("*Bio is required")
      .min(3, "Bio must be at least 3 characters long")
      .matches(/^[^\s].*$/, "Bio must not start with a space"),
    experience: Yup.string()
      .required("*Experience is required")
      .matches(/^[^\s].*$/, "Experience must not start with a space"),
    language: Yup.array()
      .min(1, "*Select atleast one language")
      .required("Select atleast one language"),
    service_type: Yup.array().of(
      Yup.object().shape({
        id: Yup.object().required("*Service name is required"),
        categories: Yup.array().required("*Type of service is required"),
        price: Yup.string().required("*Price is required"),
      })
    ),
    ...(isEdit
      ? {
          documents: Yup.array(),
        }
      : {
          documents: Yup.array()
            .min(1, "Upload documents")
            .required("Upload documents"),
        }),
    // address: Yup.string()
    //   .required("*Address is required")
    //   .min(3, "Address must be at least 3 characters long")
    //   .matches(/^[^\s].*$/, "Address must not start with a space"),
  });
  const formik = useFormik({
    initialValues: {
      name: "",
      country_code: "",
      profile_image: "",
      phone: "",
      email: "",
      dob: "",
      gender: "",
      bio: "",
      address: "",
      experience: "",
      language: "",
      // service_type: "",
      service_type: [
        {
          id: "",
          categories: "",
          price: "",
        },
      ],
      documents: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      const newlamguage = values?.language.map((item) => item._id);
      if (isEdit) {
        let data = {
          id: itemId,
          full_name: values?.name,
          country_code: values?.country_code?.value,
          phone_number: values?.phone,
          email: values?.email,
          dob: getFormatedDate2(values?.dob),
          gender: values?.gender ? values?.gender?.id : "",
          bio: values?.bio,
          languages: newlamguage,
          service_types: values?.service_type,
          latitude: 70,
          longitude: 110,
          address: values?.address,
          document: values?.documents,
          experience: Number(values?.experience),
          profile_image: values?.profile_image,
          status: status ? 1 : 0,
        };
        if (values?.profile_image === "") {
          delete data.profile_image;
        }
        if (values?.documents.length === 0) {
          delete data.document;
        }
        const formData = new FormData();
        Object.keys(data).forEach((key) => {
          if (key === "document") {
            data.document.forEach((item, index) => {
              formData.append(`document[${index}]`, item);
            });
          } else if (key === "service_types") {
            data.service_types.forEach((item, index) => {
              const newcategories = item?.categories.map((item) => item._id);
              formData.append(`service_types[${index}][id]`, item?.id?._id);

              newcategories.forEach((item, i) => {
                formData.append(
                  `service_types[${index}][categories][${i}]`,
                  item
                );
                //service_types[{id: "",category:"",price:""}]
              });
              formData.append(
                `service_types[${index}][price]`,
                Number(item?.price)
              );
              //service_types[{id: "",category:"",price:""}]
            });
          } else if (key === "languages") {
            data.languages.forEach((item, index) => {
              formData.append(`languages[${index}]`, item);
            });
          } else {
            formData.append(key, data[key]);
          }
        });
        updateProviders(formData).then((response) => {
          if (response?.status === 200) {
            toast.success("Added");
            setBasicData();
            refetch();
            handleCloseModal();
          } else if (response?.status === 422) {
            let errors = response?.errors;
            let errorFields = Object.keys(errors);
            errorFields?.forEach((field) => {
              formik.setFieldError(field, errors[field]);
            });
            toast.error(response?.message);
          } else if (response?.success === false) {
            let errors = response?.message;
            let errorFields = Object.keys(errors);
            errorFields?.forEach((field) => {
              formik.setFieldError(field, errors[field]);
              if (field === "address") {
                formik.setFieldError("address", errors[field]);
              }
            });
            toast.error(response?.message);
          } else {
            toast.error("Something went wrong");
          }
        });
      } else {
        let data = {
          full_name: values?.name,
          country_code: values?.country_code?.value,
          phone_number: values?.phone,
          email: values?.email,
          dob: getFormatedDate2(values?.dob),
          gender: values?.gender?.id,
          bio: values?.bio,
          languages: newlamguage,
          service_types: values?.service_type,
          latitude: 70,
          longitude: 110,
          address: values?.address,
          document: values?.documents,
          experience: Number(values?.experience),
          profile_image: values?.profile_image,
        };
        const formData = new FormData();
        Object.keys(data).forEach((key) => {
          if (key === "document") {
            data.document.forEach((item, index) => {
              formData.append(`document[${index}]`, item);
            });
          } else if (key === "service_types") {
            data.service_types.forEach((item, index) => {
              const newcategories = item?.categories.map((item) => item._id);
              formData.append(`service_types[${index}][id]`, item?.id?._id);

              newcategories.forEach((item, i) => {
                formData.append(
                  `service_types[${index}][categories][${i}]`,
                  item
                );
                //service_types[{id: "",category:"",price:""}]
              });
              formData.append(
                `service_types[${index}][price]`,
                Number(item?.price)
              );
              //service_types[{id: "",category:"",price:""}]
            });
          } else if (key === "languages") {
            data.languages.forEach((item, index) => {
              formData.append(`languages[${index}]`, item);
            });
          } else {
            formData.append(key, data[key]);
          }
        });
        createProviders(formData).then((response) => {
          if (response?.status === 200) {
            toast.success("Added");
            setBasicData();
            refetch();
            handleCloseModal();
          } else if (response?.success === false) {
            let errors = response?.message;
            let errorFields = Object.keys(errors);
            errorFields?.forEach((field) => {
              formik.setFieldError(field, errors[field]);
              if (field === "address") {
                formik.setFieldError("address", errors[field]);
              }
            });
            toast.error(response?.message);
          } else {
            toast.error("Something went wrong");
          }
        });
      }
    },
  });
  useEffect(() => {
    if (isEdit) {
      Object.keys(itemData || {}).forEach((key) => {
        dispatch(
          updateConfig(
            (state) => (state.imagePreview = itemData?.profile_image)
          )
        );
        if (key === "full_name") {
          formik.setFieldValue("name", itemData?.[key]);
        } else if (key === "profile_image") {
          formik.setFieldValue("profile_image", "");
        } else if (key === "email") {
          formik.setFieldValue("email", itemData?.[key]);
        } else if (key === "phone_number") {
          formik.setFieldValue("phone", itemData?.[key]);
        } else if (key === "dob") {
          formik.setFieldValue("dob", getFormatedDate3(itemData?.[key]));
        } else if (key === "document") {
          formik.setFieldValue("document", []);
        } else if (key === "experience") {
          formik.setFieldValue("experience", itemData?.[key]);
        } else if (key === "country_code") {
          const country = countryData.find(
            (country) => country.value === itemData?.country_code
          );
          formik.setFieldValue("country_code", country);
        } else if (key === "languages") {
          let languagesarray = [];
          // const service = basicData?.service_types.find(service => service.id === itemData?.service_type_id);
          // formik.setFieldValue("servicetype", service);
          for (let i = 0; i < itemData?.languages?.length; i++) {
            const file = itemData?.languages[i];
            const languages = basicData?.languages_list?.filter(
              (value) => value?._id === file
            );
            languagesarray.push(languages[0]);
          }
          formik.setFieldValue("language", languagesarray);
        } else if (key === "bio") {
          formik.setFieldValue("bio", itemData?.[key]);
        } else if (key === "service_types") {
          let service_types = [];
          for (let i = 0; i < itemData?.service_types?.length; i++) {
            const file = itemData?.service_types[i];
            const service = basicData?.service_types_list?.filter(
              (value) => value?._id === file?.id
            );
            let categories_type = [];
            if (service?.length > 0) {
              for (
                let j = 0;
                j < itemData?.service_types[i]?.categories?.length;
                j++
              ) {
                const file = itemData?.service_types[i]?.categories[j];
                const categories = service[0]?.categories?.filter(
                  (value) => value?._id === file
                );
                categories_type.push(categories[0] ?? categories[0]);
              }
            }
            service_types.push({
              id: service[0] ? service[0] : "",
              categories: categories_type.length > 0 ? categories_type : "",
              price: itemData?.service_types[i]?.price ?? "",
            });
          }
          formik.setFieldValue("service_type", service_types);
        } else if (key === "gender") {
          const gender = basicData?.gender?.filter(
            (gend) => gend?.id === itemData?.gender
          );
          formik.setFieldValue("gender", gender[0] ?? "");
        } else if (key === "address") {
          formik.setFieldValue("address", itemData?.[key]);
        }
      });
    }
    if (!isEdit) {
      formik?.setFieldValue("country_code", {
        name: "Canada",
        code: "CA",
        label: "+1",
        value: "+1",
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEdit, itemData]);
  function calculateCompletionStatus(fields) {
    // eslint-disable-next-line array-callback-return
    var completedFields = fields?.filter((field) => {
      if (Array.isArray(formik?.values[field])) {
        return formik?.values[field]?.length > 0;
      } else if (
        field === "id" ||
        field === "categories" ||
        field === "price"
      ) {
        return !!formik?.values?.service_type?.[0]?.[field];
      } else {
        return !!formik?.values[field];
      }
    });
    return (completedFields.length / fields.length) * 130;
  }
  const getFieldError = (fieldName) => {
    if (formik.touched[fieldName] && formik.errors[fieldName]) {
      return formik.errors[fieldName];
    }
    return "";
  };

  const handleCloseModal = () => {
    dispatch(
      updateConfig((state) => {
        state.createModal = false;
        state.itemData = "";
        state.imagePreview = "";
        state.documents = [];
        state.phoneverified = false;
        state.emailverified = false;
        state.formActiveTab = "BasicDetails";
      })
    );
  };
  const tabs = [
    {
      label: "BasicDetails",
      completed: calculateCompletionStatus([
        "name",
        "country_code",
        "phone",
        "email",
        "dob",
        "gender",
        "bio",
        "address",
        "experience",
        "profile_image",
      ]),
    },
    {
      label: "Language",
      completed: calculateCompletionStatus(["language"]),
    },
    {
      label: "Services",
      completed: calculateCompletionStatus(["id", "categories", "price"]),
    },
    {
      label: "Documents",
      completed: calculateCompletionStatus(["documents"]),
    },
  ];

  const renderTabContent = () => {
    switch (formActiveTab) {
      case "BasicDetails":
        return (
          <Basicdetails
            formik={formik}
            tabs={tabs}
            getFieldError={getFieldError}
            providersState={providersState}
            handleCloseModal={handleCloseModal}
            status={status}
            setStatus={setStatus}
            handleMoveToErrorTab={handleMoveToErrorTab}
          />
        );
      case "Language":
        return (
          <Language
            formik={formik}
            tabs={tabs}
            getFieldError={getFieldError}
            providersState={providersState}
            basicData={basicData}
            handleCloseModal={handleCloseModal}
            handleMoveToErrorTab={handleMoveToErrorTab}
            // selectedOption={selectedOption}
            // setSelectedOption={setSelectedOption}
          />
        );
      case "Services":
        return (
          <Services
            formik={formik}
            tabs={tabs}
            getFieldError={getFieldError}
            providersState={providersState}
            handleCloseModal={handleCloseModal}
            basicData={basicData}
            handleMoveToErrorTab={handleMoveToErrorTab}
          />
        );
      case "Documents":
        return (
          <Documents
            formik={formik}
            tabs={tabs}
            getFieldError={getFieldError}
            providersState={providersState}
            basicData={basicData}
            handleCloseModal={handleCloseModal}
            documents={documents}
            handleImageChange={handleImageChange}
            handleImageRemove={handleImageRemove}
            handleMoveToErrorTab={handleMoveToErrorTab}
            // selectedOption={selectedOption}
            // setSelectedOption={setSelectedOption}
          />
        );
      default:
        return null;
    }
  };
  const handleTabClick = (tab) => {
    dispatch(
      updateConfig((state) => {
        state.formActiveTab = tab?.label;
      })
    );
  };
  const handleImageChange = (event) => {
    const selectedImage = event?.target?.files;
    if (selectedImage?.length > 0) {
      dispatch(
        updateConfig((state) => {
          state.documents = [];
        })
      );
      const fileArray = Array.from(selectedImage).filter(
        (file) => file.size <= 2 * 1024 * 1024
      );
      let existingArray = Array.isArray(formik.values.documents)
        ? formik.values.documents
        : [];
      const updatedArray = existingArray.concat(fileArray);
      const oversizedFiles = Array.from(selectedImage).filter(
        (file) => file.size > 2 * 1024 * 1024
      );
      if (oversizedFiles.length > 0) {
        toast.error("The file exceed the 2 MB limit.");
      }
      formik.setFieldValue("documents", updatedArray);
      for (let i = 0; i < updatedArray.length; i++) {
        const file = updatedArray[i];
        const reader = new FileReader();
        reader.onloadend = () => {
          dispatch(
            updateConfig((state) => {
              state.documents.push(reader.result);
            })
          );
        };
        if (file) {
          reader.readAsDataURL(file);
        }
      }
    }
  };
  const handleImageRemove = (value, index) => {
    const newDocuments = documents.filter((_, i) => i !== index);
    const formikDocuments = Array.isArray(formik.values.documents)
      ? formik.values.documents
      : [];
    const newFormikDocuments = formikDocuments.filter((_, i) => i !== index);
    dispatch(
      updateConfig((state) => {
        state.documents = newDocuments;
      })
    );
    formik?.setFieldValue("documents", newFormikDocuments);
    if (formik?.values?.documents?.length === 0) {
      formik?.setFieldValue("documents", "");
      formik?.setFieldError("documents", "Upload documents");
      formik?.setFieldTouched("documents", true);
    }
  };
  const handleMoveToErrorTab = () => {
    // Move activeTab to the first tab with errors
    const firstErrorTab = Object.keys(formik.errors)[0];
    if (
      firstErrorTab === "name" ||
      firstErrorTab === "profile_image" ||
      firstErrorTab === "phone" ||
      firstErrorTab === "email" ||
      firstErrorTab === "dob" ||
      firstErrorTab === "bio" ||
      firstErrorTab === "experience"
    ) {
      setActiveTabByName("BasicDetails");
      return "";
    }
    if (firstErrorTab === "language") {
      setActiveTabByName("Language");
      return "";
    }
    if (firstErrorTab === "service_type") {
      setActiveTabByName("Services");
      return "";
    }
    if (firstErrorTab === "documents") {
      setActiveTabByName("Documents");
      return "";
    }
    if (firstErrorTab && firstErrorTab !== formActiveTab) {
      const errorTab = tabs.find((tab) => tab.fields.includes(firstErrorTab));
      setActiveTabByName(errorTab?.label);
    }
  };
  function setActiveTabByName(active) {
    const updatedTab = tabs.find((tab) => tab?.label === active);
    dispatch(
      updateConfig((state) => {
        state.formActiveTab = updatedTab?.label;
      })
    );
  }
  return {
    tabs,
    formik,
    activeTab: formActiveTab,
    handleTabClick,
    renderTabContent,
  };
};

export default useAddProviders;
