import { getAxiosInstance } from "../../../API";

export const createExpertise= async (body) => {
  const api = await getAxiosInstance();
  try {
    const response = await api.post(`/admin/service-categories/create`, body);
    return response;
  } catch (error) {
    return error?.response?.data
  }
};

export const updateExpertise = async (body) => {
  const api = await getAxiosInstance();;
  try {
    const response = await api.put(`/admin/service-categories/update`, body);
    return response;
  } catch (error) {
    return error?.response?.data
  }
};

export const viewExpertise = async (id) => {
  const api = await getAxiosInstance();
  try {
    const response = await api.get(`/admin/service-categories/view?id=${id}`);
    return response;
  } catch (error) {
    return error?.response?.data
  }
};

export const updateStatus = async (body) => {
  const api = await getAxiosInstance();
  try {
    const response = await api.put(`/admin/service-categories/status/change`, body);
    return response;
  } catch (error) {
    return error?.response?.data
  }
};
export const deleteExpertise = async (id) => {
  const api = await getAxiosInstance();
  try {
    const response = await api.delete(`/admin/service-categories/delete?id=${id}`);
    return response;
  } catch (error) {
    return error?.response?.data
  }
};