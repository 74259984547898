import { useSelector } from "react-redux";
import {
  useGetBookingDataQuery,
  useBookingTableHeadDataMutation,
} from "../../../Store/Queries/Booking";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { updateConfig as globalUpdateConfig } from "../../../Store/Slices/Global";
import { updateConfig } from "../../../Store/Slices/Booking/bookingSlice";
import { subDays, format } from "date-fns";
import { useNavigate } from "react-router-dom";
import { IconText } from "@wac-ui-dashboard/wac_component_library";
const useBookingHome = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const bookingState = useSelector((state) => state.booking);
  const { tableFields, showEditModal } = useSelector((state) => state.global);
  let actionOptions = [{ label: "Edit Columns", value: 0 }];
  let paginationOptions = [
    { label: "10 Per page", value: 10 },
    { label: "20 Per page", value: 20 },
    { label: "30 Per page", value: 30 },
  ];
  const [initialDate, setInitialDate] = useState({
    startDate: subDays(new Date(), 30),
    endDate: subDays(new Date(), 0),
    key: "selection",
  });
  const {
    data: bookingList = {},
    isLoading,
    isFetching,
    refetch,
  } = useGetBookingDataQuery({
    items_per_page: bookingState?.currentPageSize,
    currentPage: bookingState?.currentPage,
    sort_by: bookingState?.sortBy,
    sort_order: bookingState?.sortOrder,
    start:
      bookingState.start === ""
        ? format(initialDate?.startDate, "dd-MM-yyyy")
        : bookingState.start,
    end:
      bookingState.end === ""
        ? format(initialDate?.endDate, "dd-MM-yyyy")
        : bookingState.end,
    search: bookingState?.search,
    status: bookingState?.status,
    service_type_id: bookingState?.service_type_id,
    schedule: bookingState?.schedule,
  });
  const [updateStatusFields] = useBookingTableHeadDataMutation();
  useEffect(() => {
    dispatch(
      globalUpdateConfig((state) => {
        state.tableFields = bookingList?.data?.fields;
      })
    );
    // eslint-disable-next-line
  }, [bookingList]);
  const getRow = (feild, data) => {
    const rows = {
      common: (feild, data) => <p className="pro-mb-0">{data[feild]}</p>,
      service_request_id: (feild, data) => (
        <p
          className="pro-mb-0 pro-pnt"
          onClick={() => {
            // if (usersList?.data?.permissions?.can_view) {
            navigate({
              pathname: `/booking/booking-details/${data?._id}`,
            });
          }}
        >
          {data[feild]}
        </p>
      ),
      seeker: (feild, data) => (
        <div className="text-truncate icontxt_no_hover">
          <IconText
            icon={
              <img
                src={data["seeker_image"]}
                alt={"seeker_image"}
                width={20}
                height={20}
                className="object-fit-cover"
              />
            }
            title={data[feild]}
            propStyle={{
              rootIcon:
                "pro-d-flex pro-items-center pro-rounded-circle pro-overflow-hidden ",
            }}
          />
        </div>
      ),
      provider: (feild, data) => (
        <div className="text-truncate icontxt_no_hover">
          <IconText
            icon={
              <img
                src={data["provider_image"]}
                alt={"provider_image"}
                width={20}
                height={20}
                className="object-fit-cover"
              />
            }
            title={data[feild]}
            propStyle={{
              rootIcon:
                "pro-d-flex pro-items-center pro-rounded-circle pro-overflow-hidden",
            }}
          />
        </div>
      ),
      status: (feild, data) => (
        <p
          className={`pro-badge pro-mb-0 ${
            data[feild] === "Completed"
              ? "badge-success-outline"
              : data[feild] === "Accepted"
              ? " badge-success-outline"
              : data[feild] === "Pending"
              ? " badge-warning-outline"
              : data[feild] === "Rejected"
              ? " badge-danger-outline "
              : "badge-outline"
          }`}
        >
          {data[feild]}
        </p>
      ),
      service_type: (field, data) => (
        <p className={`pro-badge badge-outline pro-mb-0`}>{data[feild]}</p>
      ),
    };
    return rows?.[feild]?.(feild, data) ?? rows["common"](feild, data);
  };

  const handleClearClick = () => {
    dispatch(
      updateConfig((state) => {
        state.currentPageSize = 10;
        state.currentPage = 1;
        state.sortBy = "";
        state.sortOrder = "";
        state.start = format(subDays(new Date(), 30), "dd-MM-yyyy");
        state.end = format(new Date(), "dd-MM-yyyy");
        state.search = "";
        state.status = "";
        state.service_type_id = "";
        state.schedule = "";
      })
    );
    setInitialDate({
      startDate: subDays(new Date(), 30),
      endDate: new Date(),
      key: "selection",
    });
  };

  const handleSearch = (e) => {
    dispatch(
      updateConfig((state) => {
        state.search = e.target.value;
        state.currentPage = 1;
      })
    );
  };

  const handlePageSize = ({ value: page_size }) => {
    dispatch(
      updateConfig((state) => {
        state.currentPageSize = page_size;
        state.currentPage = 1;
      })
    );
  };
  const handlePagination = (page) => {
    dispatch(
      updateConfig((state) => {
        state.currentPage = page;
      })
    );
  };

  const handleSort = (label) => {
    if (bookingState.sortBy === label) {
      dispatch(
        updateConfig((state) => {
          state.currentPage = 1;
          state.sortOrder = bookingState.sortOrder === "asc" ? "desc" : "asc";
        })
      );
    } else {
      dispatch(
        updateConfig((state) => {
          state.currentPage = 1;
          state.sortBy = label;
          state.sortOrder = "asc";
        })
      );
    }
  };

  const handleCreateClick = () => {
    dispatch(
      updateConfig((state) => {
        state.isEdit = false;
        state.createModal = true;
      })
    );
  };

  const handleCloseClick = () => {
    dispatch(updateConfig((state) => (state.createModal = false)));
  };
  const handleDateChange = (date) => {
    dispatch(
      updateConfig((state) => {
        state.start = format(new Date(date.startDate), "dd-MM-yyyy");
        state.end = format(new Date(date.endDate), "dd-MM-yyyy");
      })
    );
  };
  const handleFilter = () => {
    dispatch(
      updateConfig((state) => {
        state.isFilter = false;
        state.createFilterModal = true;
      })
    );
  };
  const handleCloseFilter = () => {
    dispatch(updateConfig((state) => (state.createFilterModal = false)));
  };
  const handleActionChange = (item) => {
    if (item?.value === 0) {
      dispatch(
        globalUpdateConfig((state) => {
          state.showEditModal = true;
        })
      );
    }
  };
  return {
    isLoading,
    isFetching,
    tableFields,
    bookingList,
    bookingState,
    paginationOptions,
    getRow,
    refetch,
    handleSort,
    handleSearch,
    handlePageSize,
    handlePagination,
    handleCloseClick,
    handleClearClick,
    handleCreateClick,
    handleFilter,
    handleCloseFilter,
    actionOptions,
    handleActionChange,
    updateStatusFields,
    showEditModal,
    handleDateChange,
    initialDate,
  };
};

export default useBookingHome;
