import React from "react";
import Style from "./profilecard.module.scss";
import Assets from "../../../../../../Assets";

const ProfileCard = ({
  formik,
  userData,
  handleProfileImageChange,
  imagePreview,
  handleDeleteImage,
}) => {
  return (
    <div className={`${Style.root} row`}>
      <div className={Style.img_wrap}>
        <figure className="pro-mb-0">
          <label htmlFor="profileInput" />
          {imagePreview ? (
            <img
              src={imagePreview}
              alt="staff_image"
              onClick={handleProfileImageChange}
            />
          ) : (
            <img
              src={Assets?.ADMIN_PROFILE}
              alt="staff_image"
              onClick={handleProfileImageChange}
            />
          )}
          <input
            type="file"
            accept=".jpeg, .jpg , .png"
            className={`pro-input ${" error"}`}
            style={{ display: "none" }}
            id="profileInput"
            name="profile"
            onChange={(e) => handleProfileImageChange(e)}
          />
        </figure>

        <label className={Style.edit_icon} htmlFor="profileInput">
          <span  htmlFor="profileInput" className="material-symbols-outlined" onClick={handleDeleteImage}>
            edit
          </span>
        </label>

        {formik.touched.image && formik.errors.image && (
          <span className="error-text">{formik.errors.image}</span>
        )}
      </div>
      <div className="pro-ps-4 pro-pt-3 pro-pb-2 pro-d-flex pro-flex-column pro-items-center">
        {userData?.name && (
          <h4 className="pro-ttl h4 pro-mb-1">
            {userData?.name}
          </h4>
        )}
       <p className="pro-mb-0"> {(userData?.role_name || "")} | <span className="text-grey" >{(userData?.user_id || "")}</span></p>
      </div>
    </div>
  );
};

export default ProfileCard;
