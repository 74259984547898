import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  currentPage: 1,
  currentFilter: "all",
  currentPageSize: 10,
  sortBy: "",
  sortOrder: "",
  start:"",
  end:"",
  search: "",
  status: "",
  role_id:"",
  createModal: false,
  isEdit: false,
  itemId:"",
  itemData:"",
  imagePreview:"",
  deleteModal: false,
  clearSelection: false,
  createFilterModal:false,
};

const StaffSlice = createSlice({
  name: "staffslice",
  initialState,
  reducers: {
    updateConfig: (state, action) => {
      action.payload(state);
    },
  },
});

export const { updateConfig } = StaffSlice.actions;
export default StaffSlice.reducer;
