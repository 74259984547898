import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getAllRole,updateConfig } from "../../../../Store/Slices/Role/roleSlice";

const useAddRole = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const roleState = useSelector((state) => state.role);
  useEffect(() => {
    // if (roleState?.allRoles?.length === 0) {
    dispatch(getAllRole());
    // }
    // eslint-disable-next-line
  }, []);
  const handleRoleClick = (item) => {
    if (item) {
      navigate({
        pathname: "/configure/role/role-permissions",
        search: `role=${item._id}`,
      });
    } else {
      navigate("/configure/role/role-permissions");
    }
    dispatch(updateConfig((state) => (state.addModal = false)));
  };

  return {
    roleState,
    handleRoleClick,
  };
};

export default useAddRole;
