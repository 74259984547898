import { Button } from "@wac-ui-dashboard/wac_component_library";
import React from "react";
import UploadPhoto from "../../../../Global/UploadPhoto";

const Documents = ({ formik, getFieldError, providersState,handleCloseModal,handleImageChange,handleImageRemove,documents,handleMoveToErrorTab}) => {
  return (
    <div className="col">
     <div className="pro-mb-4">
        <UploadPhoto
          formik={formik}
          documents={documents}
          handleChange={handleImageChange}
          title={"Documents"}
          handleDeleteClick={handleImageRemove}
          mutiple={true}
          // status={uploadStatus?.documents}
          accept={".png,.jpeg,.jpg,.pdf"}
          addText={"Add Documents"}
          isError={getFieldError("documents") ? true : false}
          errorMsg={getFieldError("documents")}
          // showAddButton={values?.documents?.length !== 5}
        />
      </div>
      <div className={`col-12 pro-d-flex pro-justify-end ${"offcanvas-footer-sticky-btn"}`}>
        <Button
          className={"pro-btn-link lg pro-px-5"}
          onClick={handleCloseModal}
          type="button"
        >
          Cancel
        </Button>
        <Button
          type="button"
          className={"pro-btn-primary lg pro-ms-3"}
          onClick={() => {
            handleMoveToErrorTab();
            formik.handleSubmit();
          }}
        >
          {providersState?.isEdit ? "Update" : "Add"}
        </Button>
      </div>
    </div>
  );
};

export default Documents;
