import { useState } from 'react';

const useServiceTypeFeild = ({ formik,basicData}) => {
    const [index, setIndex] = useState();

    const handleRemoveField = (index) => {
      const data = formik?.values?.service_type?.filter(
        (_, itemIndex) => itemIndex !== index
      );
      formik.setFieldValue("service_type", data);
    };
    const handleOptions = () => {
      const serviceTypeIds = formik?.values?.service_type
        ?.filter(serviceType => serviceType?.id) 
        ?.map(serviceType => serviceType.id._id) || []; 
      const filteredData = basicData?.service_types_list
        ?.filter(item => !serviceTypeIds.includes(item._id)) || []; 
      const resultData = filteredData.length > 0 ? filteredData : basicData;
      return resultData
      // }
    };
    return {
      handleRemoveField,
      setIndex,
      index,
      handleOptions
    };
  };
  
  export default useServiceTypeFeild;
  