import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { forgotPassword } from "../api";
import { useDispatch } from "react-redux";
import { updateConfig } from "../../../Store/Slices/Global";
import useValidations from "../../../utils/hooks/useValidations";

const useForgotPassword = (emailRef) => {
  const dispatch = useDispatch();
  const { validateEmail } = useValidations();
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [errors, setErrors] = useState("");
  const [emailStatus, setEmailStatus] = useState("idle");
  const [emailInfo, setEmailInfo] = useState({});

//   useEffect(() => {
//     if (!emailRef?.current) {
//       navigate("/");
//     }
//     // eslint-disable-next-line react-hooks/exhaustive-deps
//   }, [emailRef]);

  useEffect(() => {
    if (emailStatus === "success") {
      navigate("/login/reset-password");
    } else if (emailStatus === "failed") {
      setErrors(emailInfo?.email);
    }
    // eslint-disable-next-line
  }, [emailStatus]);

  const handleBlur = () => {
    setErrors("");
  };

  const handleChange = (e) => {
    if (!e.target.value) {
        setErrors("*Email required");
      }
    if (!validateEmail(e.target.value)) {
        setErrors("*Enter a valid email")
      }
      if (validateEmail(e.target.value)) {
        setErrors("")
      }
    setEmail(e.target.value);
  };

  const handleLoginClick = () => {
    navigate("/login");
  };

  const handleSubmit = (e) => {
    e?.preventDefault();
    if (email && errors==="") {
      setEmailStatus("pending");
      forgotPassword({ email: email}).then(
        (response) => {
          if (response?.status === 200) {
            setEmailStatus("success");
            dispatch(
              updateConfig((state) => {
                state.otpGenerated = response?.data?.message;
                state.adminID = email;
              })
            );
          } else if (response?.code === 400) {
            setEmailInfo({
              email: response?.message?.username?.[0],
            });
            setEmailStatus("failed");
          } else if (response?.code === 422) {
            setEmailInfo({
              email: response?.errors?.email,
            });
            setEmailStatus("failed");
          } else{ setEmailStatus("failed")
          setEmailInfo({ email: response?.message });
        };
        }
      );
    } else {
      setErrors("*OTP cannot be empty");
    }
  };


  return {
    email,
    errors,
    emailStatus,
    handleChange,
    handleSubmit,
    handleBlur,
    handleLoginClick,
  };
};

export default useForgotPassword;
