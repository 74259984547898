import Style from './bookingDetailShimmer.module.scss';
const BookingDetailShimmer = () => {
  return (
    <div className={`pro-pt-5`}>
        <div className={`pro-d-flex pro-flex-column pro-items-start pro-ps-5 pro-pb-5 pro-mb-5 ${Style.border}`}>
            <div className={`pro-d-flex pro-gap-4 pro-items-center pro-mb-2`}>
                <div className={`${Style.title_shimmer} shimmer`}></div>
                <div className={`pro-pb-5  pro-ps-6 pro-pe-5 shimmer pro-rounded-2`}></div>
            </div>
            <div className={`pro-d-flex shimmer`}>
                <div className={`pro-px-6 pro-pb-3`}></div>
                <div className={`pro-px-6 pro-pb-3`}></div>
            </div>
        </div>
        <div className={`pro-p-5 ${Style.border}`}>
            <div className={`pro-ps-6 pro-pe-5 pro-pb-4 shimmer pro-mb-4`}></div>
            <div className='row pro-justify-between'>
                <div className={`col-auto`}>
                    <div className={`pro-ps-6 pro-pe-3 shimmer img`}></div>
                </div>
                <div className={`col-11 pro-d-flex pro-flex-column pro-items-start`}>
                    <div className={`pro-ps-6 pro-pe-2 shimmer pro-pb-3 pro-mb-3 pro-mt-2`}></div>
                    <div className={`pro-d-inline-flex shimmer`}>
                        <div className={`pro-pb-4 pro-px-6`}></div>
                        <div className={`pro-pb-4 pro-px-6`}></div>
                    </div>
                </div>
            </div>
            <div className='pro-mt-5'></div>
            {
                [...Array(5)].map(_=>(
                    <div className='row pro-mt-2'>
                        <div className={`col-6`}>
                            <div className={`pro-pb-3 pro-px-6 shimmer`}></div>
                        </div>
                        <div className={`col-6`}>
                            <div className={`pro-pb-3 pro-px-6 shimmer`}></div>
                        </div>
                    </div>
                ))
            }
        </div> 
        <div className={`pro-p-5 ${Style.border}`}>
            <div className={`pro-ps-6 pro-pe-5 pro-pb-4 shimmer pro-mb-4`}></div>
            <div className='row pro-justify-between'>
                <div className={`col-auto`}>
                    <div className={`pro-ps-6 pro-pe-3 shimmer img`}></div>
                </div>
                <div className={`col-11 pro-d-flex pro-flex-column pro-items-start`}>
                    <div className={`pro-ps-6 pro-pe-2 shimmer pro-pb-3 pro-mb-3 pro-mt-2`}></div>
                    <div className={`pro-d-inline-flex shimmer`}>
                        <div className={`pro-pb-4 pro-px-6`}></div>
                        <div className={`pro-pb-4 pro-px-6`}></div>
                    </div>
                </div>
            </div>
            <div className='pro-mt-5'></div>
            {
                [...Array(5)].map(_=>(
                    <div className='row pro-mt-2'>
                        <div className={`col-6`}>
                            <div className={`pro-pb-3 pro-px-6 shimmer`}></div>
                        </div>
                        <div className={`col-6`}>
                            <div className={`pro-pb-3 pro-px-6 shimmer`}></div>
                        </div>
                    </div>
                ))
            }
        </div> 
    </div>
  )
}

export default BookingDetailShimmer 