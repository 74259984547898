import { getAxiosInstance } from "../../../API";

export const createTerms= async (body) => {
  const api = await getAxiosInstance();
  try {
    const response = await api.post(`/admin/cms/terms-and-conditions/create`, body);
    return response;
  } catch (error) {
    return error?.response?.data
  }
};

export const updateTerms = async (body) => {
  const api = await getAxiosInstance();;
  try {
    const response = await api.put(`/admin/cms/terms-and-conditions/update`, body);
    return response;
  } catch (error) {
    return error?.response?.data
  }
};

export const viewTerms = async (id) => {
  const api = await getAxiosInstance();
  try {
    const response = await api.get(`/admin/cms/terms-and-conditions/edit?content_id=${id}`);
    return response;
  } catch (error) {
    return error?.response?.data
  }
};

export const updateStatus = async (body) => {
  const api = await getAxiosInstance();
  try {
    const response = await api.put(`/admin/cms/terms-and-conditions/status-change`, body);
    return response;
  } catch (error) {
    return error?.response?.data
  }
};
export const deleteTerms = async (id) => {
  const api = await getAxiosInstance();
  try {
    const response = await api.delete(`/admin/cms/terms-and-conditions/delete?content_id=${id}`);
    return response;
  } catch (error) {
    return error?.response?.data
  }
};