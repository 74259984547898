import { getAxiosInstance } from "../../../API";

export const createStaff= async (body) => {
  const api = await getAxiosInstance();
  try {
    const response = await api.post(`/admin/users/create`, body);
    return response;
  } catch (error) {
    return error?.response?.data
  }
};

export const updateExpertise = async (body) => {
  const api = await getAxiosInstance();;
  try {
    const response = await api.put(`/admin/users/update`, body);
    return response;
  } catch (error) {
    return error?.response?.data
  }
};

export const viewStaff = async (id) => {
  const api = await getAxiosInstance();
  try {
    const response = await api.get(`/admin/users/view?user_id=${id}`);
    return response;
  } catch (error) {
    return error?.response?.data
  }
};

export const updateStatus = async (body) => {
  const api = await getAxiosInstance();
  try {
    const response = await api.post(`/admin/users/status/change`, body);
    return response;
  } catch (error) {
    return error?.response?.data
  }
};
export const deleteStaff = async (id) => {
  const api = await getAxiosInstance();
  try {
    const response = await api.delete(`/admin/users/delete?user_id=${id}`);
    return response;
  } catch (error) {
    return error?.response?.data
  }
};