import { Button, Input } from "@wac-ui-dashboard/wac_component_library";
import React from "react";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import Style from "./addPrivacy.module.scss";
import useAddPrivacy from "./useAddPrivacy";
// import Assets from "../../../../Assets";

const AddPrivacy = ({ closeModal, refetch }) => {
  const { formik, handleContentChange, getFieldError, isEdit, handleStatusChange, status } = useAddPrivacy({
    closeModal,
    refetch,
  });
  return (
    <div className="pro-p-5">
      {formik?.values?.description ? (
        <div className={Style.editor_container}>
          <Input
            label={"Title*"}
            type="text"
            id="title"
            name="title"
            className={`pro-input lg ${getFieldError("title") && "error"}`}
            {...formik.getFieldProps("title")}
            error={getFieldError("title")}
            errorMessage={getFieldError("title")}
          />
          <Editor

            wrapperClassName={Style.pro_editor_wrapper}
            editorClassName={Style.pro_editor_main}
            toolbarClassName={Style.pro_editor_toolbar}
            editorState={formik.values.description}
            readOnly={false}
            onEditorStateChange={handleContentChange}
            toolbar={{
              blockType: {
                inDropdown: true,
                className: Style.dropdown_wrapper,
                dropdownClassName: Style.dropdown_menu,
              },
              link: {
                inDropdown: true,
                className: Style.dropdown_wrapper,
                dropdownClassName: Style.dropdown_menu,
              },
            }}
          />
          {isEdit && <div className="pro-toggle">
            <div className="pro-toggle-box ">
              <input
                type="checkbox"
                role="switch"
                id="status"
                checked={status}
                onChange={handleStatusChange}
              />
              <span></span>
            </div>
            <label htmlFor="status">Status</label>
          </div>}
          {formik.touched.description && formik.errors.description && (
            <span className={Style.error_text}>
              {formik.errors.description}
            </span>
          )}
        </div>
      ) : (
        <div className="title-content-shimmer pro-pt-5 ">

          <div className="shine pro-p-5 pro-mb-4 pro-mt-1 pro-w-100"></div>
            <div className="shine lg pro-w-100 pro-p-6 ">
            
            <div className="pro-p-5 pro-mb-5 pro-mt-5"></div>
            <div className="pro-p-6"></div>
          </div>
        </div>
      )}
      <div className="pro-d-flex pro-justify-end pro-pt-4">
        <button
          className={"pro-btn-link lg "}
          onClick={() => closeModal()}
          type="button"
        >
          Cancel
        </button>
        <Button
          className={"pro-btn-primary lg pro-ms-3"}
          type="submit"
          onClick={formik.handleSubmit}
        >
          Submit
        </Button>
      </div>
    </div>
  );
};

export default AddPrivacy;
