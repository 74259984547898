import {
    NavGroup,
  } from "@wac-ui-dashboard/wac_component_library";
  import React from "react";
  import Style from "./seekersDetails.module.scss";
  import useSeekersDetails from "./useSeekersDetails";
  import { Link } from "react-router-dom";
  
  const SeekersDetails = () => {
    const {
      tabNavs,
      renderTabContent,
    } = useSeekersDetails();
    return (
        <>
          <div className={`${Style.main_container}`}>
            <div className="row pro-items-center">
              <div className="col ">
                <NavGroup navigations={tabNavs} type={"type-2"} Link={Link} />
              </div>
            </div>
          </div>
          {renderTabContent()}
        </>
    );
  };
  
  export default SeekersDetails;
  