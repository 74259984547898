import { useNavigate, useParams } from "react-router-dom";
import { useGetSeekerdetailQuery } from "../../../../Store/Queries/Users/Seekers";
import {  useState } from "react";

const useSeekersDetailsLayout = () => {
  const { seekerID } = useParams();
  const activeSeeker =seekerID?.replace(/activeTab=[^&]+/, "");
  const [showAllDesc, setShowAllDesc] = useState(false);
  const navigate = useNavigate();
  const { data: seekerData, isFetching } = useGetSeekerdetailQuery({
    service_user_id: activeSeeker,
  });
  const handleBackClick = () => {
    navigate("/users/seeker");
  };

  const handleReadMoreDesc = () => {
    setShowAllDesc(true);
  };

  return {
    seekerID,
    isFetching,
    seekerData,
    showAllDesc,
    handleReadMoreDesc,
    handleBackClick 
  };
};

export default useSeekersDetailsLayout;
