import {
  ConfirmationBox,
  HeadingGroup,
  ModalLayout,
  Pagination,
  SearchFilters,
  OffCanvasLayout,
  Table,
} from "@wac-ui-dashboard/wac_component_library";
import React from "react";
import { FaSort } from "react-icons/fa";
import EmptyData from "../../Global/EmptyData";
import AddSeeker from "./AddSeeker";
import useSeeker from "./useSeeker";
// import FilterStaff from "./FilterStaff";
import OrderColumn from "../../Global/OrderColumn";
import FilterSeeker from "./FilterSeeker";

const Seeker = () => {
  const {
    isLoading,
    isFetching,
    tableFields,
    seekersList,
    seekersState,
    paginationOptions,
    getRow,
    refetch,
    handleSort,
    handleSearch,
    handlePageSize,
    handleEditAction,
    handlePagination,
    handleCloseClick,
    handleClearClick,
    handleCreateClick,
    handleDeleteModal,
    handleDeleteCloseModal,
    handleDeleteAction,
    handleFilter,
    handleCloseFilter,
    actionOptions,
    handleActionChange,
    updateStatusFields,
    showEditModal,
    handleDateChange,
    initialDate,
    handleExport
  } = useSeeker();
  return (
    <div>
      <HeadingGroup
        title={"Seekers"}
        className={`pro-mb-4`}
        buttonTitle={seekersList?.data?.permissions?.can_create && "Create"}
        handleClick={handleCreateClick}
      />
      <div className="col-auto pro-pt-3 pro-pb-6">
        <div className="row pro-w-100 pro-m-0">
          <div className="col-11 pro-px-0 search-filter-datepicker-wrap search_filter_shimmer">
            <SearchFilters
              onSearchInput={handleSearch}
              showActions={false}
              loading={isLoading}
              SearchIcon={
                <span className="material-symbols-outlined"> search </span>
              }
              // showClearFilters
              // handleClear={handleClearClick}
              searchInputProps={{ value: seekersState?.search }}
              actionOptions={actionOptions?.filter((item) => item.value === 0)}
              onActionOptionChange={handleActionChange}
              isDateRange
              showDateRange={true}
              initialDateRange={initialDate}
              onDateChange={handleDateChange}
              extraFilters={
                <>
                <div className="col-auto">
                  <button
                    className={"pro-btn pro-items-center pro-btn-outline pro-mx-1"}
                    onClick={handleFilter}
                  >
                    <span className="material-symbols-outlined x4">tune</span>
                    Filter
                  </button>
                </div>
                <div className="col-auto pro-d-flex">
                  <button
                    className={" pro-btn-link lg"}
                    onClick={handleClearClick}
                  >
                    Clear
                  </button>
                </div>
                </>
              }
            />
          </div>
          <div className="col-1 pro-pe-0">
                <button
                className={"pro-btn pro-items-center pro-btn-outline pro-h-100 pro-w-100"}
                onClick={handleExport}
              >
                Export
              </button>
          </div>
        </div>
        {seekersList?.data?.data?.length !== 0 ? (
          <>
            <Table
              multiSelect={false}
              data={seekersList?.data?.data || []}
              uniqueID={"_id"}
              fields={tableFields}
              SortIcon={<FaSort />}
              editIcon={<span className="material-symbols-outlined">edit</span>}
              deleteIcon={
                <span className="material-symbols-outlined">delete</span>
              }
              handleDelete={handleDeleteModal}
              handleEdit={handleEditAction}
              handleSort={handleSort}
              clear={seekersState?.clearSelection}
              getRow={getRow}
              loading={isFetching}
              perpage={seekersState?.currentPageSize}
              editable={seekersList?.data?.permissions?.can_update}
              deletable={seekersList?.data?.permissions?.can_delete}
              assignable={false}
              showCheckBox={seekersList?.data?.permissions?.can_update}
            />
            {seekersList?.data?.data?.length > 0 && (
              <Pagination
                currentPage={seekersState?.currentPage}
                totalPageCount={Math.ceil(
                  seekersList?.data?.count /
                  seekersState.currentPageSize
                )}
                options={paginationOptions}
                onPageChange={handlePagination}
                onActionChange={handlePageSize}
                center
              />
            )}
          </>
        ) : (
          <EmptyData />
        )}
       <OffCanvasLayout
          show={seekersState?.createModal}
          handleClose={handleCloseClick}
          title={seekersState?.isEdit ? "Edit Seekers" : "Create Seekers"}
          closeicon={<span className="material-symbols-outlined">close</span>}
          backdrop="static"
        >
          <AddSeeker refetch={refetch} seekersState={seekersState} />
        </OffCanvasLayout>
        <OffCanvasLayout
          show={seekersState?.createFilterModal}
          handleClose={handleCloseFilter}
          title={"Filter"}
          closeicon={<span className="material-symbols-outlined">close</span>}
          backdrop="static"
        >
          <FilterSeeker refetch={refetch} />
        </OffCanvasLayout>
        <ModalLayout
          show={seekersState?.deleteModal}
          handleClose={handleDeleteCloseModal}
        >
          <div className="pro-m-5">
            <ConfirmationBox
              title={"Are you sure you want to delete?"}
              subTitle={"This action cannot be undo "}
              cancelText={"No"}
              cancelAction={handleDeleteCloseModal}
              submitText={"Yes"}
              submitAction={handleDeleteAction}
              isRight={true}
            />
          </div>
        </ModalLayout>
        <ModalLayout show={showEditModal} handleClose={handleCloseClick}>
          <div className="pro-m-5">
          <OrderColumn
            title={"Choose which columns you see"}
            tableFields={seekersList?.data?.fields}
            moduleId={seekersList?.data?.module_id}
            updateData={updateStatusFields} 
            refetch={refetch}
          />
          </div>
        </ModalLayout>
      </div>
    </div>
  );
};

export default Seeker;
