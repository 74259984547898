import React from "react";
import ReactDOM from "react-dom/client";
import App from "./Components/App";
import reportWebVitals from "./reportWebVitals";
import { Provider } from "react-redux";
import { configureStore } from "@reduxjs/toolkit";
import appReducer from "./Store";
import { servicetypes } from "./Store/Queries/ServiceTypes";
import { expertise } from "./Store/Queries/Expertise";
import { staff } from "./Store/Queries/Staff";
import { tags } from "./Store/Queries/Tags";
import { role } from "./Store/Queries/Role";
import { verification } from "./Store/Queries/Verification";
import { posts } from "./Store/Queries/Posts";
import { booking } from "./Store/Queries/Booking";
import { payments } from "./Store/Queries/Payments";
import { privacypolicy } from "./Store/Queries/CMS/Privacypolicy";
import { termsandconditions } from "./Store/Queries/CMS/TermsandConditions";
import { providers } from "./Store/Queries/Users/Providers";
import { seekers } from "./Store/Queries/Users/Seekers";
import { notifications } from "./Store/Queries/Notifications";
import { faq } from "./Store/Queries/CMS/FAQ";
import { dashboard } from "./Store/Queries/Dashboard";
const root = ReactDOM.createRoot(document.getElementById("root"));
const store = configureStore({
  reducer: appReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ serializableCheck: false }).concat(
      expertise.middleware,
      servicetypes.middleware,
      staff.middleware,
      tags.middleware,
      role.middleware,
      verification.middleware,
      posts.middleware,
      booking.middleware,
      payments.middleware,
      privacypolicy.middleware,
      termsandconditions.middleware,
      providers.middleware,
      seekers.middleware,
      notifications.middleware,
      faq.middleware,
      dashboard.middleware,
    ),
});
root.render(
  <Provider store={store}>
    <App />
  </Provider>
);

// If you want to start measuring performance in your app, pass a function
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
