import {
  DataContainer,
  IconText,
} from "@wac-ui-dashboard/wac_component_library";
import Style from "./ticketCard.module.scss";

const TicketCard = ({ tickets }) => {
  return (
    <DataContainer>
      <h6 className="pro-ttl h6 pro-mb-0">Tickets</h6>
      <div className={`pro-px-0 flex-fill`}>
        <div className={`${Style.root}`}>
          {tickets.slice(0, 10).map((ticket, index) => (
            <div className={`${Style.ticket_item} pro-ps-4 pro-pe-2`} key={index}>
              <div className="row gx-0">
                <div className="col-10 pro-py-3 pro-fw-medium">
                  <IconText
                    icon={
                      <img
                        src={ticket?.image}
                        alt={"profile-pic"}
                        width={20}
                        height={20}
                        className="object-fit-cover"
                      />
                    }
                    title={ticket?.name}
                    propStyle={{
                      rootIcon:
                        "pro-d-flex pro-items-center pro-rounded-circle pro-overflow-hidden",
                    }}
                  />
                </div>
                <div className={`col-2 pro-py-3 pro-text-center ${Style.count}`}>
                  <span>{ticket?.total_count}</span>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </DataContainer>
  );
};

export default TicketCard;
