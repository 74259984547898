import { getAxiosInstance } from "../../API";

export const updateStatus = async (body) => {
  const api = await getAxiosInstance();
  try {
    const response = await api.put(`/admin/posts/status-update`, body);
    return response;
  } catch (error) {
    return error?.response?.data
  }
};
export const deletePayments = async (id) => {
  const api = await getAxiosInstance();
  try {
    const response = await api.delete(`/admin/posts/delete?post_id=${id}`);
    return response;
  } catch (error) {
    return error?.response?.data
  }
};