import {
  ConfirmationBox,
  HeadingGroup,
  ModalLayout,
  Pagination,
  SearchFilters,
  OffCanvasLayout,
  Table,
} from "@wac-ui-dashboard/wac_component_library";
import React from "react";
import { FaSort } from "react-icons/fa";
import EmptyData from "../../Global/EmptyData";
import useProviders from "./useProviders";
import OrderColumn from "../../Global/OrderColumn";
import AddProviders from "./AddProviders";
import FilterProviders from "./FilterProviders";

const Providers = () => {
  const {
    isLoading,
    isFetching,
    tableFields,
    providersList,
    providersState,
    paginationOptions,
    getRow,
    refetch,
    handleSort,
    handleSearch,
    handlePageSize,
    handleEditAction,
    handlePagination,
    handleCloseClick,
    handleCreateClick,
    handleDeleteModal,
    handleDeleteCloseModal,
    handleDeleteAction,
    handleFilter,
    handleCloseFilter,
    handleClearClick,
    actionOptions,
    handleActionChange,
    updateStatusFields,
    showEditModal,
    handleDateChange,
    initialDate,
    handleExport
  } = useProviders();
  return (
    <div>
      <HeadingGroup
        title={"Providers"}
        className={`pro-mb-4`}
        buttonTitle={providersList?.data?.permissions?.can_create && "Create"}
        handleClick={handleCreateClick}
      />
      <div className="col-auto pro-pt-3 pro-pb-6">
        <div className="row pro-w-100 pro-m-0">
          <div className="col-11 pro-px-0 search-filter-datepicker-wrap search_filter_shimmer">
          <SearchFilters
            onSearchInput={handleSearch}
            showActions={false}
            loading={isLoading}
            SearchIcon={
              <span className="material-symbols-outlined"> search </span>
            }
            // showClearFilters
            // handleClear={handleClearClick}
            searchInputProps={{ value: providersState?.search }}
            actionOptions={actionOptions?.filter((item) => item.value === 0)}
            onActionOptionChange={handleActionChange}
            isDateRange
            showDateRange={true}
            initialDateRange={initialDate}
            onDateChange={handleDateChange}
            extraFilters={
              <>
              <div className="col-auto">
              
                <button
                  className={"pro-btn pro-items-center pro-btn-outline pro-mx-1"}
                  onClick={handleFilter}
                >
                  <span className="material-symbols-outlined x4">tune</span>
                  Filter
                </button>
              </div>
              <div className="col-auto pro-d-flex">
              
                <button
                  className={" pro-btn-link lg"}
                  onClick={handleClearClick}
                >
                  
                  Clear
                </button>
              </div>
              </>
            }
          />
          </div>
          <div className="col-1 pro-pe-0">
            <button
              className={"pro-btn pro-items-center pro-btn-outline pro-w-100 pro-h-100"}
              onClick={handleExport}
            >
             Export
            </button>
          </div>
        </div>
        {providersList?.data?.data?.length !== 0 ? (
          <>
            <Table
              multiSelect={false}
              data={providersList?.data?.data || []}
              uniqueID={"_id"}
              fields={tableFields}
              SortIcon={<FaSort />}
              editIcon={<span className="material-symbols-outlined">edit</span>}
              deleteIcon={
                <span className="material-symbols-outlined">delete</span>
              }
              handleDelete={handleDeleteModal}
              handleEdit={handleEditAction}
              handleSort={handleSort}
              clear={providersState.clearSelection}
              getRow={getRow}
              loading={isFetching}
              perpage={providersState?.currentPageSize}
              editable={providersList?.data?.permissions?.can_update}
              deletable={providersList?.data?.permissions?.can_delete}
              assignable={false}
              showCheckBox={providersList?.data?.permissions?.can_update}
            />
            {providersList?.data?.data?.length > 0 && (
              <Pagination
                currentPage={providersState?.currentPage}
                totalPageCount={Math.ceil(
                  providersList?.data?.total /
                  providersState.currentPageSize
                )}
                options={paginationOptions}
                onPageChange={handlePagination}
                onActionChange={handlePageSize}
                center
              />
            )}
          </>
        ) : (
          <EmptyData />
        )}
        <OffCanvasLayout
          show={providersState?.createModal}
          handleClose={handleCloseClick}
          title={providersState?.isEdit ? "Edit Providers" : "Create Providers"}
          closeicon={<span className="material-symbols-outlined">close</span>}
          backdrop="static"
        >
          <AddProviders refetch={refetch} providersState={providersState} />
        </OffCanvasLayout>
        <OffCanvasLayout
          show={providersState?.createFilterModal}
          handleClose={handleCloseFilter}
          title={"Filter"}
          closeicon={<span className="material-symbols-outlined">close</span>}
          backdrop="static"
        >
          <FilterProviders refetch={refetch} />
        </OffCanvasLayout>
        <ModalLayout
          show={providersState?.deleteModal}
          handleClose={handleDeleteCloseModal}
        >
          <div className="pro-m-5">
            <ConfirmationBox
              title={"Are you sure you want to delete?"}
              subTitle={"This action cannot be undo "}
              cancelText={"No"}
              cancelAction={handleDeleteCloseModal}
              submitText={"Yes"}
              submitAction={handleDeleteAction}
              isRight={true}
            />
          </div>
        </ModalLayout>
        <ModalLayout show={showEditModal} handleClose={handleCloseClick}>
          <div className="pro-m-5">
            <OrderColumn
              title={"Choose which columns you see"}
              tableFields={providersList?.data?.fields}
              moduleId={providersList?.data?.module_id}
              updateData={updateStatusFields}
              refetch={refetch}
            />
          </div>
        </ModalLayout>
      </div>
    </div>
  );
};

export default Providers;
