import React from 'react'
import { Outlet } from 'react-router-dom'
import CommonLayout from '../../Pages/Layouts/CommonLayout'
import useConfigure from "./useConfigure";

const Configure = () => {
  const { drawerMenu } = useConfigure();
  return (
    <CommonLayout drawerMenu={drawerMenu}>
      <Outlet/>
    </CommonLayout>
  )
}

export default Configure
