import { useSelector } from "react-redux";
import useRouteUtils from "../../utils/hooks/useRouteUtils";
import { useMemo } from "react";

const useUser = () => {
  const { checkIfActiveRoute } = useRouteUtils();
  const globalState = useSelector((state) => state.global);
  const drawerMenu = [
    {
      title: "Providers",
      label: "Providers",
      link: "/users/providers",
      iconAsset: "DrawerIcon1",
      icon: (
        <span className="material-symbols-outlined x4">heart_check</span>
      ),
      active: checkIfActiveRoute("/users/providers"),
    },
    {
      title: "Seeker",
      label: "Seeker",
      link: "/users/seeker",
      iconAsset: "DrawerIcon1",
      icon: (
        <span className="material-symbols-outlined x4">how_to_reg</span>
      ),
      active: checkIfActiveRoute("/users/seeker"),
    },
    {
      title: "Verification",
      label: "Verification",
      link: "/users/verification",
      iconAsset: "DrawerIcon1",
      icon: (
        <span className="material-symbols-outlined x4">verified</span>
      ),
      active: checkIfActiveRoute("/users/verification"),
    },
  ];
  const drawerMenuPermission = useMemo(() => {
    let menus = Object.values(
      globalState.menu_permissions ?? []
    )?.flatMap?.((menu) =>
      menu?.permissions?.map((side_menu) => side_menu?.sub_menu_name)
    );

    return drawerMenu
      ?.filter((menu) => {
        return menus?.includes?.(menu?.label);
      })
      ?.map((menu) => {
        return {
          ...menu,
        };
      });
    //eslint-disable-next-line
  }, [globalState.menu_permissions, location.pathname]);

  return {
    drawerMenu: drawerMenuPermission,
  };
};

export default useUser;
