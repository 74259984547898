import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { useMemo } from "react";
import useRoutes from "../../../Routes/useRoutes";
import useRouteUtils from "../../../utils/hooks/useRouteUtils";
const useCommonLayout = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { menuDrawer } = useSelector((state) => state.global);
  const globalState = useSelector((state) => state.global);

  const {
    allowedDashBoardComponents,
    allowedUserComponents,
    allowedPostsComponents,
    allowedBookingComponents,
    allowedPaymentsComponents,
    allowedCmsComponents,
    allowedConfigureComponents
  } = useRoutes();

  const { checkIfActiveRoute } = useRouteUtils();

  const navigations = [
    {
      label: "Dashboard",
      link: `/${allowedDashBoardComponents?.map((item) => item?.path)?.[0]}`,
      isButton: false,
      active: checkIfActiveRoute("/dashboard"),
    },
    {
      label: "Users",
      link: `/${allowedUserComponents?.map((item) => item?.path)?.[0]}`,
      isButton: false,
      active: checkIfActiveRoute("/users"),
    },
    {
      label: "Booking",
      link: `/${allowedBookingComponents?.map((item) => item?.path)?.[0]}`,
      isButton: false,
      active: checkIfActiveRoute("/booking"),
    },
    {
      label: "Payments",
      link: `/${allowedPaymentsComponents?.map((item) => item?.path)?.[0]}`,
      isButton: false,
      active: checkIfActiveRoute("/payments"),
    },
    {
      label: "Posts",
      link: `/${allowedPostsComponents?.map((item) => item?.path)?.[0]}`,
      isButton: false,
      active: checkIfActiveRoute("/posts"),
    },
    {
      label: "CMS",
      link: `/${allowedCmsComponents?.map((item) => item?.path)?.[0]}`,
      isButton: false,
      active: checkIfActiveRoute("/CMS"),
    },
    {
      label: "Configure",
      link: `/${allowedConfigureComponents?.map((item) => item?.path)?.[0]}`,
      isButton: false,
      active: checkIfActiveRoute("/configure"),
    },
  ];
  const handleGoBack = () => {
    navigate(menuDrawer?.backTo);
  };
  const topMenuPermission = useMemo(() => {
    let menus = globalState.menu_permissions?.flatMap?.(
      (menu) => [menu?.menu_name, "Settings"]
    );
    return navigations
      .filter((menu) => menus?.includes?.(menu.label))
      .map((menu) => {
        return {
          ...menu,
        };
      });
    //eslint-disable-next-line
  }, [globalState.menu_permissions, location.pathname]);

  return {
    navigations: topMenuPermission,
    menuDrawer,
    globalState,
    handleGoBack,
  };
};

export default useCommonLayout;
