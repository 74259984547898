import {
  HeadingGroup,
  Pagination,
  SearchFilters,
  Table,
} from "@wac-ui-dashboard/wac_component_library";
import React from "react";
import EmptyData from "../../../../Global/EmptyData";
import useSeekerBooking from "./useSeekerBooking";

const SeekerBooking = ({ activeSeeker }) => {
  const {
    isLoading,
    isFetching,
    tableFields,
    seekerbooking,
    seekerState,
    paginationOptions,
    getRow,
    handleSearch,
    handlePageSize,
    handlePagination,
    handleClearClick,
  } = useSeekerBooking({ activeSeeker });
  return (
    <div className="pro-p-3">
      <div className="col-auto pro-pt-3 pro-pb-6">
        <div className="pro-d-flex pro-justify-start">
          <HeadingGroup
            title={"Bookings"}
            extraClassName={`pro-mb-1 pro-me-3 heading-wrap pro-w-auto`}
          />
          <SearchFilters
            onSearchInput={handleSearch}
            showActions={false}
            loading={isLoading}
            SearchIcon={
              <span className="material-symbols-outlined"> search </span>
            }
            searchInputProps={{ value: seekerState?.search }}
            extraFilters={
              <>
              <div className="col-auto pro-d-flex">    
                <button
                  className={" pro-btn-link lg"}
                  onClick={handleClearClick}
                >        
                  Clear
                </button>
              </div>
              </>
            }
          />
        </div>
        {seekerbooking?.data?.data?.length !== 0 ? (
          <>
            <Table
              multiSelect={false}
              data={seekerbooking?.data?.data || []}
              uniqueID={"_id"}
              fields={tableFields}
              getRow={getRow}
              loading={isFetching}
              perpage={seekerState?.currentPageSize}
              assignable={false}
            />
            {seekerbooking?.data?.data?.length > 0 && (
              <div className="pagination-fill">
                <Pagination
                  currentPage={seekerState?.currentPage}
                  totalPageCount={Math.ceil(
                    seekerbooking?.data?.total /
                      seekerState?.details_bookingPage?.currentPageSize
                  )}
                  options={paginationOptions}
                  onPageChange={handlePagination}
                  onActionChange={handlePageSize}
                  center
                />
              </div>
            )}
          </>
        ) : (
          <EmptyData />
        )}
      </div>
    </div>
  );
};

export default SeekerBooking;
