import LOGO from "./Images/Nimble.png";
// import LOGO2 from "./Images/logo_small.png";
// import LOGO_DARK from "./Images/Logo_dark1.svg";
import LOGO_INVERT from "./Images/LOGO_INVERT.svg";
import HEADER from "./Images/Nimble.svg";
import PdfLogo from './Images/pdfLogo.png'
import NO_DATA from "./Images/no_data.svg";
import EMPTY_DATA_DARK from "./Images/noDataDark.svg";
// import PROFILE_USER from "./Images/profile-user.png";
// import PROFILE_USER_SQURE from "./Images/profile-user-squre.png";
// import HEADERLOGO from "./Images/logo.svg";
// import THEME_BLACK from "./Images/black.png";
import THEME_LIGHT from "./Images/light.png";
import THEME_CLOSED from "./Images/closed.png";
// import THEME_COMPACT from "./Images/compact.png";
import THEME_DARK from "./Images/dark.png";
// import THEME_DETAILED from "./Images/detailed.png";
import THEME_EXPANDED from "./Images/expanded.png";
// import CAMPAIGN from "./Images/campaign.jpg";
// import PHOTO_IMAGE from "./Images/picture.png";
// import VIDEO_IMAGE from "./Images/media-player.png";
import ADMIN_PROFILE from "./Images/Admin_profile.png";
// import GENDER_IMAGE from "./Images/gender_image.png";
// import MESSAGE_ICON from "./Images/message-question.svg";
// import SPINNER from "./Images/spinner.gif";
// import CHECKED from "./Images/checked.svg";
// import CHECK_DISABLE from "./Images/check_diasble.svg";
// import WALLET from "./Images/wallet.svg";
// icons
// import FACEBOOK_ICON from "./Icons/Facebook.png";
// import INSTAGRAM_ICON from "./Icons/Instagram.png";
// import LINKEDIN_ICON from "./Icons/Linkedin.png";
// import YOUTUBE_ICON from "./Icons/Youtube.png";
// import TIKTOK_ICON from "./Icons/Tiktok.png";
// import GOOGLE_ICON from "./Icons/Google.png";
import STAR_ICON from "./Images/star.svg"
import STAR_RATING from "./Images/star-rating.svg"

const Assets = {
  // LOGO2,
  LOGO,
  // LOGO_DARK,
  LOGO_INVERT,
  HEADER,
  // PROFILE_USER_SQURE,
  NO_DATA,
  // THEME_BLACK,
  THEME_LIGHT,
  THEME_CLOSED,
  // THEME_COMPACT,
  THEME_DARK,
  // THEME_DETAILED,
  THEME_EXPANDED,
  // FACEBOOK_ICON,
  // INSTAGRAM_ICON,
  // LINKEDIN_ICON,
  // YOUTUBE_ICON,
  // TIKTOK_ICON,
  // GOOGLE_ICON,
  // HEADERLOGO,
  // PROFILE_USER,
  // CAMPAIGN,
  // PHOTO_IMAGE,
  // VIDEO_IMAGE,
  ADMIN_PROFILE,
  // GENDER_IMAGE,
  // MESSAGE_ICON,
  EMPTY_DATA_DARK,
  // SPINNER,
  // CHECKED,
  // CHECK_DISABLE,
  // WALLET,
  PdfLogo,
  STAR_ICON,
  STAR_RATING
};

export default Assets;
