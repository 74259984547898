import DashboardCardShimmer from "../../../Global/Shimmers/DashboardCardShimmer";
import SingleCard from "../SingleCard";
import Style from "./DashboardCards.module.scss";
import React from "react";

const DashboardCards = ({
  data,
  obj,
  transactions = false,
  loading,
  listingType,
  shimmerCount,
}) => {
  return (
    <div className={`pro-w-100`}>
      <div className={`row g-4 row-cols-2 row-cols-lg-4 row-cols-xxl-5`}>
        {!loading ? (
          <>
            {Object.keys(data || {})?.map((key) => {
              return (
                <React.Fragment key={key}>
                  <div
                    className={`col-4 ${
                      !transactions ? Style.dashboardCard_root : ""
                    }`}
                  >
                    <SingleCard
                      icon={obj?.[key]?.icon}
                      title={obj?.[key]?.title}
                      data={data?.[key]}
                    />
                  </div>
                </React.Fragment>
              );
            })}
          </>
        ) : (
          [...Array(shimmerCount)].map((_, i) => (
            <div className="col-4" key={i}>
              <DashboardCardShimmer key={i}/>
            </div>
          ))
        )}
      </div>
    </div>
  );
};

export default DashboardCards;
