import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getAxiosInstance } from "../../../API";

const initialState = {
  providerData: {},
  isFetching: "idle",
  user_id: '',
  details_bookingPage: {
    activeTab: "",
    currentPage: 1,
    currentFilter: "all",
    currentPageSize: 10,
    sortBy: "",
    sortOrder: "",
    search: "",
  },
};

export const getProviderData = createAsyncThunk(
  "provider/getProviderData",
  async (body, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.get(`/admin/service-providers/view?service_provider_id=${body?.service_provider_id}`);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const providerdataSlice = createSlice({
  name: "providerdata",
  initialState,
  reducers: {
    updateConfig: (state, action) => {
      action.payload(state);
    },
  },
  extraReducers: (builder) => {
    // Staff data
    builder.addCase(getProviderData.pending, (state) => {
      state.isFetching = true;
    });
    builder.addCase(getProviderData.fulfilled, (state, action) => {
      state.providerData = action.payload.data.data;
      state.isFetching = false;
    });
    builder.addCase(getProviderData.rejected, (state) => {
      state.isFetching = true;
    });
  },
});

export const { updateConfig } = providerdataSlice.actions;
export default providerdataSlice.reducer;
