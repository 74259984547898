import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useGetSeekerbookingQuery } from "../../../../../Store/Queries/Users/Seekers";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { updateConfig as globalUpdateConfig } from "../../../../../Store/Slices/Global";
import { updateConfig } from "../../../../../Store/Slices/Users/seekerdetailSlice";
import { IconText } from "@wac-ui-dashboard/wac_component_library";

const useSeekerBooking = ({ activeSeeker }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const seekerState = useSelector((state) => state.seekerdetails);
  const { tableFields } = useSelector((state) => state.global);
  let paginationOptions = [
    { label: "10 Per page", value: 10 },
    { label: "20 Per page", value: 20 },
    { label: "30 Per page", value: 30 },
  ];
  const {
    data: seekerbooking = {},
    isLoading,
    isFetching,
  } = useGetSeekerbookingQuery({
    items_per_page: seekerState?.details_bookingPage?.currentPageSize,
    current_page: seekerState?.details_bookingPage.currentPage,
    sort_by: seekerState?.details_bookingPage.sortBy,
    sort_order: seekerState?.details_bookingPage.sortOrder,
    search: seekerState?.details_bookingPage.search,
    user_id: activeSeeker,
  });
  useEffect(() => {
    dispatch(
      globalUpdateConfig((state) => {
        state.tableFields = seekerbooking?.data?.fields;
      })
    );
    // eslint-disable-next-line
  }, [seekerbooking]);

  const getRow = (feild, data) => {
    const rows = {
      common: (feild, data) => <p className="pro-mb-0">{data[feild]}</p>,
      name: (feild, data) => (
        <div className="pro-pnt text-truncate">
          <IconText
            icon={
              <img
                src={data["profile_image"]}
                alt={"profile-pic"}
                width={20}
                height={20}
                className="object-fit-cover"
              />
            }
            title={data[feild]}
            onClick={() => {
              navigate({
                pathname: `/booking/booking-details/${data?._id}`,
              });
            }}
            propStyle={{
              rootIcon:
                "pro-d-flex pro-items-center pro-rounded-circle pro-overflow-hidden",
            }}
          />
        </div>
      ),
      status: (feild, data) => (
        <p
          className={`pro-badge pro-mb-0 ${
            data[feild] === "Completed"
              ? "badge-success-outline"
              : data[feild] === "Accepted"
              ? " badge-success-outline"
              : data[feild] === "Pending"
              ? " badge-warning-outline"
              : data[feild] === "Rejected"
              ? " badge-danger-outline "
              : "badge-outline"
          }`}
        >
          {data[feild]}
        </p>
      ),
      service_type: (field, data) => (
        <p className={`pro-badge badge-outline pro-mb-0`}>{data[feild]}</p>
      ),
    };
    return rows?.[feild]?.(feild, data) ?? rows["common"](feild, data);
  };

  const handleSearch = (e) => {
    dispatch(
      updateConfig((state) => {
        state.details_bookingPage.search = e.target.value;
        state.details_bookingPage.currentPage = 1;
      })
    );
  };

  const handlePageSize = ({ value: page_size }) => {
    dispatch(
      updateConfig((state) => {
        state.details_bookingPage.currentPageSize = page_size;
        state.details_bookingPage.currentPage = 1;
      })
    );
  };
  const handlePagination = (page) => {
    dispatch(
      updateConfig((state) => {
        state.details_bookingPage.currentPage = page;
      })
    );
  };
  const handleClearClick = () => {
    dispatch(updateConfig((state) => {
      state.details_bookingPage.currentPageSize= 10;
      state.details_bookingPage.currentPage= 1;
      state.details_bookingPage.sortBy = "";
      state.details_bookingPage.sortOrder = "";
      state.details_bookingPage.search = "";
    }));
  };
  return {
    isLoading,
    isFetching,
    tableFields,
    seekerbooking,
    seekerState,
    paginationOptions,
    getRow,
    handleSearch,
    handlePageSize,
    handlePagination,
    handleClearClick,
  };
};

export default useSeekerBooking;
