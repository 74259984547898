import React from "react";
import Style from "../servicetype.module.scss";
import useServiceTypeFeild from "./useServiceTypeFeild";
import Select from "react-select";
import { Input } from "@wac-ui-dashboard/wac_component_library";
import Accordion from "react-bootstrap/Accordion";

const ServiceTypeFeild = ({
  formik,
  itmIndex,
  getFieldError,
  basicData,
  handleOption,
}) => {
  const { handleRemoveField, setIndex, index, handleOptions } =
    useServiceTypeFeild({ formik, basicData });
  return (
    <>
      <div className={`form-wrap-gray `}>
        <Accordion.Header
          className={`accordian-title ${
            formik.values.service_type?.length === 1 ? "single" : ""
          }`}
        >
          Service <span className="count">{itmIndex}</span>
        </Accordion.Header>
        <Accordion.Body className={`pro-pt-5`}>
          <div className="input-wrap pro-mb-4">
            <label
              htmlFor="servicetype"
              className="pro-font-sm pro-mb-1 pro-fw-medium"
            >
              Service Name
            </label>
            <Select
              placeholder={"Service Name"}
              classNamePrefix="pro-input"
              options={handleOptions() ?? []}
              getOptionValue={(option) => option?._id}
              getOptionLabel={(option) => option?.name}
              menuPlacement="auto"
              isClearable
              id={`ServiceName${itmIndex}`}
              name={`service_type.${itmIndex}.id`}
              className={`pro-input lg ${
                formik.errors.service_type?.[itmIndex]?.id &&
                formik.touched?.service_type?.[itmIndex]?.id &&
                "error"
              }`}
              value={formik.values.service_type?.[itmIndex]?.id ?? {}}
              onBlur={formik.handleBlur(`service_type.${itmIndex}.id`)}
              onChange={(value) => {
                const selectedIndex = basicData?.service_types_list.findIndex(
                  (option) => option._id === value?._id && value?._id
                );
                setIndex(selectedIndex);
                formik.setFieldValue(`service_type.${itmIndex}.id`, value);
                formik.setFieldValue(`service_type.${itmIndex}.categories`, "");
              }}
            />
            {formik.touched.service_type?.[itmIndex]?.id &&
              formik.errors.service_type?.[itmIndex]?.id && (
                <span className="error-text">
                  {formik.errors.service_type?.[itmIndex]?.id}
                </span>
              )}
          </div>

          <div className="input-wrap pro-mb-4">
            <label
              htmlFor="servicetype"
              className="pro-font-sm pro-mb-1 pro-fw-medium"
            >
              Types of Service
            </label>
            <Select
              isMulti={true}
              placeholder={"Service Type"}
              classNamePrefix="pro-input"
              options={basicData?.service_types_list[index]?.categories ?? []}
              getOptionValue={(option) => option?._id}
              getOptionLabel={(option) => option?.name}
              menuPlacement="auto"
              isClearable
              id={`ServiceTypes${itmIndex}`}
              name={`service_type.${itmIndex}.categories`}
              className={`pro-input lg ${
                formik.errors.service_type?.[itmIndex]?.categories &&
                formik.touched?.service_type?.[itmIndex]?.categories &&
                "error"
              }`}
              value={formik.values.service_type?.[itmIndex]?.categories || []}
              onBlur={formik.handleBlur(`service_type.${itmIndex}.categories`)}
              onChange={(value) =>
                formik.setFieldValue(
                  `service_type.${itmIndex}.categories`,
                  value
                )
              }
            />
            {formik.touched.service_type?.[itmIndex]?.categories &&
              formik.errors.service_type?.[itmIndex]?.categories && (
                <span className="error-text">
                  {formik.errors.service_type?.[itmIndex]?.categories}
                </span>
              )}
          </div>

          <div className="input-wrap">
            <Input
              label={"Price"}
              placeholder={"Price"}
              type="text"
              maxLength={6}
              onKeyDown={(e) => {
                const regex = /^[0-9]$/;
                const allowedKeys = [
                  "Backspace",
                  "ArrowLeft",
                  "ArrowRight",
                  "Tab",
                  "Delete",
                ];
                if (!regex.test(e.key) && !allowedKeys.includes(e.key)) {
                  e.preventDefault();
                }
              }}
              id={`ServicePrice${itmIndex}`}
              name={`service_type.${itmIndex}.price`}
              className={`pro-input pro-md-100 lg ${
                formik.errors.service_type?.[itmIndex]?.price &&
                formik.touched?.service_type?.[itmIndex]?.price &&
                "error"
              }`}
              value={formik.values.service_type?.[itmIndex]?.price || ""}
              onBlur={formik.handleBlur(`service_type.${itmIndex}.price`)}
              onChange={(e) =>
                formik.setFieldValue(
                  `service_type.${itmIndex}.price`,
                  e.target.value
                )
              }
              error={
                formik.touched.service_type?.[itmIndex]?.price &&
                formik.errors.service_type?.[itmIndex]?.price && (
                  <span className="error-text">
                    {formik.errors.service_type?.[itmIndex]?.price}
                  </span>
                )
              }
              errorMessage={
                formik.touched.service_type?.[itmIndex]?.price &&
                formik.errors.service_type?.[itmIndex]?.price && (
                  <span className="error-text">
                    {formik.errors.service_type?.[itmIndex]?.price}
                  </span>
                )
              }
            />
          </div>
          {formik.values.service_type?.length > 1 && (
            <div
              className={`${Style.delete_btn} pro-justify-center pro-d-flex pro-items-center`}
            >
              <span
                className="material-symbols-outlined x4"
                onClick={() => handleRemoveField(itmIndex)}
              >
                delete
              </span>
            </div>
          )}
        </Accordion.Body>
      </div>
    </>
  );
};

export default ServiceTypeFeild;
