import React from 'react'
import { Outlet } from 'react-router-dom'
import CommonLayout from '../../Pages/Layouts/CommonLayout'

const Payments = () => {
  return (
    <CommonLayout dashboard>
      <Outlet/>
   </CommonLayout>
  )
}

export default Payments
