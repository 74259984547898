import { getAxiosInstance } from "../../../API";

export const createPolicy= async (body) => {
  const api = await getAxiosInstance();
  try {
    const response = await api.post(`/admin/cms/privacy-policy/create`, body);
    return response;
  } catch (error) {
    return error?.response?.data
  }
};

export const updatePolicy = async (body) => {
  const api = await getAxiosInstance();;
  try {
    const response = await api.put(`/admin/cms/privacy-policy/update`, body);
    return response;
  } catch (error) {
    return error?.response?.data
  }
};

export const viewPolicy = async (id) => {
  const api = await getAxiosInstance();
  try {
    const response = await api.get(`/admin/cms/privacy-policy/edit?content_id=${id}`);
    return response;
  } catch (error) {
    return error?.response?.data
  }
};

export const updateStatus = async (body) => {
  const api = await getAxiosInstance();
  try {
    const response = await api.put(`/admin/cms/privacy-policy/status-change`, body);
    return response;
  } catch (error) {
    return error?.response?.data
  }
};
export const deletePolicy = async (id) => {
  const api = await getAxiosInstance();
  try {
    const response = await api.delete(`/admin/cms/privacy-policy/delete?content_id=${id}`);
    return response;
  } catch (error) {
    return error?.response?.data
  }
};