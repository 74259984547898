import Style from "./dashboardHome.module.scss";
import EmptyData from "../../Global/EmptyData";
import {
  HeadingGroup,
  SearchFilters,
} from "@wac-ui-dashboard/wac_component_library";
import useDashboardHome from "./useDashboardHome";
import DashboardCards from "./DashboardCards";
import DoughnutCard from "./DoughnutCard";
import BarChart from "./BarChart";
import TicketCard from "./TicketCard";
const HomeDashboardListing = () => {
  const {
    data,options,
    dashboardItems,
    isFetching,
    cardDetails,
    dashboardList,
    initialDate,
    handleDateChange,
    statusGraph,
    doughnutData,
    doughnutTextCenter,
    totalUsers,
    doughnutTextCenterService,doughnutServiceData,totalStatusService
  } = useDashboardHome();

  return (
    <>
      <div className={Style.title_wrap}>
        <div className="row ">
          <div className="col ">
            <HeadingGroup
              title={"Dashboard"}
              extraClassName={`pro-mb-0 heading-wrap`}
            />
          </div>
          <div className="col-auto">
            <SearchFilters
                // onSearchInput={handleSearch}
                searchable={false}
                showActions={false}
                isDateRange
                showDateRange={true}
                initialDateRange={initialDate}
                onDateChange={handleDateChange}
              />
          </div>
        </div>
      </div>
      <div className={`pro-w-100 pro-my-5 pro-px-5`}>
        <DashboardCards
          data={cardDetails}
          obj={dashboardItems}
          loading={isFetching}
          // listingType={Object.keys(dashboardData?.data?.totals || {}) ?? ""}
          shimmerCount={3}
        />
      </div>
      <div className="pro-px-5">
      {dashboardList?.data && Object.keys(dashboardList?.data)?.length !== 0 ? (
        <div className={Style.charts_container}>
          {!isFetching ? (
            <>
              <div className="row gx-5 ">
                <div className={`${Style.graph_box} ${Style.box_lg} flex-fill`}>
                  <DoughnutCard
                    doughnutData={doughnutData}
                    statusGraph={statusGraph}
                    doughnutTextCenter={doughnutTextCenter}
                    total={totalUsers}
                    percentage={true}
                    title={"User Status"} />
                </div>

                <div className={`${Style.graph_box}`}>
                <BarChart
                data={data}
                options={options}
                />
                </div>
                <div className={`${Style.graph_box} ${Style.box_lg} flex-fill`}>
                  <DoughnutCard
                    doughnutData={doughnutServiceData}
                    statusGraph={statusGraph}
                    doughnutTextCenter={doughnutTextCenterService}
                    total={totalStatusService}
                    percentage={false}
                    title={"Service & Action"}
                  />
                </div>
                <div className={`${Style.graph_box} ${Style.box_lg} flex-fill`}>
                  <TicketCard tickets={dashboardList?.data?.top_bookings}/>
                </div>
              </div>
            </>
          ) : (
            <div className="app-loading fill"></div>
          )}
        </div>
      ) : (
        <>
          {!isFetching ? (
            <EmptyData />
          ) : (
            <div className="app-loading fill"></div>
          )}
        </>
      )}
      </div>
    </>
  );
};

export default HomeDashboardListing;
