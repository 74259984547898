import { useFormik } from "formik";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { updateConfig } from "../../../../Store/Slices/Users/seekersSlice";
import { createSeeker, updateSeeker, OtpVerification, Otpsend } from "../api";
import { toast } from "react-toastify";
import { useEffect, useState } from "react";
import * as Yup from "yup";
import { getBasicData } from "../../../../Store/Slices/Global";
// import countryData from "../../../../utils/countryCode";
import countryData from "../../../../utils/countryCode";
import { getFormatedDate2, getFormatedDate3 } from "../../../../utils/Table";

const useAddSeeker = ({ refetch }) => {
    const dispatch = useDispatch();
    const { isEdit, itemData, itemId, imagePreview } = useSelector((state) => state.seekers);
    const { basicData } = useSelector((state) => state.global);
    const [otpnumber, setOtpnumber] = useState();
    const [otpemailshown, setOtpemailshown] = useState(false);
    const [submitname, setSubmitname] = useState("Send OTP");
    const [otpnumbershown, setOtpnumbershown] = useState(false);
    const [otpemail, setOtpemail] = useState();
    const [submitemail, setSubmitemail] = useState("Send OTP");
    const [status, setStatus] = useState(true);
    const setBasicData = async () => {
        localStorage?.setItem("basicData", "");
        dispatch(getBasicData());
    };
    const validationSchema = Yup.object({
        name: Yup.string()
            .required("*Name is required")
            .min(3, "Name must be at least 3 characters long")
            .matches(/^[^\s].*$/, "Name must not start with a space"),
        country_code: Yup.object().required("*Select country"),
        phone: Yup.string()
            .required("*Enter mobile number")
            .matches(/^[0-9]+$/, "Number must be digits")
            .min(7, "Number must be at least 7 digits")
            .max(14, "Number must be at most 14 digits"),
        email: Yup.string()
            .email("Invalid email format")
            .required("*Email is required"),
        dob: Yup.string().required("*Select date of birth"),
        gender: Yup.object(),
        bio: Yup.string()
            .required("*Bio is required")
            .min(3, "Bio must be at least 3 characters long")
            .matches(/^[^\s].*$/, "Bio must not start with a space"),
        language: Yup.array()
            .min(1, "*Select atleast one language")
            .required("Select atleast one language"),
        service_type: Yup.array()
            .min(1, "*Select atleast one service type")
            .required("Select atleast one service type"),
        profile_image: imagePreview !== ""
            ? Yup.mixed()
                .test("fileType", "Invalid file format", (value) => {
                    if (value) {
                        const supportedFormats = [
                            "image/jpeg",
                            "image/png",
                            "image/svg+xml",
                        ];
                        return supportedFormats.includes(value.type);
                    }
                    return true;
                })
                .test("fileSize", "Please upload file less than 2MB", (value) => {
                    if (value) {
                        return value.size <= 2097152;
                    }
                    return true;
                })
            : Yup.string().required("Upload image"),
    });
    const formik = useFormik({
        initialValues: {
            name: "",
            country_code: "",
            phone: "",
            email: "",
            dob: "",
            gender: "",
            bio: "",
            language: "",
            service_type: "",
            address: "",
            profile_image: ""
        },
        validationSchema,
        onSubmit: (values) => {
            let simplifiedServiceTypes = [];
            simplifiedServiceTypes = values?.service_type.map(item => ({ id: item.object_id }));
            const newlamguage = values?.language.map(item => (item._id));
            if (isEdit) {
                let data = {
                    service_user_id: itemId,
                    full_name: values?.name,
                    country_code: values?.country_code?.value,
                    phone_number: values?.phone,
                    email: values?.email,
                    dob: getFormatedDate2(values?.dob),
                    gender: values?.gender?.id,
                    bio: values?.bio,
                    language: newlamguage,
                    service_types: simplifiedServiceTypes,
                    latitude: 70,
                    longitude: 110,
                    profile_image: values?.profile_image                    ,
                    status: status ? 1 : 0,
                    address:values?.address
                };
                if (values?.profile_image === "") {
                    delete data.profile_image;
                }
                const formData = new FormData();
                Object.keys(data).forEach((key) => {
                    if (key === 'language') {
                        data.language.forEach((item, index) => {
                            formData.append(`language[${index}]`, item);
                        })
                    } else if (key === 'service_types') {
                        data.service_types.forEach((item, index) => {
                            formData.append(`service_types[${index}][id]`, item?.id);
                        })
                    } else {
                        formData.append(key, data[key]);
                    }
                });
                updateSeeker(formData).then((response) => {
                    if (response?.status === 200) {
                        toast.success("Added");
                        setBasicData();
                        refetch();
                        handleCloseModal();
                    } else if (response?.status === 422) {
                        let errors = response?.errors;
                        let errorFields = Object.keys(errors);
                        errorFields?.forEach((field) => {
                            formik.setFieldError(field, errors[field]);
                        });
                    }
                    else if (response?.success === false) {
                        let errors = response?.message;
                        let errorFields = Object.keys(errors);
                        errorFields?.forEach((field) => {
                            formik.setFieldError(field, errors[field]);
                        })
                    } else {
                        toast.error("Something went wrong");
                    }
                });
            } else {
                let data = {
                    full_name: values?.name,
                    country_code: values?.country_code?.value,
                    phone_number: values?.phone,
                    email: values?.email,
                    dob: getFormatedDate2(values?.dob),
                    gender: values?.gender?.id,
                    bio: values?.bio,
                    language: newlamguage,
                    service_types: simplifiedServiceTypes,
                    latitude: 70,
                    longitude: 110,
                    profile_image: values?.profile_image,
                    address:values?.address
                };
                const formData = new FormData();
                Object.keys(data).forEach((key) => {
                    if (key === 'language') {
                        data.language.forEach((item, index) => {
                            formData.append(`language[${index}]`, item);
                        })
                    } else if (key === 'service_types') {
                        data.service_types.forEach((item, index) => {
                            formData.append(`service_types[${index}][id]`, item?.id);
                        })
                    } else {
                        formData.append(key, data[key]);
                    }
                });
                createSeeker(formData).then((response) => {
                    if (response?.status === 200) {
                        toast.success("Added");
                        setBasicData();
                        refetch();
                        handleCloseModal();
                    } else if (response?.status === 422) {
                        let errors = response?.errors;
                        let errorFields = Object.keys(errors);
                        errorFields?.forEach((field) => {
                            formik.setFieldError(field, errors[field]);
                        });
                    } else if (response?.success === false) {
                        let errors = response?.message;
                        let errorFields = Object.keys(errors);
                        errorFields?.forEach((field) => {
                            formik.setFieldError(field, errors[field]);
                        })

                    } else {
                        toast.error("Something went wrong");
                    }
                });
            }
        },
    });
    useEffect(() => {
        if (isEdit) {
            Object?.keys(itemData || {})?.forEach((key) => {
                dispatch(
                    updateConfig(
                        (state) => (state.imagePreview = itemData?.profile_image)
                    )
                );
                if (key === "full_name") {
                    formik.setFieldValue("name", itemData?.[key]??"");
                } else if (key ==="profile_image") {
                    formik.setFieldValue("profile_image", "");
                } else if (key === "email") {
                    formik.setFieldValue("email", itemData?.[key]??"");
                } else if (key === "phone_number") {
                    formik.setFieldValue("phone", itemData?.[key]??"");
                } else if (key === "dob") {
                    formik.setFieldValue("dob", getFormatedDate3(itemData?.[key])??"");
                } else if (key === "country_code") {
                    const country = countryData?.find(country => country?.value === itemData?.country_code??"");
                    formik.setFieldValue("country_code", country);
                } else if (key === "language") {
                    let languagesarray = [];
                    // const service = basicData?.service_types.find(service => service.id === itemData?.service_type_id);
                    // formik.setFieldValue("servicetype", service);
                    for (let i = 0; i < itemData?.language?.length; i++) {
                        const file = itemData?.language[i]??"";
                        const language = basicData?.languages_list?.filter(
                            (value) => value?._id === file
                        );
                        languagesarray.push(language[0]);
                    }
                    formik.setFieldValue("language", languagesarray);
                } else if (key === "bio") {
                    formik.setFieldValue("bio", itemData?.[key]??"");
                } else if (key === "service_type") {
                    let service_types = [];
                    for (let i = 0; i < itemData?.service_type?.length; i++) {
                        const file = itemData?.service_type[i];
                        const service = basicData?.service_types?.filter(
                            (value) => value?.object_id === file?.id
                        );
                        service_types.push(service[0]);
                    }
                    formik.setFieldValue("service_type", service_types);
                } else if (key === "gender") {
                    const gender = basicData?.gender?.filter(gend => gend?.id === itemData?.gender);
                    formik.setFieldValue("gender", gender[0] ?? "");
                } else if (key === "address") {
                    formik.setFieldValue("address", itemData?.[key]);
                }
            });
            setSubmitemail("Edit")
            setSubmitname("Edit")
            setOtpnumbershown(false)
            setOtpemailshown(false)
        }
        if (!isEdit) {
            formik?.setFieldValue("country_code", {
                name: "Canada",
                code: "CA",
                label: "+1",
                value: "+1",
              });        
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isEdit, itemData]);


    const getFieldError = (fieldName) => {
        if (formik.touched[fieldName] && formik.errors[fieldName]) {
            return formik.errors[fieldName];
        }
        return "";
    };

    const handleCloseModal = () => {
        dispatch(
            updateConfig((state) => {
                state.createModal = false;
                state.itemData = "";
                state.imagePreview = "";
                state.phoneverified = false;
                state.emailverified = false;
            })
        );
    };
    const handleStatusChange = () => {
        setStatus(!status);
    };

    const numberChange = () => {
        setSubmitname("Send OTP")
        setOtpnumber()
        dispatch(
            updateConfig((state) => {
                state.phoneverified = false;
            })
        );
    }
    const otpreq = () => {
        let data = {
            "country_code": formik?.values?.country_code?.value,
            "phone_number": formik?.values?.phone,
            // "email":"pereus21@gmail.com"
        }
        Otpsend(data).then((response) => {
            if (response?.data?.success === true) {
                setSubmitname("Edit")
                setOtpnumbershown(true)
                toast.success(response?.data?.message);
            } else {
                setOtpnumbershown(false)
                toast.error(response?.message?.phone_number);
                setSubmitname("Send OTP")
            }
        });
    }
    const otpsend = () => {
        const phonePattern = /^[^\s].*$/;
        const isPhoneValid = phonePattern.test(formik?.values?.phone); 
        if (isPhoneValid && formik?.values?.phone?.length >= 7 && formik?.values?.phone?.length <= 14) {
            if (submitname === "Edit") {
                numberChange();
                setOtpnumbershown(false);
            } else {
                otpreq();
            }
        } else {
            numberChange();
            setOtpnumbershown(false);
        }
    };
    
    const otpverify = () => {
        if (!formik?.errors?.phone) {
            let data = {
                "country_code": formik?.values?.country_code?.value,
                "phone_number": formik?.values?.phone,
                "otp": Number(otpnumber)
            }
            OtpVerification(data).then((response) => {
                if (response?.data?.success === true) {
                    dispatch(
                        updateConfig((state) => {
                            state.phoneverified = true;
                        })
                    );
                    setSubmitname("Verified")
                    setOtpnumbershown(false)
                    toast.success(response?.message);
                } else {
                    dispatch(
                        updateConfig((state) => {
                            state.phoneverified = false;
                        })
                    );
                    setSubmitname("Edit")
                    setOtpnumbershown(true)
                    toast.error(response?.message);
                }
            });
        }

    };

    const emailChange = () => {
        setSubmitemail("Send OTP")
        setOtpemail()
        dispatch(
            updateConfig((state) => {
                state.emailverified = false;
            })
        );
    }
    const otpreqemail = () => {
        let data = {
            "email": formik?.values?.email,
        }
        Otpsend(data).then((response) => {
            if (response?.data?.success === true) {
                setSubmitemail("Edit")
                setOtpemailshown(true)
                toast.success(response?.data?.message);
            } else {
                setSubmitemail("Send OTP")
                setOtpemailshown(false)
                toast.error(response?.message?.email);
            }
        });
    }
    const otpsendemail = () => {
        const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        const isEmailValid = emailPattern.test(formik?.values?.email);
        if (isEmailValid) {
            if (submitemail === "Edit") {
                emailChange()
                setOtpemailshown(false);
            } else {
                otpreqemail()
            }
        } else {
            emailChange()
            setOtpemailshown(false);
        }

    };
    const otpverifyemail = () => {
        if (!formik?.errors?.email) {
            let data = {
                "email": formik?.values?.email,
                "otp": Number(otpemail)
            }
            OtpVerification(data).then((response) => {
                if (response?.status === 200) {
                    dispatch(
                        updateConfig((state) => {
                            state.emailverified = true;
                        })
                    );
                    setOtpemailshown(false)
                    setSubmitemail("Verified")
                    toast.success(response?.data?.message);
                } else {
                    dispatch(
                        updateConfig((state) => {
                            state.emailverified = false;
                        })
                    );
                    setSubmitemail("Edit")
                    setOtpemailshown(true)
                    toast.error(response?.message);
                }
            });
        }

    };
    const handleProfileChange = (event) => {
        const selectedImage = event?.target?.files[0];
        if (selectedImage) {
            formik.setFieldValue("profile_image", selectedImage);
            const file = selectedImage;
            const reader = new FileReader();
            reader.onloadend = () => {
                dispatch(
                    updateConfig((state) => {
                        state.imagePreview = reader.result;
                    })
                );
            };
            if (file) {
                reader.readAsDataURL(file);
            }
        }
    };
    const handleProfileRemove = () => {
        dispatch(
            updateConfig((state) => {
                state.imagePreview = "";
            })
        );
        formik.setFieldValue("profile_image", "");
        formik?.setFieldError("profile_image", "Upload image");
        formik?.setFieldTouched("profile_image", true);
    };
    const handlePhoneButton = () => {
        if (submitname === "Edit" || submitname === "Send OTP") {
            otpsend();
          } else {
            return ;
          }
      };
      const handleEmailButton = () => {
        if (submitemail === "Edit" || submitemail === "Send OTP") {
            otpsendemail();
          } else {
            return ;
          }
      };
    return {
        formik,
        isEdit,
        getFieldError,
        handleCloseModal,
        status,
        handleStatusChange,
        basicData,
        countryCodeOptions: countryData,
        otpsend,
        otpverify,
        setOtpnumber,
        otpnumber,
        otpemail,
        submitname,
        otpreq,
        emailChange,
        otpreqemail,
        otpsendemail,
        otpverifyemail,
        submitemail,
        setOtpemail,
        handleProfileRemove,
        handleProfileChange,
        imagePreview,
        otpnumbershown,
        otpemailshown,
        handlePhoneButton,
        handleEmailButton
    };
};

export default useAddSeeker;
