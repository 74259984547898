import { getAxiosInstance } from "../../../API";

export const createFaq= async (body) => {
  const api = await getAxiosInstance();
  try {
    const response = await api.post(`/admin/cms/faqs/create`, body);
    return response;
  } catch (error) {
    return error?.response?.data
  }
};

export const updateFaq = async (body) => {
  const api = await getAxiosInstance();;
  try {
    const response = await api.put(`/admin/cms/faqs/update`, body);
    return response;
  } catch (error) {
    return error?.response?.data
  }
};

export const viewFaq = async (id) => {
  const api = await getAxiosInstance();
  try {
    const response = await api.get(`/admin/cms/faqs/edit?faq_id=${id}`);
    return response;
  } catch (error) {
    return error?.response?.data
  }
};

export const updateStatus = async (body) => {
  const api = await getAxiosInstance();
  try {
    const response = await api.put(`/admin/cms/faqs/status-change`, body);
    return response;
  } catch (error) {
    return error?.response?.data
  }
};
export const deleteFaq = async (id) => {
  const api = await getAxiosInstance();
  try {
    const response = await api.delete(`/admin/cms/faqs/delete?faq_id=${id}`);
    return response;
  } catch (error) {
    return error?.response?.data
  }
};