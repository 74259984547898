import { useFormik } from "formik";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import {login } from "../api";
import useValidations from "../../../utils/hooks/useValidations";
// import useRoutes from "../../../routes/useRoutes";
import { updateConfig } from "../../../Store/Slices/Global";
import { useDispatch } from "react-redux";

const useAuth = (emailRef) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { validateEmail } = useValidations();

  const [loginStatus, setLoginStatus] = useState("idle");
  const [loginInfo, setLoginInfo] = useState({});
  const [showPassword, setShowPassword] = useState(true);
  const [forgotPasswordStatus, setForgotPasswordStatus] = useState("idle");
  // const { allowedSettingsComponent } = useRoutes();

  useEffect(() => {
    if (loginStatus === "success") {
      dispatch(updateConfig((state) => (state.isLogged = true)));
      // navigate(
      //   `/configure/${allowedSettingsComponent?.map((item) => item?.path)?.[0]}`
      // );
      navigate("/dashboard");
      // window.location.reload();
    } else if (loginStatus === "failed") {
      let errorFields = Object.keys(loginInfo);
      errorFields.forEach((field) => {
        formik.setFieldError(field, loginInfo[field]);
      });
    }
    // eslint-disable-next-line
  }, [loginStatus,loginInfo]);

  useEffect(() => {
    if (forgotPasswordStatus === "success") {
      navigate("/login/forgot-password");
    } 
    // eslint-disable-next-line
  }, [forgotPasswordStatus]);

  const formik = useFormik({
    initialValues: {
      username: "",
      password: "",
    },
    validate: (values) => {
      let errors = {};
      if (!values.username) {
        errors.username = "*Email required";
      }
      if (!values.password) {
        errors.password = "*Password required";
      }
      if (!validateEmail(values.username)) {
        errors.username = "*Enter a valid email";
      }
      // const validationResults = validPasswords(values.password);
      // if (
      //   !validationResults.passwordLength ||
      //   !validationResults.uppercasePassword ||
      //   !validationResults.lowercasePassword ||
      //   !validationResults.digitsPassword 
      //   // !validationResults.charsPassword
      // ) {
      //   errors.password =
      //     "*Password must be 8 characters or more and include uppercase, numbers, and symbols.";
      // }
      return errors;
    },
    onSubmit: (values) => {
      emailRef.current = values.username;
      setLoginStatus("pending");
      const newvalues= {
        email: values.username,
        password: values.password,
      }
      login(newvalues).then((response) => {
        if (response?.status === 200) {
          setLoginInfo(response?.data?.data);
          localStorage.setItem(
            "USER_ACCESS_TOKEN_NIMBLE",
            response?.data?.data?.token
          );
          setLoginStatus("success");
        } else if (response?.status === 422) {
          setLoginStatus("failed");
          setLoginInfo({ password: response?.message });
        } else if (response?.status === 401) {
          setLoginInfo({ password: response?.message });
          setLoginStatus("failed");
        } else if (response?.status === 422) {
          setLoginInfo({ password: response?.message });
          setLoginStatus("failed");
        } else{ setLoginStatus("failed")
          setLoginInfo({ password: response?.message });
        };
      });
    },
  });
  const handleForgotPassword = () => {
    localStorage.clear("USER_ACCESS_TOKEN_NIMBLE");
    // emailRef.current = formik.values.username;
    setForgotPasswordStatus("success");
  };

  const handleShowPassword = (e) => {
    e.preventDefault();
    setShowPassword(!showPassword);
    var x = document.getElementById("password");
    if (x.type === "password") {
      x.type = "text";
    } else {
      x.type = "password";
    }
  };

  return {
    formik,
    loginStatus,
    forgotPasswordStatus,
    showPassword,
    handleShowPassword,
    setForgotPasswordStatus,
    handleForgotPassword,
  };
};

export default useAuth;
