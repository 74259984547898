import { Auth } from "@wac-ui-dashboard/wac_component_library";
import { useOutletContext } from "react-router-dom";
import useForgotPassword from "./useForgotPassword";

const ForgotPassword = () => {
  const { emailRef } = useOutletContext();
  const {
    email,
    errors,
    emailStatus,
    handleSubmit,
    handleChange,
    handleBlur,
    handleLoginClick,
  } = useForgotPassword(emailRef);

  return (
    <Auth
      title={"Forgot Password"}
      buttonText={"Submit"}
      handleClick={handleSubmit}
      actionText={"Back to login"}
      handleActionClick={handleLoginClick}
      loading={emailStatus === "pending"}
    >
      <div className="input-wrap pro-mb-5">
        <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
          Please enter the  your email address
        </label>
        <input
          name="username"
          value={email}
          onChange={handleChange}
          onBlur={handleBlur}
          type="text"
          className={`pro-input lg ${errors && "error"}`}
        />
        {errors && <span className="error-text">{errors}</span>}
      </div>
      {/* {globalState.otpGenerated !== "" ? <div className="pro-my-2 pro-pnt">OTP : {globalState.otpGenerated?.slice(22)}</div> : <></>} */}
    </Auth>
  );
};

export default ForgotPassword;
