import { useFormik } from "formik";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { updateConfig } from "../../../../../Store/Slices/Global";
import countryData from "../../../../../utils/countryCode"
import { useSelector } from "react-redux";
import useValidations from "../../../../../utils/hooks/useValidations";
import { ProfileSubmit } from "../api";
import { toast } from "react-toastify";

const useProfile = () => {
  const dispatch = useDispatch();
  const globalState = useSelector((state) => state.global);
  const [showExistingPassword, setShowExistingPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [isPasswordChange, setIsPasswordChange] = useState(false);
  const { validateEmail, validPasswords } = useValidations();

  const validatePhoneNumber = (phoneNumber) => {
    return /^\d{7,14}$/.test(phoneNumber);
  };

  const formik = useFormik({
    initialValues: {
      email: "",
      profile_image: "",
      phone_number: "",
      country_code: "",
      current_password: "",
      new_password: "",
      password_confirmation:"",
    },
    validate: (values) => {
      let errors = {};
      if (!values.email) {
        errors.email = "*Email required";
      }
      if (values?.profile_image) {
        const acceptedTypes = ["image/jpeg", "image/png"];
        const maxFileSize = 2 * 1024 * 1024; // 2MB in bytes

        // Check file type
        if (
          !acceptedTypes.includes(values.profile_image.type) &&
          !values.profile_image.name.endsWith(".jpg") &&
          !values.profile_image.name.endsWith(".jpeg") &&
          !values.profile_image.name.endsWith(".png")
        ) {
          errors.profile_image = "Invalid file format. Only JPEG and PNG allowed.";
        }

        // Check file size
        if (values.profile_image.size > maxFileSize) {
          errors.profile_image = "File too large. Max 2MB allowed.";
        }
      }
      if (!validateEmail(values.email)) {
        errors.email = "*Enter a valid email";
      }
      if (!values?.phone_number) {
        errors.phone_number = "*Phone Number is required";
      } else if (!validatePhoneNumber(values.phone_number)) {
        errors.phone_number = "*Enter a valid phone number (7-14 digits)";
      }
      if (isPasswordChange) {
        const exValidationResults = validPasswords(values.current_password);
        if (
          !exValidationResults.passwordLength 
          // !exValidationResults.uppercasePassword ||
          // !exValidationResults.lowercasePassword ||
          // !exValidationResults.digitsPassword ||
          // !exValidationResults.charsPassword
        ) {
          errors.current_password =
            "*Password must be 8 characters ";
        }
        const validationResults = validPasswords(values.new_password);
        if (
          !validationResults.passwordLength 
          // !validationResults.uppercasePassword ||
          // !validationResults.lowercasePassword ||
          // !validationResults.digitsPassword ||
          // !validationResults.charsPassword
        ) {
          errors.new_password =
            "*Password must be 8 characters ";
        }
        if (values?.current_password && values?.new_password) {
          if (values?.current_password === values?.new_password) {
            errors.new_password = "* New password can't be old password";
          }
        }
        const validationResult = validPasswords(values.password_confirmation);
        if (
          !validationResult.passwordLength 
          // !validationResult.uppercasePassword ||
          // !validationResult.lowercasePassword ||
          // !validationResult.digitsPassword ||
          // !validationResult.charsPassword
        ) {
          errors.password_confirmation =
            "*Password must be 8 characters ";
        }
        if (values?.password_confirmation && values?.new_password) {
          if (values?.password_confirmation !== values?.new_password) {
            errors.password_confirmation = "*New password must be the same as confirm password";
          }
        }
      }
      return errors;
    },
    onSubmit: (values) => {
      let formData = new FormData();
      formData.append("name", globalState?.profileData?.name);
      formData.append("country_code", values?.country_code?.value);
      formData.append("phone_number", values?.phone_number);
      formData.append("email", values?.email);
      if (isPasswordChange) {
        formData.append("current_password", values?.current_password);
        formData.append("new_password", values?.new_password);
        formData.append("password_confirmation", values?.password_confirmation);
      }
      if (values?.profile_image) {
        formData.append("profile_image", values?.profile_image);
      }
      ProfileSubmit(formData).then((resp) => {
        if (resp?.status === 200) {
          toast.success("Updated");
          handleCloseModal();
        } else if (resp?.success === false) {
          let errors = resp?.message;
          let errorFields = Object.keys(errors);
          errorFields?.forEach((field) => {
            formik.setFieldError(field, errors[field]);
          });
        } else {
          toast.error("Something went wrong");
        }
      });
    },
  });
  useEffect(() => {
    if (globalState?.profileData) {
      const countryCode = countryData?.filter(
        (val) => val?.value === globalState?.profileData?.country_code
      );
      formik?.setFieldValue("email", globalState?.profileData?.email);
      formik?.setFieldValue(
        "phone_number",
        globalState?.profileData?.phone_number
      );
      formik?.setFieldValue("country_code", countryCode?.[0]);
      dispatch(
        updateConfig(
          (state) => (state.profileThumb = globalState?.profileData?.profile_image)
        )
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [globalState?.profileData, countryData]);

  const handleShowExistingPassword = () => {
    setShowExistingPassword(!showExistingPassword);
  };

  const handleProfileImageChange = (e) => {
    const image = e?.target?.files?.[0];
    if (image) {
      formik?.setFieldTouched("profile_image", true);
      const reader = new FileReader();
      reader.onload = (event) => {
        const imageDataUrl = event.target.result;
        dispatch(
          updateConfig((state) => {
            state.profileThumb = imageDataUrl;
            return state;
          })
        );
      };
      reader.readAsDataURL(image);
    }
    formik?.setFieldValue("profile_image", image);
  };

  const handleDeleteImage = () => {
    dispatch(updateConfig((state) => (state.profileThumb = "")));
    formik?.setFieldValue("profile_image", "");
    handleProfileImageChange()
  };

  const handleShowNewPassword = () => {
    setShowNewPassword(!showNewPassword);
  };
  const handleShowConfirmPassword = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  const handleCloseModal = () => {
    dispatch(updateConfig((state) => (state.profileModal = false)));
  };

  const handlePasswordChange = () => {
    setIsPasswordChange(!isPasswordChange);
  };
  return {
    formik,
    showNewPassword,
    showConfirmPassword,
    globalState,
    userDetails: globalState?.profileData,
    showExistingPassword,
    countryCodeOptions: countryData,
    isPasswordChange,
    handleCloseModal,
    handlePasswordChange,
    handleShowNewPassword,
    handleDeleteImage,
    handleProfileImageChange,
    handleShowExistingPassword,
    handleShowConfirmPassword
  };
};

export default useProfile;
