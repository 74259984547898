import { HeadingGroup, Input } from "@wac-ui-dashboard/wac_component_library";
import useManageRole from "./useManageRole";
import Style from "./manageRoles.module.scss";
import { Permissions } from "../../../Global/Permissions";
const AddRole = () => {
  const {
    update,
    status,
    state,
    roleparam,
    formik,
    getFieldError,
    handlePermissionChange,
    handleSubmenu,
    handleStatusChange
  } = useManageRole();
  return (
    <>
      <HeadingGroup
        title={"Role Management"}
        buttonTitle={"Save"}
        handleClick={formik?.handleSubmit}
        extraClassName={`pro-justify-between pro-pb-4`}
        buttonProps={{ type: "submit" }}
      />
      <div className={`row`}>
        <div className="col-4">
          <Input
            label={"Name"}
            type="text"
            id="name"
            name="name"
            className={`pro-input lg ${getFieldError("name") && " error"}`}
            {...formik.getFieldProps("name")}
            error={getFieldError("name")}
            errorMessage={getFieldError("name")}
          />
        </div>
        <div className="col-4">
          <Input
            label={"Level"}
            type="text"
            id="level"
            name="level"
            className={`pro-input lg ${getFieldError("level") && " error"}`}
            {...formik.getFieldProps("level")}
            error={getFieldError("level")}
            errorMessage={getFieldError("level")}
            onKeyDown={(e) => {
              const regex = /^[0-9]$/;
              const allowedKeys = ["Backspace", "ArrowLeft", "ArrowRight", "Tab", "Delete"];
              if (!regex.test(e.key) && !allowedKeys.includes(e.key)) {
                e.preventDefault();
              }
            }}
          />
        </div>
      </div>
      <div className="pro-pb-6">
        {(roleparam ? state.rolePermission?.[roleparam] : true) &&
          state?.allPermissions?.map?.(
            (permission, index) => (
              <div className={Style.root} key={index}>
                <Permissions
                  title={permission?.module_name}
                  permissions={permission?.permissions}
                  defaultCheckedList={
                    state.rolePermission?.[roleparam]?.modules?? (state.rolePermission?.rolename?.modules?state.rolePermission?.rolename?.modules:[])
                  }
                  menu_id={permission.module_id}
                  key={index}
                  uniqueID={"_id"}
                  singleID={permission?._id}
                  handleChange={(item) =>
                    handlePermissionChange(item, permission._id)
                  }
                  handleSubmenu={handleSubmenu}
                />
              </div>
            )
          )}
         {getFieldError("modules") && <span className="error-text">{getFieldError("modules")}</span>}
      </div>
      {update &&<div className="pro-toggle">
        <div className="pro-toggle-box ">
          <input
            type="checkbox"
            role="switch"
            id="status"
            checked={status}
            onChange={handleStatusChange}
          />
          <span></span>
        </div>
        <label htmlFor="status">Status</label>
      </div>}
    </>
  );
};

export default AddRole;
