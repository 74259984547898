import { useFormik } from "formik";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { updateConfig } from "../../../../Store/Slices/Posts/postsSlice";
import * as Yup from "yup";

const useFilterPosts= ({ refetch }) => {
  const dispatch = useDispatch();
  // const [status, setStatus] = useState(true);
  const { basicData} = useSelector((state) => state.global);

  const validationSchema = Yup.object({
    service:Yup.object(),
    service_type:Yup.object(),
    status: Yup.object(),
  });

  const formik = useFormik({
    initialValues: {
      service:'',
      service_type:'',
      status: '',
    },
    validationSchema,
    onSubmit: (values) => {
      dispatch(updateConfig((state) => {
        state.service_type_id = values?.service?.id?values?.service?.id:""
        state.schedule = values?.service_type?.id ? values?.service_type?.id:""
        state.status = values?.status?.id?.toString()??""
      }));
      handleCloseModal()
    },
  });

  const getFieldError = (fieldName) => {
    if (formik.touched[fieldName] && formik.errors[fieldName]) {
      return formik.errors[fieldName];
    }
    return "";
  };

  const handleCloseModal = () => {
    dispatch(updateConfig((state) => (state.createFilterModal = false)));
  };

  return {
    formik, getFieldError, handleCloseModal ,basicData
  };
};

export default useFilterPosts;
