
import { useDispatch } from "react-redux";
import { updateConfig } from "../../../../../Store/Slices/Users/providersSlice";
const useLanguage = () => {
    const dispatch = useDispatch();
    const handleNext = () => {
        dispatch(updateConfig((state) => (state.formActiveTab = "Services")));
      };
  return {handleNext}
}

export default useLanguage