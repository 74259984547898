import { Button } from "@wac-ui-dashboard/wac_component_library";
import React from "react";
import Select from "react-select";
import useFilterProviders from "./useFilterProviders";

const FilterProviders = () => {
  const { formik, getFieldError, handleCloseModal,basicData} = useFilterProviders();
  return (
    <div className="col">
      <div className="pro-mb-4">
        <label htmlFor="servicetype" className="pro-font-sm pro-mb-1 pro-fw-medium">
         Service Type
        </label>
        <Select
          isMulti={true}
          id="servicetype"
          placeholder={"Service Type"}
          className={`pro-input lg  ${getFieldError("servicetype") && " error"}`}
          classNamePrefix="pro-input"
          name="servicetype"
          options={basicData?.service_types?? []}
          getOptionValue={(option) => option?.id}
          getOptionLabel={(option) => option?.name}
          value={formik?.values?.servicetype}
          onBlur={formik.handleBlur("servicetype")}
          onChange={(value) => formik.setFieldValue("servicetype", value ?? "")}
          menuPlacement="auto"
          isClearable
        />
        {getFieldError("servicetype") && (
          <span className="error-text">{getFieldError("servicetype")}</span>
        )}
      </div>
      <div className="pro-mb-4">
        <label htmlFor="language" className="pro-font-sm pro-mb-1 pro-fw-medium">
         Language
        </label>
        <Select
           isMulti={true}
          id="language"
          placeholder={"Language"}
          className={`pro-input lg  ${getFieldError("language") && " error"}`}
          classNamePrefix="pro-input"
          name="language"
          options={basicData?.languages_list?? []}
          getOptionValue={(option) => option?._id}
          getOptionLabel={(option) => option?.name}
          value={formik?.values?.language}
          onBlur={formik.handleBlur("language")}
          onChange={(value) => formik.setFieldValue("language", value )}
          menuPlacement="auto"
          isClearable
        />
        {getFieldError("language") && (
          <span className="error-text">{getFieldError("language")}</span>
        )}
      </div>
      <div className="pro-mb-4">
        <label htmlFor="status" className="pro-font-sm pro-mb-1 pro-fw-medium">
         Status
        </label>
        <Select
          id="status"
          placeholder={"Status"}
          className={`pro-input lg  ${getFieldError("status") && " error"}`}
          classNamePrefix="pro-input"
          name="status"
          options={basicData?.service_type_status ?? []}
          getOptionValue={(option) => option?.id}
          getOptionLabel={(option) => option?.name}
          value={formik?.values?.status}
          onBlur={formik.handleBlur("status")}
          onChange={(value) => formik.setFieldValue("status", value ?? "")}
          menuPlacement="auto"
          isClearable
        />
        {getFieldError("status") && (
          <span className="error-text">{getFieldError("status")}</span>
        )}
      </div>
      <div
        className={`col-12 pro-d-flex pro-justify-end pro-py-4  pro-mt-auto offcanvas-footer-sticky-btn`}
      >
        <Button
          className={"pro-btn-link lg pro-px-5"}
          onClick={handleCloseModal}
          type="button"
        >
          Cancel
        </Button>

        <Button
          className={"pro-btn-primary lg pro-ms-3"}
          onClick={formik?.handleSubmit}
          type="submit"
        >
          Apply
        </Button>
      </div>
    </div>
  );
};

export default FilterProviders;
