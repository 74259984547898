import {
  Image,
  MultiColumnTable,
  SimpleTextDetails,
} from "@wac-ui-dashboard/wac_component_library";
import React from "react";
import Style from "./bookingsdetails.module.scss";
import useBookingDetails from "./useBookingDetails";
import { useOutletContext } from "react-router-dom";
import StarRating from "../../Global/StarRating";
import EmptyData from "../../Global/EmptyData";
import BookingDetailShimmer from "../../Global/Shimmers/BookingDetailShimmer";

const BookingDetails = () => {
  const { bookingdetailData } = useOutletContext();
  const { seekerdetail, providerdetail } = useBookingDetails(bookingdetailData);
  return (
    <>
      {bookingdetailData?.data &&
      Object.keys(bookingdetailData?.data).length !== 0 ? (
        <div className={`${Style.main_container}`}>
          <div className={`${Style.detail_wrapper}`}>
            <div className={` pro-pb-4 pro-ps-5 ${Style.booking_status_wrap}`}>
              <div
                className={`pro-d-flex pro-items-center pro-gap-4 ${Style.booking_id_staus}`}
              >
                <h1 className="pro-m-0">
                  {bookingdetailData?.data?.details?.service_request_id}
                </h1>
                <p className="pro-m-0 ">
                  <span
                    className={`pro-badge ${
                      bookingdetailData?.data?.details?.status === "Completed"
                        ? "badge-success-outline"
                        : bookingdetailData?.data?.details?.status ===
                          "Accepted"
                        ? " badge-success-outline"
                        : bookingdetailData?.data?.details?.status === "Pending"
                        ? " badge-warning-outline"
                        : bookingdetailData?.data?.details?.status ===
                          "Rejected"
                        ? " badge-danger-outline "
                        : "badge-outline"
                    }`}
                  >
                    {bookingdetailData?.data?.details?.status}
                  </span>
                </p>
              </div>
              <p className="pro-m-0">
                {bookingdetailData?.data?.details?.service_date}
              </p>
            </div>
            <div className={`${Style.booking_item}`}>
              <SimpleTextDetails
                title={"Seeker" ?? ""}
                extraClassNames={`pro-mb-4`}
              />
              <div className={`${Style.top_wrap}`}>
                <div className="row pro-mb-5">
                  <div className="col-1">
                    <Image
                      src={
                        bookingdetailData?.data?.details?.service_user
                          ?.profile_image
                      }
                      width={15}
                      height={15}
                      propStyle={{
                        root: Style.img_wrap,
                      }}
                    />
                  </div>
                  <div className="col-11">
                    <p className="pro-mb-2 pro-fw-bold">
                      {bookingdetailData?.data?.details?.service_user?.name}
                    </p>
                    <div className="col pro-d-flex pro-items-center">
                      <span className="material-symbols-outlined x4 pro-me-1">
                        location_on
                      </span>
                      <p className="pro-mb-0 pro-fw-bold">
                        {bookingdetailData?.data?.details?.address}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col">
                <MultiColumnTable
                  data={seekerdetail}
                  extraClassNames={`multi_column_table_item_details`}
                />
              </div>
            </div>
            <div className={`${Style.booking_item}`}>
              <SimpleTextDetails
                title={"Providers" ?? ""}
                extraClassNames={`pro-mb-4`}
              />
              <div className="row pro-mb-5 ">
                <div className="col-1">
                  <Image
                    src={
                      bookingdetailData?.data?.details?.service_provider
                        ?.profile_image
                    }
                    width={15}
                    height={15}
                  />
                </div>
                <div className="col-11">
                  <p className="pro-mb-2 pro-fw-bold">
                    {bookingdetailData?.data?.details?.service_provider?.name}
                    <span className="pro-badge pro-ms-2">
                      {bookingdetailData?.data?.details?.service_type}
                    </span>
                  </p>
                  <div className="col pro-d-flex pro-items-center">
                    <span className="material-symbols-outlined x4 pro-me-1">
                      calendar_month
                    </span>
                    <p className="pro-mb-0 pro-fw-bold">
                      {bookingdetailData?.data?.details?.service_date}
                    </p>
                  </div>
                </div>
              </div>
              <div className="col">
                <MultiColumnTable
                  data={providerdetail}
                  extraClassNames={`multi_column_table_item_details`}
                />
              </div>
            </div>
            <div className={`${Style.booking_item}`}>
              <SimpleTextDetails
                title={"Feedback"}
                extraClassNames={`pro-mb-4`}
              />
              <div className="row pro-justify-content-center">
                <div className="col-6 pro-d-flex pro-items-center">
                  <p className="pro-m-0">Rating</p>
                </div>
                <div className="col-6 pro-d-flex pro-items-center ">
                  {/* <StarRating rating={2.5} />
                <div className={Style.star_rating}>
                  <div className={Style.golden_stars}></div>
                </div> */}
                  <div className={`star-rating`}>
                    {/* <input
                    type="number"
                    value={rating}
                    onChange={handleInputChange}
                    min="0"
                    max="5"
                    step="0.1"
                  /> */}
                    <StarRating
                      rating={bookingdetailData?.data?.feedback?.rating}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className={`${Style.booking_item}`}>
              <SimpleTextDetails
                title={"About the service"}
                extraClassNames={`pro-mb-4`}
              />
              <div className="row pro-justify-content-center">
                <div className="col-6 pro-d-flex pro-items-center">
                  <p className="pro-m-0">Tags</p>
                </div>
                <div className="col-6 pro-d-flex pro-items-center ">
                  {bookingdetailData?.data?.details?.tags?.map((tag, i) => (
                    <span className="pro-badge pro-me-2" key={i}>
                      {tag}
                    </span>
                  ))}
                </div>
              </div>
            </div>
            <div className={`${Style.booking_item}`}>
              <SimpleTextDetails
                title={"Comments"}
                extraClassNames={`pro-mb-4`}
              />
              {bookingdetailData?.data?.feedback?.comment ? (
                <div className="col pro-d-flex">
                  <p>{bookingdetailData?.data?.feedback?.comment}</p>
                  <div></div>
                </div>
              ) : (
                <EmptyData />
              )}
            </div>
            <div className={`${Style.booking_item}`}>
              <SimpleTextDetails
                title={"Upload Images"}
                extraClassNames={`pro-mb-4`}
              />
              <div className="col pro-d-flex pro-gap-3 upload-img-grid">
                {bookingdetailData?.data?.feedback?.length !== 0 &&
                bookingdetailData?.data?.feedback?.image?.length !== 0 ? (
                  bookingdetailData?.data?.feedback?.image?.map((tag, i) => (
                    <div className="col" key={i}>
                      <Image src={tag} width={108} height={108} />
                    </div>
                  ))
                ) : (
                  <div className="pro-w-100">
                    <EmptyData />
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      ) : bookingdetailData?.data &&
        Object.keys(bookingdetailData?.data).length === 0 ? (
        <EmptyData />
      ) : (
        <BookingDetailShimmer/>
      )}
      {/* {renderTabContent()} */}
    </>
  );
};

export default BookingDetails;
