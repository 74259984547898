import { useSelector } from "react-redux";
import { posts,useGetPostsDataQuery,usePostsTableHeadDataMutation } from "../../../Store/Queries/Posts";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import {  updateConfig as globalUpdateConfig } from "../../../Store/Slices/Global";
import { updateConfig } from "../../../Store/Slices/Posts/postsSlice";
import { updateStatus,deletePosts} from "../api";
import { toast } from "react-toastify";
import { current } from "@reduxjs/toolkit";
import { subDays,format} from "date-fns";
import { IconText } from "@wac-ui-dashboard/wac_component_library";

const usePostsHome = () => {
  const dispatch = useDispatch();
  const postsState = useSelector((state) => state.posts);
  const { tableFields,showEditModal} = useSelector((state) => state.global);
  let actionOptions = [{ label: "Edit Columns", value: 0 }];
  let paginationOptions = [
    { label: "10 Per page", value: 10 },
    { label: "20 Per page", value: 20 },
    { label: "30 Per page", value: 30 },
  ];
  const [initialDate, setInitialDate] = useState({
    startDate: subDays(new Date(), 30),
    endDate: subDays(new Date(), 0),
    key: "selection",
  });
  const {
    data: postsList = {},
    isLoading,
    isFetching,
    refetch,
  } = useGetPostsDataQuery({
    items_per_page: postsState.currentPageSize,
    currentPage: postsState.currentPage,
    sort_by: postsState.sortBy,
    sort_order: postsState.sortOrder,
    start: postsState.start ===""? format(initialDate?.startDate, 'dd-MM-yyyy'):postsState.start,
    end: postsState.end ===""? format(initialDate?.endDate, 'dd-MM-yyyy'):postsState.end,
    search: postsState.search,
    status:postsState?.status,
    service_type_id:postsState?.service_type_id,
    schedule:postsState?.schedule,
  });
  const [updateStatusFields] = usePostsTableHeadDataMutation();
  useEffect(() => {
    dispatch(
      globalUpdateConfig((state) => {
        state.tableFields = postsList?.message?.fields;
      })
    );
    // eslint-disable-next-line
  }, [postsList]);

  const handleChange = (data) => {
    let params = {
      post_id: data?._id,
      status: data.status === 1 ? 0 : 1,
    };
    updateStatus(params);
    dispatch(
      posts.util.updateQueryData(
        "getPostsData",
        {
          items_per_page: postsState.currentPageSize,
          currentPage: postsState.currentPage,
          sort_by: postsState.sortBy,
          sort_order: postsState.sortOrder,
          start: postsState.start ===""? format(initialDate?.startDate, 'dd-MM-yyyy'):postsState.start,
          end: postsState.end ===""? format(initialDate?.endDate, 'dd-MM-yyyy'):postsState.endDate,
          search: postsState.search,
          status:postsState?.status,
          service_type_id:postsState?.service_type_id,
          schedule:postsState?.schedule,
                },
        (cachedData) => {
          let updatedCachedData = { ...current(cachedData) };
          let newResult = updatedCachedData?.message?.data?.map((res) =>
            res?._id === data?._id
              ? {
                  ...res,
                  status: data?.status === 1 ? 0 : 1,
                }
              : res
          );
          updatedCachedData = {
            ...updatedCachedData,
            message: {
              ...updatedCachedData.message,
              data: newResult,
            },
          };
          return updatedCachedData;
        }
      )
    );
  };
  const getRow = (feild, data) => {
    const rows = {
      common: (feild, data) => <p className="pro-mb-0">{data[feild]}</p>,
      seeker_name: (feild, data) => (
        <div className="text-truncate icontxt_no_hover">
          <IconText
            icon={
              <img
                src={data["image"]}
                alt={"Profile-pic"}
                width={20}
                height={20}
                className="object-fit-cover"
              />
            }
            title={data[feild]}
            propStyle={{
              rootIcon:
                "pro-d-flex pro-items-center pro-rounded-circle pro-overflow-hidden ",
            }}
          />
        </div>
      ),
      status: (feild, data) => {
        const status = data[feild] === 1;
        return (
          <>
              <div className="pro-toggle">
                <div className="pro-toggle-box">
                  <input
                    type="checkbox"
                    role="switch"
                    id="status"
                    checked={status}
                    onChange={() => handleChange(data)}
                  />
                  <span></span>
                </div>
              </div>
          </>
        );
      },
      service: (field, data) => (
        <p className={`pro-badge badge-outline  pro-mb-0`}>{data[feild]}</p>
      ),
    };
    return rows?.[feild]?.(feild, data) ?? rows["common"](feild, data);
  };

  const handleClearClick = () => {
    dispatch(updateConfig((state) => {
      state.currentPageSize= 10;
      state.currentPage= 1;
      state.sortBy = "";
      state.sortOrder = "";
      state.start= format(subDays(new Date(), 30), 'dd-MM-yyyy');
      state.end= format(new Date(), 'dd-MM-yyyy');
      state.search = "";
      state.status = "";
      state.service_type_id = "";
      state.schedule = "";
    }));
    setInitialDate({
        startDate: subDays(new Date(), 30),
        endDate: new Date(),
        key: "selection",
      });
  };

  const handleSearch = (e) => {
    dispatch(
      updateConfig((state) => {
        state.search = e.target.value;
        state.currentPage = 1;
      })
    );
  };

  const handlePageSize = ({ value: page_size }) => {
    dispatch(
      updateConfig((state) => {
        state.currentPageSize = page_size;
        state.currentPage = 1;
      })
    );
  };
  const handlePagination = (page) => {
    dispatch(
      updateConfig((state) => {
        state.currentPage = page;
      })
    );
  };

  const handleSort = (label) => {
    if (postsState.sortBy === label) {
      dispatch(
        updateConfig((state) => {
          state.currentPage = 1;
          state.sortOrder = postsState.sortOrder === "asc" ? "desc" : "asc";
        })
      );
    } else {
      dispatch(
        updateConfig((state) => {
          state.currentPage = 1;
          state.sortBy = label;
          state.sortOrder = "asc";
        })
      );
    }
  };

  const handleCreateClick = () => {
    dispatch(
      updateConfig((state) => {
        state.isEdit = false;
        state.createModal = true;
      })
    );
  };

  const handleCloseClick = () => {
    dispatch(updateConfig((state) => (state.createModal = false)));
  };
  const handleDeleteAction = () => {
    let id = postsState?.itemId;
      deletePosts(id).then((response) => {
        if (response?.status === 200) {
          toast.success("Deleted");
          refetch(); 
          handleDeleteCloseModal();
          dispatch(updateConfig((state) => (state.clearSelection = true)));
          const clearSelection = setTimeout(() => {
            dispatch(updateConfig((state) => (state.clearSelection = false)));
            clearTimeout(clearSelection);
          }, 500);
        } else {
          toast.error("Something went wrong");
          handleDeleteCloseModal()
        }
      });
  };
  const handleDateChange = (date) => {
    dispatch(
      updateConfig((state) => {
        state.start = format(new Date(date.startDate),'dd-MM-yyyy');
        state.end = format(new Date(date.endDate),'dd-MM-yyyy');
      })
    );
  };
  const handleDeleteCloseModal = (e) => {
    dispatch(
      updateConfig((state) => {
        state.deleteModal = !state.deleteModal;
        state.itemId = ''
        state.itemData=''
      })
    );
  };
  const handleDeleteModal = (e) => {
    dispatch(
      updateConfig((state) => {
        state.deleteModal = !state.deleteModal;
        state.itemId = e?.[0];
      })
    );
  };
  const handleFilter = () => {
    dispatch(
      updateConfig((state) => {
        state.isFilter = false;
        state.createFilterModal = true;
      })
    );
  };
  const handleCloseFilter = () => {
    dispatch(updateConfig((state) => (state.createFilterModal = false)));
  };
  const handleActionChange = (item) => {
    if (item?.value === 0) {
      dispatch(
        globalUpdateConfig((state) => {
          state.showEditModal = true;
        })
      );
    }
  };
  return {
    isLoading,
    isFetching,
    tableFields,
    postsList,
    postsState,
    paginationOptions,
    getRow,
    refetch,
    handleSort,
    handleSearch,
    handlePageSize,
    handlePagination,
    handleCloseClick,
    handleClearClick,
    handleCreateClick,
    handleDeleteModal,
    handleDeleteCloseModal,
    handleDeleteAction,
    handleFilter,
    handleCloseFilter,
    actionOptions,
    handleActionChange,
    updateStatusFields,
    showEditModal,
    handleDateChange,
    initialDate
  };
  
};

export default usePostsHome;
