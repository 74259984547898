import { Button, Input } from "@wac-ui-dashboard/wac_component_library";
import React from "react";
import useAddTags from "./useAddTags";
import Select from "react-select";

const AddTags = ({ refetch }) => {
  const { formik, isEdit, getFieldError, handleCloseModal,status,handleStatusChange,basicData} = useAddTags({
    refetch,
  });
  return (
    <div className="col">
      <div className="pro-mb-4">
      <Input
        label={"Name"}
        type="text"
        id="name"
        name="name"
        className={`pro-input lg ${getFieldError("name") && " error"}`}
        {...formik.getFieldProps("name")}
        error={getFieldError("name")}
        errorMessage={getFieldError("name")}
      />
      </div>
      <div className="pro-mb-4">
        <label htmlFor="servicetype" className="pro-font-sm pro-mb-1 pro-fw-medium">
         Service Type
        </label>
        <Select
          id="servicetype"
          placeholder={"Service Type"}
          className={`pro-input lg  ${getFieldError("servicetype") && " error"}`}
          classNamePrefix="pro-input"
          name="servicetype"
          options={basicData?.service_types?? []}
          getOptionValue={(option) => option?.id}
          getOptionLabel={(option) => option?.name}
          value={formik?.values?.servicetype}
          onBlur={formik.handleBlur("servicetype")}
          onChange={(value) => formik.setFieldValue("servicetype", value ?? "")}
          menuPlacement="auto"
          isClearable
        />
        {getFieldError("servicetype") && (
          <span className="error-text">{getFieldError("servicetype")}</span>
        )}
      </div>
      {isEdit &&<div className="pro-toggle">
        <div className="pro-toggle-box ">
          <input
            type="checkbox"
            role="switch"
            id="status"
            checked={status}
            onChange={handleStatusChange}
          />
          <span></span>
        </div>
        <label htmlFor="status">Status</label>
      </div>}

      <div
        className={`col-12 pro-d-flex pro-justify-end ${"offcanvas-footer-sticky-btn"}`}
      >
        <Button
          className={"pro-btn-link lg pro-px-5"}
          onClick={handleCloseModal}
          type="button"
        >
          Cancel
        </Button>

        <Button
          type="submit"
          className={"pro-btn-primary lg pro-ms-3"}
          onClick={formik.handleSubmit}
        >
          {isEdit ? "Update" : "Add"}
        </Button>
      </div>
    </div>
  );
};

export default AddTags;
