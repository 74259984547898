import { createApi } from "@reduxjs/toolkit/query/react";
import { getAxiosInstance } from "../../../../API";

const axiosBaseQuery =
  () =>
  async ({ params, endpoint, method, body }) => {
    const api = await getAxiosInstance();
    const getParams = (key) => {
      if (params?.[key]) { 
        if (key === "service_type_id") {
          return params[key].map((item, index) => `${key}[${index}]=${item}`).join('&') + '&';
        }else if(key === "languages") {
          return params[key].map((item, index) => `${key}[${index}]=${item}`).join('&') + '&';
        }else {
          return `${key}=${params[key]}&`;
        }
      } else {
        return "";
      }
    };

    if (method === "get") {
      try {
        const response = await api[method](
            `${endpoint}?${getParams("start")}${getParams("end")}${getParams("sort_by")}sort_order=${
              params?.sort_order || "desc"
            }&length=${
              params?.page_size || 10
            }&page=${params?.currentPage || ""
            }&status=${params?.status||""}&${getParams("service_type_id")}${getParams("service_provider_id")}${getParams("languages")}${getParams("search")}${getParams("user_id")}`,
          body
        );
        return { data: response.data };
      } catch (axiosError) {
        let err = axiosError;
        return {
          error: {
            status: err.response?.status,
            data: err.response?.data || err.message,
          },
        };
      }
    } else {
      try {
        const response = await api[method](
          `${endpoint}?${getParams("start")}${getParams("end")}}${getParams("sort_by")}sort_order=${
            params?.sort_order || "desc"
          }&length=${
            params?.page_size || 10
          }&page=${params?.currentPage || ""
          }&status=${params?.status||""}&${getParams("service_type_id")}${getParams("languages")}${getParams("search")}${getParams("user_id")}`,
          body
        );
        return { data: response.data };
      } catch (axiosError) {
        let err = axiosError;
        return {
          error: {
            status: err.response?.status,
            data: err.response?.data || err.message,
          },
        };
      }
    }
  };

export const providers = createApi({
  reducerPath: "providersApi",
  baseQuery: axiosBaseQuery(),
  tagTypes: ["providers"],
  endpoints: (builder) => ({
    getProvidersData: builder.query({
      query: (values) => ({
        method: "get",
        params: values,
        endpoint: `/admin/service-providers`,
      }),
      providesTags: ["providers"],
    }),
    providersTableHeadData: builder.mutation({
      query: (body) => ({
        method: "post",
        body,
        endpoint: `/admin/user-field-save`,
      }),

      invalidatesTags: ["providers"],
    }),
    getProviderdetail: builder.query({
      query: (values) => ({
        method: "get",
        params: values,
        endpoint: `/admin/service-providers/view`,
      }),

      invalidatesTags: ["providersdetail"],
    }),
    getProviderbooking: builder.query({
      query: (values) => ({
        method: "get",
        params: values,
        endpoint: `/admin/user-service-request-list`,
      }),

      invalidatesTags: ["providerbooking"],
    }),
  }),
});
export const{useGetProvidersDataQuery, useProvidersTableHeadDataMutation,useGetProviderdetailQuery,useGetProviderbookingQuery}=providers
