import { useSelector } from "react-redux";
import {useGetVerificationDataQuery,useVerificationTableHeadDataMutation } from "../../../Store/Queries/Verification";
import { useEffect, useState} from "react";
import { useDispatch } from "react-redux";
import { updateConfig as globalUpdateConfig } from "../../../Store/Slices/Global";
import { updateConfig } from "../../../Store/Slices/Users/verificationSlice";
import { subDays,format} from "date-fns";
import { IconText } from "@wac-ui-dashboard/wac_component_library";

const useVerification = () => {
  const dispatch = useDispatch();
  const verificationState = useSelector((state) => state.verification);
  const { tableFields,showEditModal} = useSelector((state) => state.global);
  const [showDocumentVerificationModal, setShowDocumentVerificationModal] =useState(false);
  const [activeProfileID, setActiveProfileID] = useState("");
  let actionOptions = [{ label: "Edit Columns", value: 0 }];
  let paginationOptions = [
    { label: "10 Per page", value: 10 },
    { label: "20 Per page", value: 20 },
    { label: "30 Per page", value: 30 },
  ];
  const [initialDate, setInitialDate] = useState({
    startDate: subDays(new Date(), 30),
    endDate: subDays(new Date(), 0),
    key: "selection",
  });
  const {
    data: verificationList = {},
    isLoading,
    isFetching,
    refetch,
  } = useGetVerificationDataQuery({
    items_per_page: verificationState.currentPageSize,
    currentPage: verificationState.currentPage,
    sort_by: verificationState.sortBy,
    sort_order: verificationState.sortOrder,
    start: verificationState.start===""? format(initialDate?.startDate, 'dd-MM-yyyy'):verificationState.start,
    end: verificationState.end===""? format(initialDate?.endDate, 'dd-MM-yyyy'):verificationState.end,
    search: verificationState.search,
    status:verificationState?.status,

  });
  const [updateStatusFields] = useVerificationTableHeadDataMutation();
  useEffect(() => {
    dispatch(
      globalUpdateConfig((state) => {
        state.tableFields = verificationList?.data?.fields;
      })
    );
    // eslint-disable-next-line
  }, [verificationList]);
  
  const getRow = (feild, data) => {
    const rows = {
      common: (feild, data) => <p className="pro-mb-0">{data[feild]}</p>,
      user_name : (feild, data) => (
        <div className=" text-truncate icontxt_no_hover">
          <IconText
            icon={
              <img
                src={data["profile_image"] }
                alt={"profile-pic"}
                width={20}
                height={20}
                className="object-fit-cover"
              />
            }
            title={data[feild]}
            propStyle={{
              rootIcon:
                "pro-d-flex pro-items-center pro-rounded-circle pro-overflow-hidden",
            }}
          />
        </div>
      ),
      documents: (feild, data) =>  <p className="pro-pnt pro-mb-0" onClick={() => onDocumentClick(data)}>{data?.original_file_name}</p>,
    };
    return rows?.[feild]?.(feild, data) ?? rows["common"](feild, data);
  };

  const handleClearClick = () => {
    dispatch(updateConfig((state) => {state.search = ""
      state.currentPageSize= 10;
      state.currentPage= 1;
      state.sortBy = "";
      state.sortOrder = "";
      state.start= format(subDays(new Date(), 30), 'dd-MM-yyyy');
      state.end= format(new Date(), 'dd-MM-yyyy');
      state.search = "";
      state.status = "";
    }));
    setInitialDate({
        startDate: subDays(new Date(), 30),
        endDate: new Date(),
        key: "selection",
      });
  };

  const handleSearch = (e) => {
    dispatch(
      updateConfig((state) => {
        state.search = e.target.value;
        state.currentPage = 1;
      })
    );
  };

  const handlePageSize = ({ value: page_size }) => {
    dispatch(
      updateConfig((state) => {
        state.currentPageSize = page_size;
        state.currentPage = 1;
      })
    );
  };
  const handlePagination = (page) => {
    dispatch(
      updateConfig((state) => {
        state.currentPage = page;
      })
    );
  };

  const handleSort = (label) => {
    if (verificationState.sortBy === label) {
      dispatch(
        updateConfig((state) => {
          state.currentPage = 1;
          state.sortOrder = verificationState.sortOrder === "asc" ? "desc" : "asc";
        })
      );
    } else {
      dispatch(
        updateConfig((state) => {
          state.currentPage = 1;
          state.sortBy = label;
          state.sortOrder = "asc";
        })
      );
    }
  };


  const handleCreateClick = () => {
    dispatch(
      updateConfig((state) => {
        state.isEdit = false;
        state.createModal = true;
      })
    );
  };

  const handleCloseClick = () => {
    dispatch(updateConfig((state) => (state.createModal = false)));
  };

  const handleDateChange = (date) => {
    dispatch(
      updateConfig((state) => {
        state.start = format(new Date(date.startDate),'dd-MM-yyyy');
        state.end = format(new Date(date.endDate),'dd-MM-yyyy');
      })
    );
  };

  const handleActionChange = (item) => {
    if (item?.value === 0) {
      dispatch(
        globalUpdateConfig((state) => {
          state.showEditModal = true;
        })
      );
    }
  };
  const closeDocumentModal = () => {
    setShowDocumentVerificationModal(false);
  };

  const onDocumentClick = (data) => {
    dispatch(
        updateConfig((state) => {
          state.profileData =  {[data._id]:[data]};
        })
      );
    setActiveProfileID(data._id);
    setShowDocumentVerificationModal(true);
  };

  return {
    isLoading,
    isFetching,
    tableFields,
    verificationList,
    verificationState,
    paginationOptions,
    getRow,
    refetch,
    handleSort,
    handleSearch,
    handlePageSize,
    handlePagination,
    handleCloseClick,
    handleClearClick,
    handleCreateClick,
    actionOptions,
    handleActionChange,
    updateStatusFields,
    showEditModal,
    handleDateChange,
    initialDate,
    showDocumentVerificationModal,
    closeDocumentModal,
    activeProfileID,
    setShowDocumentVerificationModal,
  };
  
};

export default useVerification;
