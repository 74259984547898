import React from 'react'
import { Outlet } from 'react-router-dom'
import CommonLayout from '../../Pages/Layouts/CommonLayout'
import useUser from './useUser'
const Users = () => {
  const {drawerMenu}=useUser()
  return (
    <CommonLayout drawerMenu={drawerMenu}>
      <Outlet/>
    </CommonLayout>
  )
}

export default Users
