import { Button } from "@wac-ui-dashboard/wac_component_library";
import React from "react";
import Select from "react-select";
import useLanguage from "./useLanguage";

const Language = ({ formik, getFieldError, basicData, handleCloseModal}) => {
  const {handleNext} = useLanguage();
  return (
    <div className="col">
      <div className="input-wrap pro-mb-4">
        <label
          htmlFor="servicetype"
          className="pro-font-sm pro-mb-1 pro-fw-medium"
        >
          Language
        </label>
        <Select
          isMulti={true}
          id="language"
          placeholder={"Language"}
          className={`pro-input lg  ${getFieldError("language") && " error"}`}
          classNamePrefix="pro-input"
          name="language"
          options={basicData?.languages_list ?? []}
          getOptionValue={(option) => option?._id}
          getOptionLabel={(option) => option?.name}
          value={formik?.values?.language}
          onBlur={formik.handleBlur("language")}
          onChange={(value) => formik.setFieldValue("language", value ?? "")}
          menuPlacement="auto"
          isClearable
        />
        {getFieldError("language") && (
          <span className="error-text">{getFieldError("language")}</span>
        )}
      </div>
      <div className={`col-12 pro-d-flex pro-justify-end ${"offcanvas-footer-sticky-btn"}`}>
        <Button
          className={"pro-btn-link lg pro-px-5"}
          onClick={handleCloseModal}
          type="button"
        >
          Cancel
        </Button>
        <Button
          type="button"
          className={"pro-btn-primary lg pro-ms-3"}
          onClick={handleNext}
        >
          Next
        </Button>
      </div>
    </div>
  );
};

export default Language;
