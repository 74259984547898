
import React from "react";
import {
  BrowserRouter,
  Route,
  Routes as BrowserRoutes,
} from "react-router-dom"; 
import { Navigate } from "react-router-dom";
import Index from "../Pages";
import AuthModule from "../Components/Auth";
import Login from "../Components/Auth/Login";
import ForgotPassword from "../Components/Auth/ForgotPassword";
import ResetPassword from "../Components/Auth/ResetPassword";
import Dashboard from "../Components/Dashboard";
import DashboardHome from "../Components/Dashboard/DashboardHome";
import CMS from "../Components/CMS";
import Configure from "../Components/Configure";
import ServiceTypes from "../Components/Configure/ServiceTypes";
import Posts from "../Components/Posts";
import PostHome from "../Components/Posts/PostsHome";
import FAQ from "../Components/CMS/FAQ";
import Staff from "../Components/Configure/Staff";
import Expertise from "../Components/Configure/Expertise";
import Tags from "../Components/Configure/Tags";
import Payments from "../Components/Payments";
import PaymentsHome from "../Components/Payments/PaymentsHome";
import Booking from "../Components/Booking";
import BookingHome from "../Components/Booking/BookingHome";
import Verification from "../Components/Users/Verification";
import Seeker from "../Components/Users/Seeker";
import Providers from "../Components/Users/Providers";
import Users from "../Components/Users";
import Role from "../Components/Configure/Role";
import ManageRole from "../Components/Configure/Role/ManageRole";
import Termsandconditions from "../Components/CMS/TermsandConditons";
import Privacypolicy from "../Components/CMS/PrivacyPolicy";
import ProvidersDetailsLayout from "../Components/Users/Providers/ProvidersDetailsLayout";
import ProvidersDetails from "../Components/Users/Providers/ProvidersDetails";
import NotificationListing from "../Components/Notification/NotificationListing";
import Notification from "../Components/Notification";
import Settings from "../Components/Settings";
import Appearance from "../Components/Settings/Appearance";
import SekeersDetailsLayout from "../Components/Users/Seeker/SeekersDetailsLayout";
import SeekersDetails from "../Components/Users/Seeker/SeekersDetails";
import BookingDetailsLayout from "../Components/Booking/BookingDetailsLayout";
import BookingDetails from "../Components/Booking/BookingDetails";
import useTheme from "../Components/Settings/Appearance/useTheme";


const Routes = () => {
  useTheme("light");
  return (
    <div>
      <BrowserRouter>
        <BrowserRoutes>
          <Route path="/" element={<Index/>}></Route>
          <Route path="/login" element={<AuthModule/>}>
            <Route path="" element={<Login/>}></Route>
            <Route path="forgot-password" element={<ForgotPassword/>}></Route>
            <Route path="reset-password" element={<ResetPassword/>}></Route>
          </Route>
          <Route path="/CMS" element={<CMS/>}>
            <Route path="FAQ" element={<FAQ/>}></Route>
            <Route path="termsandconditions" element={<Termsandconditions/>}> </Route>
            <Route path="privacypolicy" element={<Privacypolicy/>}> </Route>
          </Route>
          <Route path="/configure" element={<Configure/>}>
            <Route path="staff" element={<Staff/>}></Route>
            <Route path="role" element={<Role/>}></Route>
            <Route path="role/role-permissions" element={<ManageRole/>} />
            <Route path="servicetype" element={<ServiceTypes/>}></Route>
            <Route path="expertise" element={<Expertise/>}></Route>
            <Route path="tags" element={<Tags/>}></Route>
          </Route>
          <Route path="/dashboard" element={<Dashboard/>}>
            <Route path="" element={<DashboardHome/>}></Route>
          </Route>
          <Route path="/posts" element={<Posts/>}>
            <Route path="" element={<PostHome/>}></Route>
          </Route>
          <Route path="/booking" element={<Booking/>}>
            <Route path="" element={<BookingHome/>}></Route>
          </Route>
          <Route path="/booking/booking-details" element={<BookingDetailsLayout/>}>
            <Route path=":bookingID" element={<BookingDetails />}></Route>
          </Route>
          <Route path="/payments" element={<Payments/>}>
            <Route path="" element={<PaymentsHome/>}></Route>
          </Route>
          <Route path="/users" element={<Users/>}>
            <Route path="providers" element={<Providers/>}></Route>
            <Route path="seeker" element={<Seeker/>}></Route>
            <Route path="verification" element={<Verification/>}> </Route>
          </Route>
          <Route path="/users/providers-details" element={<ProvidersDetailsLayout/>}>
            <Route path=":providerID" element={<ProvidersDetails />}></Route>
          </Route>
          <Route path="/users/seeker-details" element={<SekeersDetailsLayout/>}>
            <Route path=":seekerID" element={<SeekersDetails />}></Route>
          </Route>
          <Route path="/notifications" element={<Notification/>}>
            <Route path="" element={<NotificationListing />} />
          </Route>
          <Route path="/settings" element={<Settings/>}>
            <Route path="" element={<Appearance/>}></Route>
          </Route>
          <Route path={"/*"} element={<Navigate to={"/"} />} />
        </BrowserRoutes>
      </BrowserRouter>
    </div>
  );
};

export default Routes;
