import { useState } from 'react'

const useProviderDocuments = () => {
const [showDocumentModal, setShowDocumentModal] =useState(false);
const [index, setIndex] = useState();
const [type, setType] = useState();
const handleViewImage=(index,doc)=>{
    setIndex(index)
    setType(doc)
    setShowDocumentModal(true)
}
const closeDocumentModal=()=>{
    setShowDocumentModal(false)
}
  return {
    showDocumentModal,
    setShowDocumentModal,
    handleViewImage,
    closeDocumentModal,
    index,setIndex,
    type,setType
  }
}

export default useProviderDocuments