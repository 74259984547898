import { useState } from "react";
import { useDispatch } from "react-redux";
import { updateConfig } from "../../../Store/Slices/Global";
import { toast } from "react-toastify";

const useOrderColoum = ({ tableFields, moduleId, updateData, refetch }) => {

  const [characters, setCharacters] = useState(Object.values(tableFields));

  const dispatch = useDispatch();
  function handleOnDragEnd(result) {
    if (!result.destination) return;

    const items = Array.from(characters);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);
    setCharacters(items);
  }

  const handleCancel = () => {
    dispatch(
      updateConfig((state) => {
        state.showEditModal = false;
      })
    );
  };
  const handleSubmit = () => {
    function getKeyByValue(object, Label) {
      return Object.keys(object).find((key) => object[key].label === Label);
    }

    let newObj = characters.filter((t) => t.hidden === false);

    let newtableFields = newObj.map((item) =>
      getKeyByValue(tableFields, item.label)
    );

    const obj = {
      module_id: moduleId,
      fields: newtableFields,
    };

    updateData(obj).then((response) => {
      if (response?.data?.success === true) {
        dispatch(
          updateConfig((state) => {
            state.showEditModal = false;
          })
        );
        toast.success("Successfully updated fields");
        refetch()
      } else if (response?.error?.success === 422) {
        toast.warning("Select atleast one column");
      } else if (response?.data?.success === false) {
        toast.error("Something went wrong");
      }else {
        toast.error("Something went wrong");
      }
    });
  };

  const handleCheckbox = (Label) => {
    function getKeyByValue(object, Label) {
      return Object.keys(object).find((key) => object[key].label === Label);
    }

    let tempData = characters;
    let key = getKeyByValue(tempData, Label);
    tempData[key] = { ...tempData[key], hidden: !tempData[key].hidden };

    setCharacters(Object.values(tempData));
  };

  return {
    characters,
    // message,
    handleCancel,
    handleSubmit,
    handleOnDragEnd,
    handleCheckbox,
  };
};

export default useOrderColoum;
