import { createApi } from "@reduxjs/toolkit/query/react";
import { getAxiosInstance } from "../../../API";

const axiosBaseQuery =
  () =>
  async ({ params, endpoint, method, body }) => {
    const api = await getAxiosInstance();
    const getParams = (key) => {
      if (params?.[key]) {
        return `${key}=${params[key]}&`;
      } else {
        return "";
      }
    };
    if (method === "get") {
      try {
        const response = await api[method](
            `${endpoint}?${getParams("start")}${getParams("end")}&${getParams("sort_by")}sort_order=${
              params?.sort_order || "desc"
            }&length=${
              params?.page_size || 10
            }&page=${params?.currentPage || ""
            }&status=${params?.status}&${getParams("role_id")}${getParams("search")}
              `,
          body
        );
        return { data: response.data };
      } catch (axiosError) {
        let err = axiosError;
        return {
          error: {
            status: err.response?.status,
            data: err.response?.data || err.message,
          },
        };
      }
    } else {
      try {
        const response = await api[method](
          `${endpoint}?${getParams("start")}${getParams("end")}}&${getParams("sort_by")}sort_order=${
            params?.sort_order || "desc"
          }&length=${
            params?.page_size || 10
          }&page=${params?.currentPage || ""
          }&status=${params?.status}&${getParams("role_id")}${getParams("search")}`,
          body
        );
        return { data: response.data };
      } catch (axiosError) {
        let err = axiosError;
        return {
          error: {
            status: err.response?.status,
            data: err.response?.data || err.message,
          },
        };
      }
    }
  };

export const staff = createApi({
  reducerPath: "staffApi",
  baseQuery: axiosBaseQuery(),
  tagTypes: ["staff"],
  endpoints: (builder) => ({
    getStaffData: builder.query({
      query: (values) => ({
        method: "get",
        params: values,
        endpoint: `/admin/users`,
      }),
      providesTags: ["staff"],
    }),
    staffTableHeadData: builder.mutation({
      query: (body) => ({
        method: "post",
        body,
        endpoint: `/admin/user-field-save`,
      }),

      invalidatesTags: ["staff"],
    }),
  }),
});
export const{useGetStaffDataQuery, useStaffTableHeadDataMutation}=staff
