import { useDispatch } from "react-redux";
import { updateConfig } from "../../../../Store/Slices/CMS/termsandconditionsSlice";
import { useFormik } from "formik";
import { ContentState, EditorState, convertToRaw } from "draft-js";
import draftToHtml from "draftjs-to-html";
import { toast } from "react-toastify";
import { createTerms,updateTerms} from "../api";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import DOMPurify from "dompurify";
import htmlToDraft from "html-to-draftjs";

const useAddTerms = ({ closeModal,refetch }) => {
  const dispatch = useDispatch();
  const [status, setStatus] = useState(true);
  const {isEdit,itemData} = useSelector((state) => state.termsandconditions);
  const formik = useFormik({
    initialValues: {},
    enableReinitialize: true,
    validate: (values) => {
      let errors = {};
      const plainText = values?.description?.getCurrentContent()?.getPlainText();
      if (!plainText?.trim()) {
        errors.description = "*Description cannot be empty";
      }
      const Title = values.title
      if (!Title) {
        errors.title = "*Title cannot be empty";
      }
      return errors;
     },
     
    onSubmit: async (values) => {
      const contentState = values.description.getCurrentContent();
      const contentRaw = convertToRaw(contentState);
      const plainText = draftToHtml(contentRaw);
      if(!isEdit){
        let obj = {
          title:formik?.values?.title,
          content: plainText,
        };
        createTerms(obj).then((response) => {
          if (response?.status === 200) {
            dispatch(
              updateConfig((state) => {
                state.editModal = false;
              })
            );
            toast.success("created");
            closeModal()
            refetch();
          } else {
            toast.error("Something went wrong");
          }
        });
      }else{
        let obj = {
          content_id:itemData?._id,
          title:formik?.values?.title,
          content: plainText,
          status: status? 1:0
        };
        updateTerms(obj).then((response) => {
          if (response?.status === 200) {
            dispatch(
              updateConfig((state) => {
                state.editModal = false;
              })
            );
            closeModal()
            toast.success("updated");
            refetch();
          } else {
            toast.error("Something went wrong");
          }
        });
      }
    },
  });
  useEffect(() => {
    const desc = setTimeout(() => {
      const htmlContent = itemData?.content?.replace(/\\/g, "");
      const sanitizedHtml = DOMPurify?.sanitize(htmlContent);
      const contentBlock = htmlToDraft(sanitizedHtml);
      const contentState = ContentState?.createFromBlockArray(
        contentBlock.contentBlocks
      );
      const editorState = EditorState.createWithContent(contentState);
      formik.setFieldValue("description", editorState);
      formik.setFieldValue("title", itemData?.title ?? "");
      setStatus(itemData?.status===1?true:false)
    }, 1000);
    return () => clearTimeout(desc);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [itemData]);

  const handleContentChange = (value) => {
    formik.setFieldValue("description", value);
  };
  const getFieldError = (fieldName) => {
    if (formik.touched[fieldName] && formik.errors[fieldName]) {
      return formik.errors[fieldName];
    }
    return "";
  };
  const handleStatusChange = () => {
    setStatus(!status);
  };
  return {
    formik,
    handleContentChange,
    getFieldError,
    isEdit,
    handleStatusChange,
    status
  };
};

export default useAddTerms;
