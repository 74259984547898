import { getAxiosInstance } from "../../../API";

export const createTags= async (body) => {
  const api = await getAxiosInstance();
  try {
    const response = await api.post(`/admin/tags/create`, body);
    return response;
  } catch (error) {
    return error?.response?.data
  }
};

export const updateTags = async (body) => {
  const api = await getAxiosInstance();;
  try {
    const response = await api.put(`/admin/tags/update`, body);
    return response;
  } catch (error) {
    return error?.response?.data
  }
};

export const viewTags = async (id) => {
  const api = await getAxiosInstance();
  try {
    const response = await api.get(`/admin/tags/view?id=${id}`);
    return response;
  } catch (error) {
    return error?.response?.data
  }
};

export const updateStatus = async (body) => {
  const api = await getAxiosInstance();
  try {
    const response = await api.put(`/admin/tags/status/change`, body);
    return response;
  } catch (error) {
    return error?.response?.data
  }
};
export const deleteTags = async (id) => {
  const api = await getAxiosInstance();
  try {
    const response = await api.delete(`/admin/tags/delete?id=${id}`);
    return response;
  } catch (error) {
    return error?.response?.data
  }
};