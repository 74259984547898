import { FormTabs } from "@wac-ui-dashboard/wac_component_library";
import React from "react";
import useAddProviders from "./useAddProviders";
import Style from "./form.module.scss";

const AddProviders = ({ refetch ,providersState}) => {
  const {
    tabs,
    activeTab,
    map,
    setMap,
    renderTabContent,
    handleTabClick,
  } = useAddProviders({ refetch,providersState});
  return (
    <div className=" pro-pb-5 pro-d-flex pro-flex-column form-custom">
      <FormTabs
        propStyle={{ 
          root: Style.root,
          root_items :Style.root_items,
          item:Style.item,
          active : Style.active,
          item_progress_bar: Style.item_progress_bar
         }}
        map={map}
        tabs={tabs}
        active={activeTab}
      handleClick={handleTabClick}
        setMap={setMap}
      />
      <form className="pro-h-100" >
        {renderTabContent()}
      </form>
    </div>
  );
};

export default AddProviders;
