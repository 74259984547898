import { useFormik } from "formik";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { updateConfig } from "../../../../Store/Slices/Users/seekersSlice";
import * as Yup from "yup";

const useFilterSeeker = ({ refetch }) => {
  const dispatch = useDispatch();
  // const [status, setStatus] = useState(true);
  const { basicData } = useSelector((state) => state.global);
  const { isEdit} = useSelector((state) => state.seekers);

  const validationSchema = Yup.object({
    servicetype: Yup.array(),
    status: Yup.object()
  });

  const formik = useFormik({
    initialValues: {
      servicetype:"",
      status: '',
    },
    validationSchema,
    onSubmit: (values) => {
      const newvalues = values?.servicetype && values?.servicetype.map(serviceType => serviceType.object_id); 
      if(values?.status?.id?.toString()||newvalues){
          dispatch(updateConfig((state) => {
              state.status =  values?.status?.id?.toString()?values?.status?.id?.toString():""
              state.service_type_id =newvalues??""
            }));
      }
      handleCloseModal()
    },
  });

  const getFieldError = (fieldName) => {
    if (formik.touched[fieldName] && formik.errors[fieldName]) {
      return formik.errors[fieldName];
    }
    return "";
  };

  const handleCloseModal = () => {
    dispatch(updateConfig((state) => (state.createFilterModal = false)));
  };

  return {
    formik, isEdit, getFieldError, handleCloseModal ,basicData
  };
};

export default useFilterSeeker;
