import { DataContainer } from "@wac-ui-dashboard/wac_component_library";
import React from "react";
import { Bar } from "react-chartjs-2";
import Style from "../dashboardHome.module.scss";

const BarChart = ({ data, options }) => {
  return (
    <>
      <DataContainer>
        <h6 className="pro-ttl h6">Service Trends</h6>
        <div className={`row gx-0 ${Style.bar_chart_wrap}`}>
          {data &&
            data.map((newData, index) => (
              <div key={index} className={`col-4 ${Style.single_bar_diagram}`}>
                <Bar data={newData} options={options} />
                <ul
                  className={`pro-mt-5 ${Style.bullet_list}`}
                >
                  {newData?.labels.map((item, i) => (
                    <li
                      key={i}
                      className={`${Style.bullet_item}`}
                    >
                      <span
                        className={`${Style.bullet}`}
                        style={{
                          backgroundColor:
                            newData?.datasets[0].backgroundColor[i],
                        }}
                      ></span>
                      {item}
                    </li>
                  ))}
                </ul>
              </div>
            ))}
        </div>
        <div className={`pro-d-flex ${Style.bar_label}`}>
          <div
            className={`pro-d-flex flex-fill pro-p-1 ${Style.bar_label_single}`}
            style={{ backgroundColor: "#32CD32" }}
          >
            <span className="material-symbols-outlined x4">skillet</span>
            <p className="pro-mb-0 pro-font-sm pro-fw-medium">cook</p>
          </div>
          <div
            className={` pro-d-flex flex-fill pro-p-1 ${Style.bar_label_single}`}
            style={{ backgroundColor: "#4ABDE3" }}
          >
            <span className="material-symbols-outlined x4">heart_plus</span>
            <p className="pro-mb-0 pro-font-sm pro-fw-medium">care</p>
          </div>
          <div
            className={` pro-d-flex flex-fill pro-p-1 ${Style.bar_label_single}`}
            style={{ backgroundColor: "#FF6347" }}
          >
            <span className="material-symbols-outlined x4">cleaning_services</span>
            <p className="pro-mb-0 pro-font-sm pro-fw-medium">clean</p>
          </div>
        </div>
      </DataContainer>
    </>
  );
};

export default BarChart;
