
const useBookingDetails = (bookingdetailData) => {
    const seekerdetail=[{label: 'Booking Type', value: bookingdetailData?.data?.details?.service_request_type},
        {label: 'Service', value: bookingdetailData?.data?.details?.service_type},
        {label: 'Type of Service', value: bookingdetailData?.data?.details?.service_category},
        {label: 'Service Need Date', value: bookingdetailData?.data?.details?.service_date},
        {label: 'Payment ', value: bookingdetailData?.data?.details?.service_request_type}
    ].filter(detail => {
        const value = detail.value;
        return Array.isArray(value) ? value.length > 0 : value !== undefined && value !== "";
    })
    const providerdetail=[{label: 'Hourly Rate', value: `$${bookingdetailData?.data?.details?.hourly_price}/hr`},
        {label: 'Total hours', value: `${bookingdetailData?.data?.details?.service_type} hrs`},
        {label: 'Total Amount', value: `$${bookingdetailData?.data?.details?.service_type}`},
        {label: 'Status ', value: bookingdetailData?.data?.details?.status}
    ].filter(detail => {
        const value = detail.value;
        return Array.isArray(value) ? value.length > 0 : value !== undefined && value !== "";
    })
  return{seekerdetail,providerdetail}
}

export default useBookingDetails