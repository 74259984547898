import {
  Image,
  ModalLayout,
  SimpleTextDetails,
} from "@wac-ui-dashboard/wac_component_library";
import React from "react";
import providersStyle from "../providersDetails.module.scss";
import Assets from "../../../../../Assets";
import { ProviderDocCard } from "./ProviderDocCard";
import useProviderDocuments from "./useProviderDocuments";
import EmptyData from "../../../../Global/EmptyData";

const ProviderDocuments = ({ providerData }) => {
  const {
    showDocumentModal,
    handleViewImage,
    index,
    setIndex,
    closeDocumentModal,
    type,
  } = useProviderDocuments();
  return (
    <div className={`col-12`}>
      <div className={` ${providersStyle.doc_wrap}`}>
        <SimpleTextDetails
          title={"Uploaded Documents"}
          extraClassNames={` pro-mb-5 `}
        />
        <div className={`row ${providersStyle}`}>
          {providerData?.data?.documents?.pending?.length!==0?providerData?.data?.documents?.pending.map((data, index) => (
            <div className={`${providersStyle.root}`} key={index}>
              <div className={`${providersStyle.root_inner} `}>
                <div
                  className={`${providersStyle.root_image}`}
                  onClick={(e) => handleViewImage(index, "pending")}
                >
                  <Image
                    src={
                      data?.url?.split(".").pop() !== "pdf"
                        ? `${data?.url}`
                        : Assets.PdfLogo
                    }
                    width={100 || ""}
                    height={100 || ""}
                  />
                </div>
                <div className={`pro-pt-3`}>
                  <p className="pro-fw-medium pro-mb-0">{data?.name}</p>
                </div>
              </div>
            </div>
          )): (
            <div>
              <EmptyData />
            </div>
          )}
        </div>
      </div>
      <div className={` ${providersStyle.doc_wrap}`}>
        <SimpleTextDetails
          title={"Approved Documents"}
          extraClassNames={`pro-mb-5 `}
        />
        {providerData?.data?.documents?.approved.length !==0 ? (
          providerData?.data?.documents?.approved.map((data, index) => (
            <div className={`${providersStyle.root}`} key={index}>
              <div className={`${providersStyle.root_inner}`}>
                <div
                  className={`${providersStyle.root_image}`}
                  onClick={(e) => handleViewImage(index, "approved")}
                >
                  <Image
                    src={
                      data?.url?.split(".").pop() !== "pdf"
                        ? `${data?.url}`
                        : Assets.PdfLogo
                    }
                    width={100 || ""}
                    height={100 || ""}
                  />
                </div>
                <div
                  className={`${providersStyle.badge} pro-ps-1 pro-bg-light pro-rounded-3 pro-d-flex pro-items-center`}
                >
                  <span
                    className={`${providersStyle.badge_text} pro-fw-medium`}
                  >
                    Accepted
                  </span>
                  <div
                    className={`pro-check-box rounded-tick ${providersStyle.accepted}`}
                  >
                    <span className="material-symbols-outlined">check</span>
                    {/* <img
                              alt="badge"
                              src={`
                                  ${
                                    item?.status === 0
                                      ? Assets.YELLOWCHECK
                                      : item?.status === 1
                                      ? Assets.GREENCHECK
                                      : item?.status === 2
                                      ? Assets.REDCHECK
                                      : ""
                                  }
                                  `}
                            /> */}
                  </div>
                </div>
                <div className={`pro-pt-3`}>
                  <p
                    className={`pro-fw-medium pro-mb-0 ${providersStyle.file_name}`}
                  >
                    {data?.name}
                  </p>
                </div>
              </div>
            </div>
          ))
        ) : (
          <div>
            <EmptyData />
          </div>
        )}
      </div>

      <div className={` ${providersStyle.doc_wrap}`}>
        <SimpleTextDetails
          title={"Rejected Documents"}
          extraClassNames={`pro-mb-5`}
        />
        <div className="pro-d-flex pro-gap-5 pro-w-100 pro-flex-wrap">
          {providerData?.data?.documents?.rejected.length !==0 ?providerData?.data?.documents?.rejected.map((data, index) => (
            <div className={`${providersStyle.root}`} key={index}>
              <div className={`${providersStyle.root_inner} `}>
                <div
                  className={`${providersStyle.root_image}`}
                  onClick={(e) => handleViewImage(index, "rejected")}
                >
                  <Image
                    src={
                      data?.url?.split(".").pop() !== "pdf"
                        ? `${data?.url}`
                        : Assets.PdfLogo
                    }
                    width={100 || ""}
                    height={100 || ""}
                  />
                </div>
                <div
                  className={`${providersStyle.badge} pro-ps-1 pro-bg-light pro-rounded-3 pro-d-flex pro-items-center`}
                >
                  <span
                    className={`${providersStyle.badge_text} pro-fw-medium`}
                  >
                    Rejected
                  </span>
                  <div
                    className={`pro-check-box rounded-tick  ${providersStyle.rejected}`}
                  >
                    <span className="material-symbols-outlined">close</span>
                    {/* <img
                                alt="badge"
                                src={`
                                    ${
                                      item?.status === 0
                                        ? Assets.YELLOWCHECK
                                        : item?.status === 1
                                        ? Assets.GREENCHECK
                                        : item?.status === 2
                                        ? Assets.REDCHECK
                                        : ""
                                    }
                                    `}
                              /> */}
                  </div>
                </div>
                <div className={`pro-pt-3`}>
                  <p
                    className={`pro-fw-medium pro-mb-0 ${providersStyle.file_name}`}
                  >
                    {data?.name}
                  </p>
                </div>
              </div>
            </div>
          )): (
            <div className="pro-w-100">
              <EmptyData />
            </div>
          )}
        </div>
      </div>
      <ModalLayout show={showDocumentModal} handleClose={closeDocumentModal}>
        <ProviderDocCard
          data={providerData?.data?.documents[type]}
          // refetch={refetch}
          index={index}
          setIndex={setIndex}
        />
      </ModalLayout>
    </div>
  );
};

export default ProviderDocuments;
