import { DataContainer } from "@wac-ui-dashboard/wac_component_library";
import React from "react";
import { Doughnut } from "react-chartjs-2";
import ProgressBar from "../../../Global/ProgressBar";
import EmptyData from "../../../Global/EmptyData";
import Style from "../dashboardHome.module.scss";

const DoughnutCard = ({
  doughnutData,
  statusGraph,
  doughnutTextCenter,
  total,
  percentage,
  title
}) => {
  return (
    <>
      <DataContainer>
        <h6 className="pro-ttl h6">{title}</h6>
        {doughnutData?.labels.length > 0 ? (
          <>
            <div className={Style.doughnut_chart_wrap}>
              <Doughnut
                data={doughnutData}
                options={statusGraph}
                plugins={[doughnutTextCenter]}
              />
            </div>
            <div>
              {doughnutData?.labels.map((label, index) => (
                <div key={index}>
                  <ProgressBar
                    color={doughnutData?.datasets[0]?.backgroundColor[index]}
                    completed={doughnutData?.datasets[0]?.data[index]}
                    label={label}
                    total={total}
                    percentage={percentage}
                  />
                </div>
              ))}
            </div>
          </>
        ) : (
          <>
            <EmptyData />
          </>
        )}
      </DataContainer>
    </>
  );
};

export default DoughnutCard;
